import {Button, Card, FloatingLabel, Form, ListGroup, Stack} from "react-bootstrap";
import React, {useCallback, useMemo, useState} from "react";
import SearchDropdown from "../../../common/search-dropdown";
import {statsArray, StatsKey} from "../../../../models/stats";
import {useTranslation} from "react-i18next";
import {
    artifactByStatMap,
    artifactList, getArtifactTKey,
    getFilteredArtifactList,
    getSortedList
} from "../../../../utils/search";
import {ARTIFACT} from "../../../../data/artifact/artifact";
import {ArtifactItem} from "./artifact-item";
import Artifact from "../../../../models/artifact";

type ArtifactSearchProps = {
    index: number,
    setNewArtifact: (id: ARTIFACT) => void
}

export const ARTIFACT_SEARCH_TEST_ID = 'artifact-search';
export const ARTIFACT_SEARCH_INPUT_TEST_ID = 'artifact-search-input';
export const ARTIFACT_SEARCH_POSITIVE_BUTTON_TEST_ID = 'artifact-search-positive-button';


const ArtifactSearch: React.FC<ArtifactSearchProps> = ({index, setNewArtifact}) => {
    const {t, i18n} = useTranslation();
    const getLabel = useCallback((stat: StatsKey) => t(`stats.${stat}`), [t]);
    const [stat, setStat] = useState<StatsKey | null>(null);
    const [isPositive, setIsPositive] = useState<boolean>(true);
    const [filter, setFilter] = useState<string>('');
    const sortedStats = useMemo(() =>
        statsArray.sort(
            (a, b) => getLabel(a).localeCompare(getLabel(b), i18n.language)), [getLabel, i18n.language]);
    const sortedAllArtifacts = useMemo(() => getSortedList<Artifact>(artifactList, getArtifactTKey, t), [t]);
    const sortedArtifactList = useMemo(() => {
        return stat === null ? sortedAllArtifacts : (artifactByStatMap[isPositive ? 'positive' : 'negative'][stat] ?? []);
    }, [stat, sortedAllArtifacts, isPositive]);
    const artifacts = useMemo(
        () => getFilteredArtifactList(sortedArtifactList, filter, t),
        [sortedArtifactList, filter, t]
    );

    return (
        <Card className={'border-0'} data-testid={ARTIFACT_SEARCH_TEST_ID}>
            <Card.Body>
                <Stack direction='vertical' gap={2}>
                    <FloatingLabel
                        controlId={`artifact-search-${index}`}
                        label="Artifact name"
                    >
                        <Form.Control
                            type="text"
                            placeholder="Artifact name"
                            value={filter}
                            data-testid={ARTIFACT_SEARCH_INPUT_TEST_ID}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                    </FloatingLabel>
                    <div className="d-flex">
                        <SearchDropdown<StatsKey>
                            id={`artifact-stat-search-${index}`}
                            list={sortedStats}
                            getLabel={getLabel}
                            value={stat}
                            onChange={setStat}
                            placeholder={t('artifact.search.statPlaceholder')}
                            className='flex-grow-1 me-2'
                        />
                        <Button
                            onClick={() => setIsPositive(!isPositive)}
                            variant={isPositive ? 'success' : 'danger'}
                            data-testid={ARTIFACT_SEARCH_POSITIVE_BUTTON_TEST_ID}
                        >
                            {t(`artifact.search.${isPositive ? 'positive' : 'negative'}`)}
                        </Button>
                    </div>
                    {artifacts.length > 0 ? <ListGroup style={{ maxHeight: '400px', overflowY: 'auto'}}>
                        {artifacts.map((artifact, i) => (
                            <ArtifactItem artifact={artifact} setNewArtifact={setNewArtifact} key={`${artifact.id}-${i}`}/>
                        ))}
                    </ListGroup> : <div>{t('artifact.search.noResults')}</div>}
                </Stack>

            </Card.Body>
        </Card>
    )
}

export default ArtifactSearch;