import {Nav} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Navbar = () => {
    const {t, i18n} = useTranslation();

    const setLanguageFabric = (lang: 'en' | 'ru') => () => {
        i18n.changeLanguage(lang).then(() => {
            localStorage.setItem('lang', lang);
        });
    }

    return (
        <Nav className='mb-2 bg-white'>
            <Nav.Item><Nav.Link as={Link as any} to={'/'}>{t('navbar.home')}</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link as={Link as any} to={'/calc'}>{t('navbar.calc')}</Nav.Link></Nav.Item>
            {/*<Nav.Item><Nav.Link as={Link} to={'/craft'}>{t('navbar.craft')}</Nav.Link></Nav.Item>*/}
            <Nav.Item className={'ms-auto'}><Nav.Link as={Link as any} to={'https://ru.staltools.com'}>{t('navbar.linkToAnotherRegion')}</Nav.Link></Nav.Item>
            <Nav.Item className={'ms-auto'}><Nav.Link onClick={setLanguageFabric('en')}>EN</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link onClick={setLanguageFabric('ru')}>RU</Nav.Link></Nav.Item>
        </Nav>)
}

export default Navbar;