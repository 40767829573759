import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const electraHelium = new Artifact(ARTIFACT.ELECTRA_HELIUM, {
    [STAT.speedModifier]: [2, 4],
    [STAT.staminaRegenerationBonus]: [2.5, 5],
    [STAT.biologicalAccumulation]: [-2, -5],
}, {
    [STAT.staminaRegenerationBonus]: [1.25, 2.5],
    [STAT.regenerationBonus]: [1, 2],
    [STAT.biologicalAccumulation]: [0.5, 1.25],
});

export default electraHelium;