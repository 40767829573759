import {SCIENTIST_ARMOR} from "../armor";
import Armor from "../../../models/armor";
import raincoat from "./raincoat";
import ip4m from "./ip-4m";
import zaryaBSuit from "./zarya-b-suit";
import banditSuitWithGasTanks from "./bandit-suit-with-gas-tanks";
import sevaSuit from "./seva-suit";
import reaperSuit from "./reaper-suit";
import mis99Amber from "./mis-99-amber";
import mis99Malachite from "./mis-99-malachite";
import mis105Topaz from "./mis-105-topaz";
import mis113Iolite from "./mis-113-iolite";
import mis116Emerald from "./mis-116-emerald";
import mis122Amethyst from "./mis-122-amethyst";
import misXAtlas from "./mis-x-atlas";
import wornMis99Amber from "./worn-mis-99-amber";
import wornMis99mMalachite from "./worn-mis-99m-malachite";
import wornSevaSuit from "./worn-seva-suit";
import wornReaperSuit from "./worn-reaper-suit";
import wornTopaz from "./worn-topaz";
import wornIolite from "./worn-iolite";
import wornEmerald from "./worn-emerald";
import wornAmethyst from "./worn-amethyst";
import mis85d from "./mis-85d";
import improvedHazmatSuit from "./improved-hazmat-suit";
import mis100Garnet from "./mis-100-garnet";
import mis102Zircon from "./mis-102-zircon";
import saturnSuit from "./saturn-suit";
import wornSaturnSuit from "./worn-saturn-suit";
import damagedSaturnSuit from "./damaged-saturn-suit";

const ScientistArmor: Record<SCIENTIST_ARMOR, Armor> = {
    [SCIENTIST_ARMOR.RAINCOAT]: raincoat,
    [SCIENTIST_ARMOR.IP_4_M]: ip4m,
    [SCIENTIST_ARMOR.ZARYA_B_SUIT]: zaryaBSuit,
    [SCIENTIST_ARMOR.BANDIT_SUIT_WITH_GAS_TANKS]: banditSuitWithGasTanks,
    [SCIENTIST_ARMOR.SEVA_SUIT]: sevaSuit,
    [SCIENTIST_ARMOR.REAPER_SUIT]: reaperSuit,
    [SCIENTIST_ARMOR.MIS_99_AMBER]: mis99Amber,
    [SCIENTIST_ARMOR.MIS_99_MALACHITE]: mis99Malachite,
    [SCIENTIST_ARMOR.MIS_105_TOPAZ]: mis105Topaz,
    [SCIENTIST_ARMOR.MIS_113_IOLITE]: mis113Iolite,
    [SCIENTIST_ARMOR.MIS_116_EMERALD]: mis116Emerald,
    [SCIENTIST_ARMOR.MIS_122_AMETHYST]: mis122Amethyst,
    [SCIENTIST_ARMOR.MIS_X_ATLAS]: misXAtlas,
    [SCIENTIST_ARMOR.WORN_MIS_99_AMBER]: wornMis99Amber,
    [SCIENTIST_ARMOR.WORN_MIS_99_M_MALACHITE]: wornMis99mMalachite,
    [SCIENTIST_ARMOR.WORN_SEVA_SUIT]: wornSevaSuit,
    [SCIENTIST_ARMOR.WORN_REAPER_SUIT]: wornReaperSuit,
    [SCIENTIST_ARMOR.WORN_TOPAZ]: wornTopaz,
    [SCIENTIST_ARMOR.WORN_IOLITE]: wornIolite,
    [SCIENTIST_ARMOR.WORN_EMERALD]: wornEmerald,
    [SCIENTIST_ARMOR.WORN_AMETHYST]: wornAmethyst,
    [SCIENTIST_ARMOR.MIS_85_D]: mis85d,
    [SCIENTIST_ARMOR.IMPROVED_HAZMAT_SUIT]: improvedHazmatSuit,
    [SCIENTIST_ARMOR.MIS_100_GARNET]: mis100Garnet,
    [SCIENTIST_ARMOR.MIS_102_ZIRCON]: mis102Zircon,
    [SCIENTIST_ARMOR.SATURN_SUIT]: saturnSuit,
    [SCIENTIST_ARMOR.WORN_SATURN_SUIT]: wornSaturnSuit,
    [SCIENTIST_ARMOR.DAMAGED_SATURN_SUIT]:damagedSaturnSuit
}

export default ScientistArmor;