import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const trampolineDarkmeduza = new Artifact(ARTIFACT.TRAMPOLINE_DARKMEDUZA, {
    [STAT.staminaRegenerationBonus]: [-2.5, -5],
    [STAT.radiationAccumulation]: [-3, -6],
    [STAT.bulletDmgFactor]: [15, 20],
}, {
    [STAT.staminaRegenerationBonus]: [1, 2],
    [STAT.explosionDmgFactor]: [10, 20],
    [STAT.radiationAccumulation]: [0.75, 1.5],
});

export default trampolineDarkmeduza;