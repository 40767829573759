import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const huntersCloak = new Armor(ARMOR.HUNTERS_CLOAK,
    {
        [STAT.staminaBonus]: -5,
        [STAT.maxWeightBonus]: 5,
        [STAT.bulletDmgFactor]: 24,
        [STAT.tearDmgFactor]: 73,
        [STAT.explosionDmgFactor]: 33,
        [STAT.electraDmgFactor]: 15,
        [STAT.burnDmgFactor]: 15,
        [STAT.chemicalBurnDmgFactor]: 15,
        [STAT.radiationProtection]: 30,
        [STAT.thermalProtection]: 0,
        [STAT.biologicalProtection]: 30,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 42,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 1780,
            [RESOURCE.GREEN_MOLD]: 16,
            [RESOURCE.SWAMP_STONE]: 4,
        },
        dependency: ARMOR.BANDIT_CLOAK,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default huntersCloak;