import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornSevaSuit = new Armor(ARMOR.WORN_SEVA_SUIT,
    {
        [STAT.maxWeightBonus]: 5,
        [STAT.bulletDmgFactor]: 56,
        [STAT.tearDmgFactor]: 63,
        [STAT.explosionDmgFactor]: 76,
        [STAT.electraDmgFactor]: 687,
        [STAT.burnDmgFactor]: 687,
        [STAT.chemicalBurnDmgFactor]: 687,
        [STAT.radiationProtection]: 310,
        [STAT.thermalProtection]: 310,
        [STAT.biologicalProtection]: 400,
        [STAT.psychoProtection]: 295,
        [STAT.bleedingProtection]: 38,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 102690,
            [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 284,
            [RESOURCE.ZONSHROOM]: 295,
            [RESOURCE.BETA_DATA_FRAGMENT]: 107,
            [RESOURCE.CHLORINEX]: 340,
        },
        dependency: ARMOR.MIS_99_MALACHITE,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default wornSevaSuit;