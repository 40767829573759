import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const wornAh5Pilgrim = new Armor(ARMOR.WORN_AH_5_PILGRIM,
    {
        [STAT.staminaBonus]: -10,
        [STAT.speedModifier]: -2,
        [STAT.maxWeightBonus]: 15,
        [STAT.artefaktHeal]: 2.25,
        [STAT.bulletDmgFactor]: 83,
        [STAT.tearDmgFactor]: 98,
        [STAT.explosionDmgFactor]: 118,
        [STAT.electraDmgFactor]: 563,
        [STAT.burnDmgFactor]: 563,
        [STAT.chemicalBurnDmgFactor]: 563,
        [STAT.radiationProtection]: 225,
        [STAT.thermalProtection]: 225,
        [STAT.biologicalProtection]: 290,
        [STAT.psychoProtection]: 210,
        [STAT.bleedingProtection]: 50,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default wornAh5Pilgrim;