import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const exoskeleton = new Armor(ARMOR.EXOSKELETON,
    {
        [STAT.staminaBonus]: -16,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 30,
        [STAT.bulletDmgFactor]: 132,
        [STAT.tearDmgFactor]: 133,
        [STAT.explosionDmgFactor]: 190,
        [STAT.electraDmgFactor]: 89,
        [STAT.burnDmgFactor]: 89,
        [STAT.chemicalBurnDmgFactor]: 89,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 70,
        [STAT.biologicalProtection]: 180,
        [STAT.psychoProtection]: 160,
        [STAT.bleedingProtection]: 60,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 685450,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 380,
            [RESOURCE.RED_FERN]: 530,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 310,
            [RESOURCE.SUBSTANCE_07270]: 1600,
        },
        dependency: ARMOR.WORN_EXOSKELETON,
    },
    {
        resources: {
            [RESOURCE.RUB]: 685450,
            [RESOURCE.LIMBOPLASMA]: 9,
            [RESOURCE.BITTERLEAF]: 650,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 111,
            [RESOURCE.LIM]: 1040,
        },
        dependency: ARMOR.WORN_EXOSKELETON,
    },
    {
        resources: {
            [RESOURCE.RUB]: 684920,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 380,
            [RESOURCE.RED_FERN]: 530,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 310,
            [RESOURCE.SUBSTANCE_07270]: 1600,
        },
        dependency: ARMOR.WORN_TRUMP,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_STANDARD_CONTAINER,
)

export default exoskeleton;