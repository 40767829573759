import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const scoutSuit = new Armor(ARMOR.SCOUT_SUIT,
    {
        [STAT.maxWeightBonus]: 3,
        [STAT.bulletDmgFactor]: 45,
        [STAT.tearDmgFactor]: 45,
        [STAT.explosionDmgFactor]: 63,
        [STAT.electraDmgFactor]: 18,
        [STAT.burnDmgFactor]: 18,
        [STAT.chemicalBurnDmgFactor]: 18,
        [STAT.radiationProtection]: 45,
        [STAT.thermalProtection]: 0,
        [STAT.biologicalProtection]: 45,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 32,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 5240,
            [RESOURCE.STINKY_ROOT]: 18,
            [RESOURCE.SEEDS]: 33,
        },
    },
    {
        resources: {
            [RESOURCE.RUB]: 1400,
            [RESOURCE.STINKY_ROOT]: 5,
            [RESOURCE.SEEDS]: 9,
        },
        dependency: ARMOR.CURRANT_GHILLIE_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default scoutSuit;