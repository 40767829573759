import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const yorshProtectiveArmoredSuit = new Armor(ARMOR.YORSH_PROTECTIVE_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -15,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 25,
        [STAT.bulletDmgFactor]: 103,
        [STAT.tearDmgFactor]: 236,
        [STAT.explosionDmgFactor]: 133,
        [STAT.electraDmgFactor]: 593,
        [STAT.burnDmgFactor]: 59,
        [STAT.chemicalBurnDmgFactor]: 59,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 40,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 180,
        [STAT.bleedingProtection]: 71,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default yorshProtectiveArmoredSuit;