import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";

const kzs2 = new Container(
    CONTAINER.KZS_2,
    {
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 2990,
                [RESOURCE.PIECES_OF_ALUMINUM_CABLE]: 27,
                [RESOURCE.STINKY_ROOT]: 16,
                [RESOURCE.SEEDS]: 12,
            },
            dependency: CONTAINER.KZS_1_CONTAINER,
        },
    ].filter(Boolean), 2, 0.75);

export default kzs2;