import {COMPACT_CONTAINER} from "../container";
import Container from "../../../models/container";
import kzs2 from "./kzs-2";
import kzs2u from "./kzs-2u";
import kzs1Container from "./kzs-1-container";
import kzs1uContainer from "./kzs-1u-container";

const CompactContainers: Record<COMPACT_CONTAINER, Container> = {
    [COMPACT_CONTAINER.KZS_1_CONTAINER]: kzs1Container,
    [COMPACT_CONTAINER.KZS_1U_CONTAINER]: kzs1uContainer,
    [COMPACT_CONTAINER.KZS_2]: kzs2,
    [COMPACT_CONTAINER.KZS_2U]: kzs2u,
}

export default CompactContainers;