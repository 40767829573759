import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const mis116Emerald = new Armor(ARMOR.MIS_116_EMERALD,
    {
        [STAT.bulletDmgFactor]: 49,
        [STAT.tearDmgFactor]: 55,
        [STAT.explosionDmgFactor]: 67,
        [STAT.electraDmgFactor]: 333,
        [STAT.burnDmgFactor]: 778,
        [STAT.chemicalBurnDmgFactor]: 1333,
        [STAT.radiationProtection]: 310,
        [STAT.thermalProtection]: 370,
        [STAT.biologicalProtection]: 410,
        [STAT.psychoProtection]: 310,
        [STAT.bleedingProtection]: 35,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 183270,
            [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 820,
            [RESOURCE.ZONSHROOM]: 850,
            [RESOURCE.BETA_DATA_FRAGMENT]: 310,
            [RESOURCE.CHLORINEX]: 970,
        },
    },
    {
        resources: {
            [RESOURCE.RUB]: 57880,
            [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 480,
            [RESOURCE.ZONSHROOM]: 490,
            [RESOURCE.BETA_DATA_FRAGMENT]: 177,
            [RESOURCE.CHLORINEX]: 560,
        },
        dependency: ARMOR.MIS_99_MALACHITE,
    },
    {
        resources: {
            [RESOURCE.RUB]: 44810,
            [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 130,
            [RESOURCE.ZONSHROOM]: 135,
            [RESOURCE.BETA_DATA_FRAGMENT]: 49,
            [RESOURCE.CHLORINEX]: 153,
        },
        dependency: ARMOR.WORN_EMERALD,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default mis116Emerald;