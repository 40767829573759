import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const strikerArmoredSuit = new Armor(ARMOR.STRIKER_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -3,
        [STAT.maxWeightBonus]: 3,
        [STAT.bulletDmgFactor]: 68,
        [STAT.tearDmgFactor]: 68,
        [STAT.explosionDmgFactor]: 94,
        [STAT.electraDmgFactor]: 27,
        [STAT.burnDmgFactor]: 27,
        [STAT.chemicalBurnDmgFactor]: 27,
        [STAT.radiationProtection]: 100,
        [STAT.thermalProtection]: 0,
        [STAT.biologicalProtection]: 90,
        [STAT.psychoProtection]: 30,
        [STAT.bleedingProtection]: 42,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 3500,
            [RESOURCE.PIECES_OF_COPPER_WIRE]: 5,
            [RESOURCE.RUSTY_MOLD]: 23,
            [RESOURCE.CRAPPITE]: 44,
        },
        dependency: ARMOR.WORM_ARMORED_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 3040,
            [RESOURCE.PIECES_OF_COPPER_WIRE]: 4,
            [RESOURCE.RUSTY_MOLD]: 20,
            [RESOURCE.CRAPPITE]: 38,
        },
        dependency: ARMOR.WORN_STRIKER_ARMORED_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 3500,
            [RESOURCE.PIECES_OF_COPPER_WIRE]: 5,
            [RESOURCE.RUSTY_MOLD]: 23,
            [RESOURCE.CRAPPITE]: 44,
        },
        dependency: ARMOR.BERILL_5_M_ARMORED_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default strikerArmoredSuit;