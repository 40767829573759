import {HEAVY_BACKPACK} from "../container";
import Container from "../../../models/container";
import gameBag from "./game-bag";
import npaBackpack from "./npa-backpack";

const HeavyBackpacks: Record<HEAVY_BACKPACK, Container> = {
    [HEAVY_BACKPACK.NPA_BACKPACK]: npaBackpack,
    [HEAVY_BACKPACK.GAME_BAG]: gameBag
}

export default HeavyBackpacks;