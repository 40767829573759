import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const coatWithBodyArmor = new Armor(ARMOR.COAT_WITH_BODY_ARMOR,
    {
        [STAT.bulletDmgFactor]: 23,
        [STAT.tearDmgFactor]: 24,
        [STAT.explosionDmgFactor]: 31,
        [STAT.electraDmgFactor]: 9,
        [STAT.burnDmgFactor]: 9,
        [STAT.chemicalBurnDmgFactor]: 9,
        [STAT.radiationProtection]: 0,
        [STAT.thermalProtection]: 0,
        [STAT.biologicalProtection]: 0,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 21,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 1770,
            [RESOURCE.GREEN_MOLD]: 6,
            [RESOURCE.SWAMP_STONE]: 11,
        },
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default coatWithBodyArmor;