import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const beastSlayerExoarmor = new Armor(ARMOR.BEAST_SLAYER_EXOARMOR,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 25,
        [STAT.bulletDmgFactor]: 116,
        [STAT.tearDmgFactor]: 335,
        [STAT.explosionDmgFactor]: 160,
        [STAT.electraDmgFactor]: 71,
        [STAT.burnDmgFactor]: 71,
        [STAT.chemicalBurnDmgFactor]: 71,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 50,
        [STAT.biologicalProtection]: 240,
        [STAT.psychoProtection]: 220,
        [STAT.bleedingProtection]: 80,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 2218840,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1640,
            [RESOURCE.RED_FERN]: 3850,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 600,
            [RESOURCE.SUBSTANCE_07270]: 2580,
            [RESOURCE.ANOMALOUS_SERUM]: 75,
        },
        dependency: ARMOR.WORN_BEAST_SLAYER,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default beastSlayerExoarmor;