import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const punisherArmoredSuit = new Armor(ARMOR.PUNISHER_ARMORED_SUIT,
    {
        [STAT.speedModifier]: 8,
        [STAT.maxWeightBonus]: 20,
        [STAT.bulletDmgFactor]: 135,
        [STAT.tearDmgFactor]: 160,
        [STAT.explosionDmgFactor]: 206,
        [STAT.electraDmgFactor]: 178,
        [STAT.burnDmgFactor]: 178,
        [STAT.chemicalBurnDmgFactor]: 178,
        [STAT.radiationProtection]: 210,
        [STAT.thermalProtection]: 160,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 200,
        [STAT.bleedingProtection]: 61.5,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 4208990,
            [RESOURCE.QUANTUM_BATTERY]: 1800,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1550,
            [RESOURCE.RED_FERN]: 3230,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 1290,
            [RESOURCE.SUBSTANCE_07270]: 4850,
            [RESOURCE.ANOMALOUS_SERUM]: 90,
        },
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default punisherArmoredSuit;