import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const karouselDusha = new Artifact(ARTIFACT.KAROUSEL_DUSHA, {
    [STAT.regenerationBonus]: [3, 5],
    [STAT.radiationAccumulation]: [-3, -6],
}, {
    [STAT.staminaRegenerationBonus]: [3, 5],
    [STAT.regenerationBonus]: [1.5, 2.5],
    [STAT.radiationAccumulation]: [0.75, 1.25],
});

export default karouselDusha;