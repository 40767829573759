import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornSaturnSuit = new Armor(ARMOR.WORN_SATURN_SUIT,
    {
        [STAT.maxWeightBonus]: 6,
        [STAT.bulletDmgFactor]: 77,
        [STAT.tearDmgFactor]: 87,
        [STAT.explosionDmgFactor]: 105,
        [STAT.electraDmgFactor]: 945,
        [STAT.burnDmgFactor]: 945,
        [STAT.chemicalBurnDmgFactor]: 945,
        [STAT.radiationProtection]: 370,
        [STAT.thermalProtection]: 370,
        [STAT.biologicalProtection]: 405,
        [STAT.psychoProtection]: 370,
        [STAT.bleedingProtection]: 45,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 1224700,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1820,
            [RESOURCE.RED_FERN]: 1050,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 360,
            [RESOURCE.SUBSTANCE_07270]: 1570,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.DAMAGED_SATURN_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 1224700,
            [RESOURCE.LIMBOPLASMA]: 24,
            [RESOURCE.BITTERLEAF]: 1160,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 264,
            [RESOURCE.LIM]: 930,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.DAMAGED_SATURN_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default wornSaturnSuit;