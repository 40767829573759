import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const puzirOskolok = new Artifact(ARTIFACT.PUZIR_OSKOLOK, {
    [STAT.healthBonus]: [2, 5],
    [STAT.healEfficiency]: [5, 15],
    [STAT.biologicalAccumulation]: [-3, -8],
}, {
    [STAT.healEfficiency]: [2.5, 7.5],
    [STAT.regenerationBonus]: [2.5, 5],
    [STAT.biologicalAccumulation]: [0.75, 2],
});

export default puzirOskolok;