import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const jarkaMamybusi = new Artifact(ARTIFACT.JARKA_MAMYBUSI, {
    [STAT.healthBonus]: [1, 3],
    [STAT.healEfficiency]: [10, 20],
    [STAT.radiationAccumulation]: [0, -3],
}, {
    [STAT.staminaBonus]: [5, 10],
    [STAT.healthBonus]: [0.25, 0.75],
    [STAT.radiationAccumulation]: [0.25, 0.75],
});

export default jarkaMamybusi;