import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const psz10ArmoredSuit = new Armor(ARMOR.PSZ_10_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -4,
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 106,
        [STAT.tearDmgFactor]: 115,
        [STAT.explosionDmgFactor]: 154,
        [STAT.electraDmgFactor]: 267,
        [STAT.burnDmgFactor]: 267,
        [STAT.chemicalBurnDmgFactor]: 267,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 90,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 160,
        [STAT.bleedingProtection]: 53,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 604630,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 275,
            [RESOURCE.RED_FERN]: 216,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 420,
            [RESOURCE.SUBSTANCE_07270]: 650,
        },
        dependency: ARMOR.WORN_PSZ_10,
    },
    {
        resources: {
            [RESOURCE.RUB]: 604630,
            [RESOURCE.LIMBOPLASMA]: 3,
            [RESOURCE.BITTERLEAF]: 440,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 100,
            [RESOURCE.LIM]: 500,
        },
        dependency: ARMOR.WORN_PSZ_10,
    },
    {
        resources: {
            [RESOURCE.RUB]: 604630,
            [RESOURCE.LIMBOPLASMA]: 3,
            [RESOURCE.BITTERLEAF]: 440,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 100,
            [RESOURCE.LIM]: 500,
        },
        dependency: ARMOR.WORN_KINGPIN,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default psz10ArmoredSuit;