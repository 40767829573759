import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const bearsDen6Container = new Container(
    CONTAINER.BEARS_DEN_6_CONTAINER,
    {
        [STAT.speedModifier]: -2,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 1774680,
                [RESOURCE.QUANTUM_BATTERY]: 420,
                [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1950,
                [RESOURCE.RED_FERN]: 1630,
                [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 770,
                [RESOURCE.SUBSTANCE_07270]: 2450,
                [RESOURCE.ANOMALOUS_SERUM]: 30,
            },
            dependency: CONTAINER.BEARS_DEN_4_CONTAINER,
        },
        {
            resources: {
                [RESOURCE.RUB]: 1774680,
                [RESOURCE.ANOMALOUS_BATTERY]: 28,
                [RESOURCE.LIMBOPLASMA]: 21,
                [RESOURCE.BITTERLEAF]: 1990,
                [RESOURCE.LAMBDA_DATA_FRAGMENT]: 570,
                [RESOURCE.LIM]: 1590,
                [RESOURCE.ANOMALOUS_SERUM]: 30,
            },
            dependency: CONTAINER.BEARS_DEN_4_CONTAINER,
        },
        {
            resources: {
                [RESOURCE.RUB]: 719950,
                [RESOURCE.ANOMALOUS_BATTERY]: 12,
                [RESOURCE.LIMBOPLASMA]: 9,
                [RESOURCE.BITTERLEAF]: 850,
                [RESOURCE.LAMBDA_DATA_FRAGMENT]: 240,
                [RESOURCE.LIM]: 680,
                [RESOURCE.ANOMALOUS_SERUM]: 30,
            },
            dependency: CONTAINER.FORAGER_CONTAINER,
        },
    ].filter(Boolean), 6, 0.6);

export default bearsDen6Container;