import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const currantGhillieSuit = new Armor(ARMOR.CURRANT_GHILLIE_SUIT,
    {
        [STAT.maxWeightBonus]: 3,
        [STAT.bulletDmgFactor]: 38,
        [STAT.tearDmgFactor]: 40,
        [STAT.explosionDmgFactor]: 52,
        [STAT.electraDmgFactor]: 15,
        [STAT.burnDmgFactor]: 15,
        [STAT.chemicalBurnDmgFactor]: 15,
        [STAT.radiationProtection]: 30,
        [STAT.thermalProtection]: 0,
        [STAT.biologicalProtection]: 30,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 31,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 2070,
            [RESOURCE.GREEN_MOLD]: 7,
            [RESOURCE.SWAMP_STONE]: 13,
        },
        dependency: ARMOR.COAT_WITH_BODY_ARMOR,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default currantGhillieSuit;