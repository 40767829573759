import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const expeditionSuit = new Armor(ARMOR.EXPEDITION_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.speedModifier]: 2,
        [STAT.artefaktHeal]: 1.5,
        [STAT.radiationAccumulation]: 2,
        [STAT.bleedingAccumulation]: 0.5,
        [STAT.bulletDmgFactor]: 79,
        [STAT.tearDmgFactor]: 93,
        [STAT.explosionDmgFactor]: 111,
        [STAT.electraDmgFactor]: 474,
        [STAT.burnDmgFactor]: 474,
        [STAT.chemicalBurnDmgFactor]: 474,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 200,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 200,
        [STAT.bleedingProtection]: 44,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default expeditionSuit;