import Container from "../../../models/container";
import {CONTAINER} from "../container";

const kzs1uContainer = new Container(
    CONTAINER.KZS_1U_CONTAINER,
    {
    },
    [
    ].filter(Boolean), 1, 0.75);

export default kzs1uContainer;