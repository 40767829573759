import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const kiselPlenka = new Artifact(ARTIFACT.KISEL_PLENKA, {
    [STAT.radiationAccumulation]: [-1, -3],
    [STAT.chemicalBurnDmgFactor]: [15, 30],
}, {
    [STAT.reactionToChemicalBurn]: [1, 2],
    [STAT.chemicalBurnDmgFactor]: [5, 15],
    [STAT.radiationAccumulation]: [0.25, 0.75],
});

export default kiselPlenka;