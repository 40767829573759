import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {STAT} from "../../../models/stats";

const freezerContainer = new Container(
    CONTAINER.FREEZER_CONTAINER,
    {
        [STAT.speedModifier]: -1.5,
        [STAT.thermalAccumulation]: 3,
        [STAT.biologicalAccumulation]: -1.5,
    },
    [
    ].filter(Boolean), 4, 0.85);

export default freezerContainer;