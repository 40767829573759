import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const oshProtectiveSuit = new Armor(ARMOR.OSH_PROTECTIVE_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.speedModifier]: -1,
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 87,
        [STAT.tearDmgFactor]: 262,
        [STAT.explosionDmgFactor]: 120,
        [STAT.electraDmgFactor]: 53,
        [STAT.burnDmgFactor]: 53,
        [STAT.chemicalBurnDmgFactor]: 53,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 30,
        [STAT.biologicalProtection]: 190,
        [STAT.psychoProtection]: 160,
        [STAT.bleedingProtection]: 75,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 580440,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 267,
            [RESOURCE.RED_FERN]: 630,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 175,
            [RESOURCE.SUBSTANCE_07270]: 420,
        },
        dependency: ARMOR.WORN_OSH_PROTECTIVE_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 580440,
            [RESOURCE.LIMBOPLASMA]: 7,
            [RESOURCE.BITTERLEAF]: 510,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 59,
            [RESOURCE.LIM]: 272,
        },
        dependency: ARMOR.WORN_OSH_PROTECTIVE_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default oshProtectiveSuit;