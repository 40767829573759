import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const kiselSlyda = new Artifact(ARTIFACT.KISEL_SLYDA, {
    [STAT.regenerationBonus]: [1, 2],
    [STAT.reactionToTear]: [1.5, 3],
    [STAT.biologicalAccumulation]: [-2, -5],
}, {
    [STAT.reactionToTear]: [0.75, 1.5],
    [STAT.biologicalAccumulation]: [0.5, 1.25],
    [STAT.bleedingAccumulation]: [1, 3],
});

export default kiselSlyda;