import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const puzirSpiral = new Artifact(ARTIFACT.PUZIR_SPIRAL, {
    [STAT.speedModifier]: [2, 4],
    [STAT.maxWeightBonus]: [3, 5],
    [STAT.radiationAccumulation]: [-2, -5],
}, {
    [STAT.speedModifier]: [1, 2],
    [STAT.healEfficiency]: [5, 10],
    [STAT.radiationAccumulation]: [0.5, 1.25],
});

export default puzirSpiral;