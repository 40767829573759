import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const electraBengal = new Artifact(ARTIFACT.ELECTRA_BENGAL, {
    [STAT.staminaBonus]: [5, 10],
    [STAT.electraDmgFactor]: [-50, -100],
}, {
    [STAT.staminaBonus]: [2.5, 5],
    [STAT.staminaRegenerationBonus]: [1, 2.5],
    [STAT.electraDmgFactor]: [20, 40],
});

export default electraBengal;