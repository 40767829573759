import Armor from "../../../models/armor";
import {DISCOVERER_ARMOR} from "../armor";
import wornExoskeleton from "./worn-exoskeleton";
import exoskeleton from "./exoskeleton";
import wornSkat9m from "./worn-skat-9m";
import gorka3Suit from "./gorka-3-suit";
import wornHawkArmoredSuit from "./worn-hawk-armored-suit";
import wornMockingbirdArmoredSuit from "./worn-mockingbird-armored-suit";
import zaryaSuit from "./zarya-suit";
import zaryaSuitWithGasMask from "./zarya-suit-with-gas-mask";
import touristSuit from "./tourist-suit";
import banditSuit from "./bandit-suit";
import banditSuitWithGasMask from "./bandit-suit-with-gas-mask";
import kleptomaniacSuit from "./kleptomaniac-suit";
import voshodHeavyArmoredSuit from "./voshod-heavy-armored-suit";
import thugHeavyArmoredSuit from "./thug-heavy-armored-suit";
import hawkArmoredSuit from "./hawk-armored-suit";
import mockingbirdArmoredSuit from "./mockingbird-armored-suit";
import skat9bArmoredSuit from "./skat-9b-armored-suit";
import trumpExoskeleton from "./trump-exoskeleton";
import psz10ArmoredSuit from "./psz-10-armored-suit";
import kingpinArmoredSuit from "./kingpin-armored-suit";
import skat9mArmoredSuit from "./skat-9m-armored-suit";
import wornVoshodHeavyArmoredSuit from "./worn-voshod-heavy-armored-suit";
import wornThugHeavyArmoredSuit from "./worn-thug-heavy-armored-suit";
import wornPsz10 from "./worn-psz-10";
import wornKingpin from "./worn-kingpin";
import wornTrump from "./worn-trump";
import damagedSkat9m from "./damaged-skat-9m";
import skat10ArmoredSuit from "./skat-10-armored-suit";
import wornTourist from "./worn-tourist";
import wornKleptomaniac from "./worn-kleptomaniac";
import brigandArmoredSuit from "./brigand-armored-suit";
import grenadierExoskeleton from "./grenadier-exoskeleton";
import muleExoskeleton from "./mule-exoskeleton";
import aceExoskeleton from "./ace-exoskeleton";
import wornMule from "./worn-mule";
import wornAce from "./worn-ace";
import punisherArmoredSuit from "./punisher-armored-suit";

const DiscovererArmor: Record<DISCOVERER_ARMOR, Armor> = {
    [DISCOVERER_ARMOR.GORKA_3_SUIT]: gorka3Suit,
    [DISCOVERER_ARMOR.WORN_HAWK_ARMORED_SUIT]: wornHawkArmoredSuit,
    [DISCOVERER_ARMOR.WORN_MOCKINGBIRD_ARMORED_SUIT]: wornMockingbirdArmoredSuit,
    [DISCOVERER_ARMOR.ZARYA_SUIT]: zaryaSuit,
    [DISCOVERER_ARMOR.ZARYA_SUIT_WITH_GAS_MASK]: zaryaSuitWithGasMask,
    [DISCOVERER_ARMOR.TOURIST_SUIT]: touristSuit,
    [DISCOVERER_ARMOR.BANDIT_SUIT]: banditSuit,
    [DISCOVERER_ARMOR.BANDIT_SUIT_WITH_GAS_MASK]: banditSuitWithGasMask,
    [DISCOVERER_ARMOR.KLEPTOMANIAC_SUIT]: kleptomaniacSuit,
    [DISCOVERER_ARMOR.VOSHOD_HEAVY_ARMORED_SUIT]: voshodHeavyArmoredSuit,
    [DISCOVERER_ARMOR.THUG_HEAVY_ARMORED_SUIT]: thugHeavyArmoredSuit,
    [DISCOVERER_ARMOR.HAWK_ARMORED_SUIT]: hawkArmoredSuit,
    [DISCOVERER_ARMOR.MOCKINGBIRD_ARMORED_SUIT]: mockingbirdArmoredSuit,
    [DISCOVERER_ARMOR.SKAT_9_B_ARMORED_SUIT]: skat9bArmoredSuit,
    [DISCOVERER_ARMOR.EXOSKELETON]: exoskeleton,
    [DISCOVERER_ARMOR.TRUMP_EXOSKELETON]: trumpExoskeleton,
    [DISCOVERER_ARMOR.PSZ_10_ARMORED_SUIT]: psz10ArmoredSuit,
    [DISCOVERER_ARMOR.KINGPIN_ARMORED_SUIT]: kingpinArmoredSuit,
    [DISCOVERER_ARMOR.SKAT_9_M_ARMORED_SUIT]: skat9mArmoredSuit,
    [DISCOVERER_ARMOR.WORN_VOSHOD_HEAVY_ARMORED_SUIT]: wornVoshodHeavyArmoredSuit,
    [DISCOVERER_ARMOR.WORN_THUG_HEAVY_ARMORED_SUIT]: wornThugHeavyArmoredSuit,
    [DISCOVERER_ARMOR.WORN_PSZ_10]: wornPsz10,
    [DISCOVERER_ARMOR.WORN_KINGPIN]: wornKingpin,
    [DISCOVERER_ARMOR.WORN_EXOSKELETON]: wornExoskeleton,
    [DISCOVERER_ARMOR.WORN_TRUMP]: wornTrump,
    [DISCOVERER_ARMOR.WORN_SKAT_9_M]: wornSkat9m,
    [DISCOVERER_ARMOR.DAMAGED_SKAT_9_M]: damagedSkat9m,
    [DISCOVERER_ARMOR.SKAT_10_ARMORED_SUIT]: skat10ArmoredSuit,
    [DISCOVERER_ARMOR.WORN_TOURIST]: wornTourist,
    [DISCOVERER_ARMOR.WORN_KLEPTOMANIAC]: wornKleptomaniac,
    [DISCOVERER_ARMOR.BRIGAND_ARMORED_SUIT]: brigandArmoredSuit,
    [DISCOVERER_ARMOR.GRENADIER_EXOSKELETON]: grenadierExoskeleton,
    [DISCOVERER_ARMOR.MULE_EXOSKELETON]: muleExoskeleton,
    [DISCOVERER_ARMOR.ACE_EXOSKELETON]: aceExoskeleton,
    [DISCOVERER_ARMOR.WORN_MULE]: wornMule,
    [DISCOVERER_ARMOR.WORN_ACE]: wornAce,
    [DISCOVERER_ARMOR.PUNISHER_ARMORED_SUIT]: punisherArmoredSuit

}

export default DiscovererArmor;