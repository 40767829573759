import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const ip4m = new Armor(ARMOR.IP_4_M,
    {
        [STAT.staminaBonus]: -15,
        [STAT.bulletDmgFactor]: 10,
        [STAT.tearDmgFactor]: 15,
        [STAT.explosionDmgFactor]: 19,
        [STAT.electraDmgFactor]: 148,
        [STAT.burnDmgFactor]: 148,
        [STAT.chemicalBurnDmgFactor]: 148,
        [STAT.radiationProtection]: 100,
        [STAT.thermalProtection]: 100,
        [STAT.biologicalProtection]: 100,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 10,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 2780,
            [RESOURCE.GREEN_MOLD]: 26,
        },
        dependency: ARMOR.RAINCOAT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default ip4m;