import {ArmorLevelEfficiency, ARTIFACT_POSITIVE_STAT_PERCENT_PER_LEVEL, Level} from "../constants/base";

export const getArmorValueAfterLevelModified = (value: number, level: Level) => {
    return (value * (10000 + 100 * ArmorLevelEfficiency[level])) / 10000;
}

export const getArtifactValueAfterLevelModified = (value: number, level: Level, increasing = true) => {
    return (value * (100 + level * ARTIFACT_POSITIVE_STAT_PERCENT_PER_LEVEL * (increasing ? 1 : -1))) / 100;
}

export const getValueInRangeByCoefficient = (min: number, max: number, coefficient: number) => {
    return coefficient <= 1 ? min + (max - min) * coefficient : min <= max ? max * coefficient : max / coefficient;
};

export const getArtifactStatValue = (min: number, max: number, coefficient: number, level: Level) => {
    return floatFix(getArtifactValueAfterLevelModified(getValueInRangeByCoefficient(min, max, coefficient), level, min <= max), 10);
}

export const floatFix = (value: number, precision: number = 2): number => +value.toFixed(precision);