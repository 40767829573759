import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const puhKolobok = new Artifact(ARTIFACT.PUH_KOLOBOK, {
    [STAT.radiationDmgFactor]: [5, 15],
    [STAT.radiationAccumulation]: [2, 5],
    [STAT.biologicalAccumulation]: [-2, -5],
}, {
    [STAT.radiationDmgFactor]: [2.5, 7.5],
    [STAT.tearDmgFactor]: [5, 10],
    [STAT.biologicalAccumulation]: [0.5, 1.25],
});

export default puhKolobok;