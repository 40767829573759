import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const jarkaKaplya = new Artifact(ARTIFACT.JARKA_KAPLYA, {
    [STAT.staminaBonus]: [-5, -10],
    [STAT.radiationAccumulation]: [1, 3],
}, {
    [STAT.radiationAccumulation]: [0.25, 0.75],
    [STAT.staminaBonus]: [2, 4],
    [STAT.maxWeightBonus]: [1, 3],
});

export default jarkaKaplya;