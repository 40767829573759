import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const bearsDen4Container = new Container(
    CONTAINER.BEARS_DEN_4_CONTAINER,
    {
        [STAT.speedModifier]: -1.5,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 44410,
                [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 154,
                [RESOURCE.BLUE_YEAST]: 230,
                [RESOURCE.BETA_DATA_FRAGMENT]: 73,
                [RESOURCE.PICKLE]: 90,
            },
            dependency: CONTAINER.KZS_3,
        },
        {
            resources: {
                [RESOURCE.RUB]: 77420,
                [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 268,
                [RESOURCE.BLUE_YEAST]: 400,
                [RESOURCE.BETA_DATA_FRAGMENT]: 126,
                [RESOURCE.PICKLE]: 157,
            },
        },
    ].filter(Boolean), 4, 0.6);

export default bearsDen4Container;