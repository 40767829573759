import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const cd3aArmoredSuit = new Armor(ARMOR.CD_3_A_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 73,
        [STAT.tearDmgFactor]: 136,
        [STAT.explosionDmgFactor]: 137,
        [STAT.electraDmgFactor]: 167,
        [STAT.burnDmgFactor]: 167,
        [STAT.chemicalBurnDmgFactor]: 167,
        [STAT.radiationProtection]: 150,
        [STAT.thermalProtection]: 100,
        [STAT.biologicalProtection]: 150,
        [STAT.psychoProtection]: 150,
        [STAT.bleedingProtection]: 59,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default cd3aArmoredSuit;