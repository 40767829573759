import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const voronkaCrystal = new Artifact(ARTIFACT.VORONKA_CRYSTAL, {
    [STAT.regenerationBonus]: [1, 2.5],
    [STAT.radiationAccumulation]: [2, 4],
    [STAT.psychoAccumulation]: [-4, -6],
    [STAT.tearDmgFactor]: [15, 25],
}, {
    [STAT.staminaRegenerationBonus]: [2.5, 5],
    [STAT.tearDmgFactor]: [3.5, 6.25],
    [STAT.psychoAccumulation]: [1, 1.75],
});

export default voronkaCrystal;