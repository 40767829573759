import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const healingBerill = new Armor(ARMOR.HEALING_BERILL,
    {
        [STAT.staminaBonus]: -3,
        [STAT.maxWeightBonus]: 3,
        [STAT.regenerationBonus]: 4,
        [STAT.staminaRegenerationBonus]: 5,
        [STAT.bulletDmgFactor]: 61,
        [STAT.tearDmgFactor]: 61,
        [STAT.explosionDmgFactor]: 84,
        [STAT.electraDmgFactor]: 24,
        [STAT.burnDmgFactor]: 24,
        [STAT.chemicalBurnDmgFactor]: 24,
        [STAT.radiationProtection]: 90,
        [STAT.thermalProtection]: 0,
        [STAT.biologicalProtection]: 75,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 39,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default healingBerill;