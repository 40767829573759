import Artifact, {ArtifactData} from "../../../../models/artifact";
import React, {useCallback, useEffect, useState} from "react";
import {Rarity} from "../../../../constants/base";
import {Button, ButtonGroup, Dropdown} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ArtifactDataFactory from "../../../../models/artifact-data-factory";

type ArtifactRaritySelectorProps = {
    artifact: ArtifactData,
    setArtifact: (artifact: ArtifactData) => void,
    index: number
}

export const ARTIFACT_RARITY_SELECTOR_TEST_ID = 'artifact-rarity-selector';

const ArtifactRaritySelector: React.FC<ArtifactRaritySelectorProps> = ({ artifact, setArtifact, index}) => {
    const {t} = useTranslation();
    const [rarities, setRarities] = useState<Rarity[]>([]);
    useEffect(() => {
        setRarities(Artifact.getRarityByQuality(artifact.quality));
    }, [artifact]);
    const onRarityChange = useCallback((rarity: Rarity) => () => {
        const newArtifact = ArtifactDataFactory.changeRarity(artifact, rarity);
        if(newArtifact === artifact) return;

        setArtifact(newArtifact);
    }, [setArtifact, artifact]);

    if(rarities.length === 0) return (<></>);

    const className = `bg-${artifact.rarity} border-${artifact.rarity}`;

    if(rarities.length === 1) return (
        <Button
            disabled={true}
            className={className}
            data-testid={ARTIFACT_RARITY_SELECTOR_TEST_ID}
            id={`artifact-rarity-${artifact.id}-${index}`}
        >
            {t(`rarities.${artifact.rarity}`)}
        </Button>
    )

    return (
        <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
                className={className}
                data-testid={ARTIFACT_RARITY_SELECTOR_TEST_ID}
                id={`artifact-rarity-${artifact.id}-${index}`}
            >
                {t(`rarities.${artifact.rarity}`)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {rarities.map(rarity => (
                    <Dropdown.Item key={rarity} onClick={onRarityChange(rarity)}>{t(`rarities.${rarity}`)}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
};

export default ArtifactRaritySelector;