import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const hopliteArmoredSuit = new Armor(ARMOR.HOPLITE_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -8,
        [STAT.speedModifier]: -1,
        [STAT.maxWeightBonus]: 5,
        [STAT.bulletDmgFactor]: 106,
        [STAT.tearDmgFactor]: 106,
        [STAT.explosionDmgFactor]: 147,
        [STAT.electraDmgFactor]: 41,
        [STAT.burnDmgFactor]: 41,
        [STAT.chemicalBurnDmgFactor]: 41,
        [STAT.radiationProtection]: 170,
        [STAT.thermalProtection]: 20,
        [STAT.biologicalProtection]: 140,
        [STAT.psychoProtection]: 75,
        [STAT.bleedingProtection]: 52,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 54010,
            [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 63,
            [RESOURCE.BLUE_YEAST]: 223,
            [RESOURCE.ALPHA_DATA_FRAGMENT]: 56,
            [RESOURCE.PICKLE]: 219,
        },
        dependency: ARMOR.WORN_HOPLITE_ARMORED_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default hopliteArmoredSuit;