import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const thugHeavyArmoredSuit = new Armor(ARMOR.THUG_HEAVY_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 12,
        [STAT.bulletDmgFactor]: 107,
        [STAT.tearDmgFactor]: 116,
        [STAT.explosionDmgFactor]: 152,
        [STAT.electraDmgFactor]: 71,
        [STAT.burnDmgFactor]: 71,
        [STAT.chemicalBurnDmgFactor]: 71,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 60,
        [STAT.biologicalProtection]: 150,
        [STAT.psychoProtection]: 135,
        [STAT.bleedingProtection]: 55,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 96290,
            [RESOURCE.REMAINS_OF_BATTERIES]: 241,
            [RESOURCE.NORTHERN_MOSS]: 810,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 93,
            [RESOURCE.DOPESTONE]: 430,
        },
        dependency: ARMOR.WORN_THUG_HEAVY_ARMORED_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 88600,
            [RESOURCE.REMAINS_OF_BATTERIES]: 222,
            [RESOURCE.NORTHERN_MOSS]: 740,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 86,
            [RESOURCE.DOPESTONE]: 400,
        },
        dependency: ARMOR.MOCKINGBIRD_ARMORED_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 96290,
            [RESOURCE.REMAINS_OF_BATTERIES]: 241,
            [RESOURCE.NORTHERN_MOSS]: 810,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 93,
            [RESOURCE.DOPESTONE]: 430,
        },
        dependency: ARMOR.WORN_VOSHOD_HEAVY_ARMORED_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default thugHeavyArmoredSuit;