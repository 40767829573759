import {ITEM} from "../data/item";

export enum RESOURCE {
    RUB = 'RUB',
    GREEN_MOLD = '191kg',
    SWAMP_STONE = '9dknl',
    STINKY_ROOT = 'w30g2',
    SEEDS = 'y405k',
    PIECES_OF_ALUMINUM_CABLE = 'kr5oy',
    RUSTY_MOLD = 'l093k',
    CRAPPITE = 'j0z30',
    PIECES_OF_COPPER_WIRE = 'rj6gv',
    SPRIG_OF_CHERNOBYL_CHAMOMILE = 'pj52w',
    PIECE_OF_INFERNAL_COAL = 'nkgv1',
    REMAINS_OF_A_RADIO_TRANSMITTER = 'vn0yr',
    BLUE_YEAST = 'z30qy',
    PICKLE = 'gn9l6',
    ZONSHROOM = '009o9',
    CHLORINEX = 'rj6ov',
    REMAINS_OF_SCIENTIFIC_EQUIPMENT = 'mr1jy',
    DOPESTONE = '77o96',
    NORTHERN_MOSS = '6ol3p',
    REMAINS_OF_BATTERIES = '9dkgl',
    RED_FERN = 'dr6nj',
    SUBSTANCE_07270 = 'vn0wr',
    REMAINS_OF_A_PSY_TRACKER = '21gkl',
    QUANTUM_BATTERY = '77ov6',
    ANOMALOUS_BATTERY = '9d1qy',
    LIMBOPLASMA = '19402',
    BITTERLEAF = '34305',
    LIM = '21365',

    ALPHA_DATA_FRAGMENT = '556z1',
    BETA_DATA_FRAGMENT = 'olzr6',
    GAMMA_DATA_FRAGMENT = '191ng',
    DIGAMMA_DATA_FRAGMENT = '34zjl',
    LAMBDA_DATA_FRAGMENT = '6o7m0',

    ANOMALOUS_SERUM = '9dk7l',
}

export const REVERSE_RESOURCE: Record<RESOURCE, string> = Object.entries(RESOURCE).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
}, {} as Record<RESOURCE, string>);

export type Recipe = {
    resources: Partial<Record<RESOURCE, number>>
    dependency?: ITEM
}
