import {ArmorData} from "../../models/armor";

export enum COMBAT_ARMOR {
    COAT_WITH_BODY_ARMOR = "4ql2r",
    REPORTERS_ARMOR = "knqly",
    WORN_FLEECE_PROTECTIVE_SUIT = "p6mrw",
    WORN_HOPLITE_ARMORED_SUIT = "vj1rr",
    HUNTERS_CLOAK = "dmgqj",
    FLEECE_PROTECTIVE_SUIT = "2oqdl",
    HOPLITE_ARMORED_SUIT = "3g6rl",
    TRAPPER_SUIT = "7lmr6",
    WORN_TRAPPER_SUIT = "6w0rp",
    WORN_STRIKER_ARMORED_SUIT = "g4y06",
    CLOAK = "4ql0r",
    BANDIT_CLOAK = "knqmy",
    BERILL_5_M_ARMORED_SUIT = "p6m3w",
    WORM_ARMORED_SUIT = "vj12r",
    HEALING_BERILL = "dmgjj",
    CURRANT_GHILLIE_SUIT = "3g6nl",
    HOUNDMASTER_PROTECTIVE_SUIT = "7lmn6",
    SCOUT_SUIT = "6w06p",
    STRIKER_ARMORED_SUIT = "96n9l",
    OSH_PROTECTIVE_SUIT = "dmgvj",
    JAEGER_EXOARMOR = "2oqll",
    LEGIONNAIRE_HEAVY_ARMORED_SUIT = "wjg62",
    CENTURION_ARMORED_EXOSKELETON = "4ql1r",
    WORN_OSH_PROTECTIVE_SUIT = "n4ry1",
    WORN_LEGIONNAIRE = "96n5l",
    DAMAGED_JAEGER = "5lrvq",
    WORN_JAEGER = "y35p3",
    DAMAGED_CENTURION = "wjgp3",
    WORN_CENTURION = "4qlvo",
    YORSH_PROTECTIVE_ARMORED_SUIT = "5lr4q",
    LYNX_ARMORED_SUIT = "knq0p",
    // BEAR_EXOSKELETON = "qjoz3",
    PHANTOM_HUNTER_ARMORED_SUIT = "j5krg",
    RATCATCHER_EXOARMOR = "p6mg4",
    GHILLIE_SUIT = "dmgw2",
    CD_1_SUIT = "g4y3n",
    CD_2_ARMORED_SUIT = "zzyjk",
    CD_3_A_ARMORED_SUIT = "g4y5p",
    ALTYN_SET = "zzy1n",
    CD_3_B_ARMORED_SUIT = "5lr20",
    SHADE_HUNTER_HEAVY_ARMORED_SUIT = "y359o",
    ALTYN_T_HEAVY_SET = "wjg2o",
    BLIZZARD_BOUND_HEAVY_ARMORED_SUIT = "qjomk",
    MODIFIED_EXOSKELETON = "5l1q0",
    SBA_TANK = "y3q1o",
    CD_4_ARMORED_SUIT = "qj1lk",
    BEAST_SLAYER_EXOARMOR = "wj49p",
    WORN_BEAST_SLAYER = "4q9op",
    APOSTLE_HEAVY_ARMORED_SUIT = "ly3kj",
    VANGUARD_ARMORED_SUIT = "0rn7d",
    REITER_SUPER_HEAVY_ARMORED_SUIT = "96yz0",
    GRANITE_SUPER_HEAVY_ARMORED_SUIT = "1rpl6",
    CHIEFTAIN_SUPER_HEAVY_ARMORED_SUIT = "g4310"
}

export enum SCIENTIST_ARMOR {
    RAINCOAT = 'wjgm2',
    IP_4_M = 'wjgo2',
    ZARYA_B_SUIT = 'lyj2k',
    BANDIT_SUIT_WITH_GAS_TANKS = 'ok0m6',
    SEVA_SUIT = 'g4ym6',
    REAPER_SUIT = 'zzy2y',
    MIS_99_AMBER = 'wjg72',
    MIS_99_MALACHITE = '4qlnr',
    MIS_105_TOPAZ = 'rwnzv',
    MIS_113_IOLITE = '0r4q9',
    MIS_116_EMERALD = 'm06vy',
    MIS_122_AMETHYST = 'ok096',
    MIS_X_ATLAS = 'n4r21',
    WORN_MIS_99_AMBER = 'knqgy',
    WORN_MIS_99_M_MALACHITE = 'qjokj',
    WORN_SEVA_SUIT = 'j5k10',
    WORN_REAPER_SUIT = 'lyjzk',
    WORN_TOPAZ = 'knqwp',
    WORN_IOLITE = 'qjor3',
    WORN_EMERALD = 'j5k6g',
    WORN_AMETHYST = 'lyj4o',
    MIS_85_D = 'wjgq3',
    IMPROVED_HAZMAT_SUIT = '96ngz',
    MIS_100_GARNET = 'rwgl5',
    MIS_102_ZIRCON = '0rnw1',
    SATURN_SUIT = '2ovr0',
    WORN_SATURN_SUIT = '3gdoz',
    DAMAGED_SATURN_SUIT = '7lzw3'
}

export enum DISCOVERER_ARMOR {
    GORKA_3_SUIT = 'rwnrv',
    WORN_HAWK_ARMORED_SUIT = 'ok0v6',
    WORN_MOCKINGBIRD_ARMORED_SUIT = 'n4rp1',
    ZARYA_SUIT = 'qjo2j',
    ZARYA_SUIT_WITH_GAS_MASK = 'j5ko0',
    TOURIST_SUIT = 'rwn2v',
    BANDIT_SUIT = '0r429',
    BANDIT_SUIT_WITH_GAS_MASK = 'm06my',
    KLEPTOMANIAC_SUIT = 'n4rm1',
    VOSHOD_HEAVY_ARMORED_SUIT = '5lrn1',
    THUG_HEAVY_ARMORED_SUIT = 'y352k',
    HAWK_ARMORED_SUIT = 'knqvy',
    MOCKINGBIRD_ARMORED_SUIT = 'qjogj',
    SKAT_9_B_ARMORED_SUIT = '3g69l',
    EXOSKELETON = '7lm56',
    TRUMP_EXOSKELETON = '6w0zp',
    PSZ_10_ARMORED_SUIT = 'zzyly',
    KINGPIN_ARMORED_SUIT = '5lrg1',
    SKAT_9_M_ARMORED_SUIT = 'y35jk',
    WORN_VOSHOD_HEAVY_ARMORED_SUIT = 'm065y',
    WORN_THUG_HEAVY_ARMORED_SUIT = 'ok0w6',
    WORN_PSZ_10 = '1rkqg',
    WORN_KINGPIN = 'g4yo6',
    WORN_EXOSKELETON = 'zzy5y',
    WORN_TRUMP = '96n5y',
    WORN_SKAT_9_M = '1rkq2',
    DAMAGED_SKAT_9_M = 'g4yo5',
    SKAT_10_ARMORED_SUIT = 'm06k2',
    WORN_TOURIST = 'vj1pp',
    WORN_KLEPTOMANIAC = 'dmg02',
    BRIGAND_ARMORED_SUIT = '1rkz2',
    GRENADIER_EXOSKELETON = 'g4yq5',
    MULE_EXOSKELETON = 'g43k0',
    ACE_EXOSKELETON = 'zzjk2',
    WORN_MULE = 'kn360',
    WORN_ACE = 'qj1y6',
    PUNISHER_ARMORED_SUIT = 'm034j'
}

export enum COMBINED_ARMOR {
    WORN_AH_2_NOMAD = '96nml',
    DAMAGED_AH_2_NOMAD = '1rkdg',
    AH_1_DRIFTER_SUIT = '2oqnl',
    PS_1_PICKLOCK = '1rk7g',
    AH_2_NOMAD_SUIT = 'j5kv0',
    AH_3_SEEKER_SUIT = 'lyjvk',
    RAPS_AH_4_RAIDER = 'p6m1w',
    RAPS_AH_5_PILGRIM = 'vj14r',
    DAMAGED_AH_3_SEEKER_SUIT = 'rwnqv',
    WORN_AH_3_SEEKER_SUIT = '0r4k9',
    WORN_AH_4_RAIDER = 'p6m4w',
    WORN_AH_5_PILGRIM = 'zzy5m',
    RAPS_SPANNER = 'y35l3',
    EXPEDITION_SUIT = '0r4gy',
    MAGNET_SUIT = '1rkn1',
    TONGA_SUIT = '4qldn',
    ALBATROSS_SCOUT_ARMORED_EXOSKELETON = 'knqkv',
    JD_ZIVCAS_2_A_EXOARMOR = 'g43rp',
    ALBATROSS_INFILTRATOR_ARMORED_EXOSKELETON = 'zzjgn',
    ALBATROSS_HEAVY_ASSAULT_ARMORED_EXOSKELETON = 'wj4no',
    RIGEL_SUIT = 'kn3yv',
    RAPS_AH_6_NOMAD = '5l1yg',
    WORN_AH_6_NOMAD = 'y3qgz',
    HOUNDS_EXOSKELETON = 'rwgmg'

}

export enum NO_ARMOR {
    NONE = 'none',
}

export const ARMOR = { ...COMBAT_ARMOR, ...SCIENTIST_ARMOR, ...DISCOVERER_ARMOR, ...COMBINED_ARMOR, ...NO_ARMOR };
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ARMOR = COMBAT_ARMOR | SCIENTIST_ARMOR | DISCOVERER_ARMOR | COMBINED_ARMOR |NO_ARMOR;

export const REVERSE_ARMOR = Object.entries(ARMOR).reduce((acc, [key, value]) => ({...acc, [value]: key}), {} as Record<ARMOR, string>);

export const emptyArmorFactory = (): ArmorData => ({
    id: ARMOR.NONE,
    level: 0,
});

export enum ARMOR_CONTAINER_TYPE {
    LIGHT_BAG_COMPACT_CONTAINER = 'light-bag-compact-container',
    LIGHT_BAG_STANDARD_CONTAINER = 'light-bag-standard-container',
    LIGHT_BAG_SPACIOUS_CONTAINER = 'light-bag-spacious-container',
    LIGHT_BAG_ALL_CONTAINER = 'light-bag-all-container',
    MEDIUM_BAG_COMPACT_CONTAINER = 'medium-bag-compact-container',
    MEDIUM_BAG_STANDARD_CONTAINER = 'medium-bag-standard-container',
    MEDIUM_BAG_SPACIOUS_CONTAINER = 'medium-bag-spacious-container',
    MEDIUM_BAG_ALL_CONTAINER = 'medium-bag-all-container',
    ALL_BAG_COMPACT_CONTAINER = 'all-bag-compact-container',
    ALL_BAG_STANDARD_CONTAINER = 'all-bag-standard-container',
    ALL_BAG_SPACIOUS_CONTAINER = 'all-bag-spacious-container',
    ALL_BAG_ALL_CONTAINER = 'all',
}

