import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const puzirZerkalo = new Artifact(ARTIFACT.PUZIR_ZERKALO, {
    [STAT.healthBonus]: [-3, -5],
    [STAT.radiationProtection]: [10, 15],
    [STAT.psychoProtection]: [10, 15],
}, {
    [STAT.psychoProtection]: [10, 15],
    [STAT.radiationProtection]: [10, 15],
    [STAT.healthBonus]: [1.25, 2.5],
});

export default puzirZerkalo;