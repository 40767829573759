import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const cd4ArmoredSuit = new Armor(ARMOR.CD_4_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 15,
        [STAT.bulletDmgFactor]: 153,
        [STAT.tearDmgFactor]: 202,
        [STAT.explosionDmgFactor]: 219,
        [STAT.electraDmgFactor]: 167,
        [STAT.burnDmgFactor]: 167,
        [STAT.chemicalBurnDmgFactor]: 167,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 100,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 200,
        [STAT.bleedingProtection]: 67,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default cd4ArmoredSuit;