import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const electraBatareyka = new Artifact(ARTIFACT.ELECTRA_BATAREYKA, {
    [STAT.speedModifier]: [2, 4],
    [STAT.reactionToElectroshock]: [1, 2],
    [STAT.radiationAccumulation]: [-3, -6],
}, {
    [STAT.reactionToElectroshock]: [0.5, 1],
    [STAT.staminaBonus]: [5, 10],
    [STAT.radiationAccumulation]: [0.75, 1.5],
});

export default electraBatareyka;