import {GRAVI_ARTIFACT} from "../artifact";
import Artifact from "../../../models/artifact";
import voronkaGoldfish from "./voronka-goldfish";
import trampolineBraslet from "./trampoline-braslet";
import voronkaVivert from "./voronka-vivert";
import voronkaGravi from "./voronka-gravi";
import voronkaGoldgravi from "./voronka-goldgravi";
import voronkaYantar from "./voronka-yantar";
import voronkaKriogen from "./voronka-kriogen";
import trampolineKamencvet from "./trampoline-kamencvet";
import trampolineMeduza from "./trampoline-meduza";
import trampolineDarkmeduza from "./trampoline-darkmeduza";
import trampolineJilnik from "./trampoline-jilnik";
import trampolineNightstar from "./trampoline-nightstar";
import trampolineProtomeduza from "./trampoline-protomeduza";
import karouselLomot from "./karousel-lomot";
import karouselJelch from "./karousel-jelch";
import karouselGnilec from "./karousel-gnilec";
import karouselDusha from "./karousel-dusha";
import karouselPrujina from "./karousel-prujina";
import karouselOstov from "./karousel-ostov";
import karouselBloodstone from "./karousel-bloodstone";
import voronkaCrystal from "./voronka-crystal";

const GraviArtifacts: Record<GRAVI_ARTIFACT, Artifact> = {
    [GRAVI_ARTIFACT.VORONKA_GOLDFISH]: voronkaGoldfish,
    [GRAVI_ARTIFACT.TRAMPOLINE_BRASLET]: trampolineBraslet,
    [GRAVI_ARTIFACT.VORONKA_VIVERT]: voronkaVivert,
    [GRAVI_ARTIFACT.VORONKA_GRAVI]: voronkaGravi,
    [GRAVI_ARTIFACT.VORONKA_GOLDGRAVI]: voronkaGoldgravi,
    [GRAVI_ARTIFACT.VORONKA_YANTAR]: voronkaYantar,
    [GRAVI_ARTIFACT.VORONKA_KRIOGEN]: voronkaKriogen,
    [GRAVI_ARTIFACT.VORONKA_CRYSTAL]: voronkaCrystal,
    [GRAVI_ARTIFACT.KAROUSEL_BLOODSTONE]: karouselBloodstone,
    [GRAVI_ARTIFACT.KAROUSEL_LOMOT]: karouselLomot,
    [GRAVI_ARTIFACT.KAROUSEL_JELCH]: karouselJelch,
    [GRAVI_ARTIFACT.KAROUSEL_GNILEC]: karouselGnilec,
    [GRAVI_ARTIFACT.KAROUSEL_DUSHA]: karouselDusha,
    [GRAVI_ARTIFACT.KAROUSEL_PRUJINA]: karouselPrujina,
    [GRAVI_ARTIFACT.KAROUSEL_OSTOV]: karouselOstov,
    [GRAVI_ARTIFACT.TRAMPOLINE_KAMENCVET]: trampolineKamencvet,
    [GRAVI_ARTIFACT.TRAMPOLINE_MEDUZA]: trampolineMeduza,
    [GRAVI_ARTIFACT.TRAMPOLINE_DARKMEDUZA]: trampolineDarkmeduza,
    [GRAVI_ARTIFACT.TRAMPOLINE_JILNIK]: trampolineJilnik,
    [GRAVI_ARTIFACT.TRAMPOLINE_NIGHTSTAR]: trampolineNightstar,
    [GRAVI_ARTIFACT.TRAMPOLINE_PROTOMEDUZA]: trampolineProtomeduza,
}

export default GraviArtifacts;