import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const karouselLomot = new Artifact(ARTIFACT.KAROUSEL_LOMOT, {
    [STAT.healthBonus]: [-3, -5],
    [STAT.regenerationBonus]: [2, 3],
}, {
    [STAT.healEfficiency]: [5, 10],
    [STAT.regenerationBonus]: [1, 1.5],
    [STAT.healthBonus]: [0.75, 1.25],
});

export default karouselLomot;