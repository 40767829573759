import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const gorka3Suit = new Armor(ARMOR.GORKA_3_SUIT,
    {
        [STAT.bulletDmgFactor]: 9,
        [STAT.tearDmgFactor]: 15,
        [STAT.explosionDmgFactor]: 29,
        [STAT.electraDmgFactor]: 30,
        [STAT.burnDmgFactor]: 30,
        [STAT.chemicalBurnDmgFactor]: 30,
        [STAT.radiationProtection]: 30,
        [STAT.thermalProtection]: 10,
        [STAT.biologicalProtection]: 30,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 10,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 1130,
        },
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default gorka3Suit;