import {SPECIAL_STAT, STAT} from "../models/stats";
import {ARTIFACT} from "../data/artifact/artifact";
import {CONTAINER} from "../data/container/container";
import {COMBAT_ARMOR, COMBINED_ARMOR, DISCOVERER_ARMOR, SCIENTIST_ARMOR} from "../data/armor/armor";
import {Rarity} from "../constants/base";

const ru = {
    translation: {
        navbar: {
            home: 'Главная',
            calc: 'Калькулятор',
            craft: 'Крафт',
            linkToAnotherRegion: 'Сменить регион на RU',
        },
        overview: {
            title: 'Добро пожаловать на StalCraft Tools!',
            description: 'Это приложение все еще в активной разработке. Если у вас есть предложение или вы хотите сообщить об ошибке, пожалуйста присоединяйтесь к моему <discord>Discord серверу</discord>.',
            functionality: 'Доступная функциональность: <calc>Калькулятор артефактов</calc>',
        },
        stats: {
            [STAT.artefactDamage]: "Урон",
            [STAT.artefactHeal]: "Лечение",
            [STAT.biologicalAccumulation]: "Биол. заражение",
            [STAT.biologicalDmgFactor]: "Сопр. биозаражению",
            [STAT.biologicalProtection]: "Защита от биозаражения",
            [STAT.biologicalProtectionShort]: "Биозащита",
            [STAT.bleedingAccumulation]: "Кровотечение",
            [STAT.bleedingDmgFactor]: "Сопр. кровотечению",
            [STAT.bleedingProtection]: "Защита от кровотечения",
            [STAT.bulletDeflection]: "Отклонение снарядов",
            [STAT.bulletDmgFactor]: "Пулестойкость",
            [STAT.bulletSlowdown]: "Замедление пуль",
            [STAT.burnDmgFactor]: "Защита от огня",
            [STAT.chemicalBurnDmgFactor]: "Химзащита",
            [STAT.combustionAccumulation]: "Горение",
            [STAT.combustionDmgFactor]: "Сопр. горению",
            [STAT.combustionProtection]: "Защита от горения",
            [STAT.electraDmgFactor]: "Электрозащита",
            [STAT.explosionDmgFactor]: "Защита от взрыва",
            [STAT.fallProtection]: "Снижение крона от падения",
            [STAT.frostAccumulation]: "Холод",
            [STAT.frostDmgFactor]: "Сопр. холоду",
            [STAT.frostProtection]: "Защита от холода",
            [STAT.healEfficiency]: "Эффективность лечения",
            [STAT.healthBonus]: "Живучесть",
            [STAT.jumpIncrease]: "Высота прыжка",
            [STAT.maxWeightBonus]: "Переносимый вес",
            [STAT.psychoAccumulation]: "Пси-излучение",
            [STAT.psychoDmgFactor]: "Сопр. пси-излучению",
            [STAT.psychoProtection]: "Защита от пси-излучения",
            [STAT.psychoProtectionShort]: "Пси-защита",
            [STAT.radiationAccumulation]: "Радиация",
            [STAT.radiationDmgFactor]: "Сопр. радиации",
            [STAT.radiationProtection]: "Защита от радиации",
            [STAT.radiationProtectionShort]: "Радиозащита",
            [STAT.reactionToBurn]: "Реакция на ожог",
            [STAT.reactionToChemicalBurn]: "Реакция на хим. ожог",
            [STAT.reactionToElectroshock]: "Реакция на электричество",
            [STAT.reactionToTear]: "Реакция на разрыв",
            [STAT.recoilBonus]: "Отдача",
            [STAT.regenerationBonus]: "Регенерация",
            [STAT.reloadBonus]: "Скорость перезарядки",
            [STAT.speedModifier]: "Скорость",
            [STAT.spreadBonus]: "Разброс",
            [STAT.staminaBonus]: "Выносливость",
            [STAT.staminaRegenerationBonus]: "Восстановление выносливости",
            [STAT.tearDmgFactor]: "Защита от разрыва",
            [STAT.tenacity]: "Стойкость",
            [STAT.thermalAccumulation]: "Температура",
            [STAT.thermalDmgFactor]: "Сопр. температуре",
            [STAT.thermalProtection]: "Термозащита",
            [STAT.totalHp]: "Приведёнка",
            [STAT.toxicAccumulation]: "Отравление",
            [STAT.toxicDmgFactor]: "Сопр. отравлению",
            [STAT.toxicProtection]: "Защита от отравления",
            [STAT.wiggleBonus]: "Покачивание",
            [STAT.artefaktHeal]: "Периодическое лечение",
            [SPECIAL_STAT.polyhedronBlockingDamage]: "Снижает урон",
            [SPECIAL_STAT.polyhedronCooldown]: "Перезарядка",
        },
        artifacts: {
            [ARTIFACT.BLIZZARD_CHICKENGOD]: "Куриный бог",
            [ARTIFACT.BLIZZARD_CRUST]: "Корка",
            [ARTIFACT.BLIZZARD_CRYSTAL]: "Хрусталь",
            [ARTIFACT.BLIZZARD_FAHRENHEIT]: "Фаренгейт",
            [ARTIFACT.BLIZZARD_FROST]: "Иней",
            [ARTIFACT.BLIZZARD_HEEL]: "Каблук",
            [ARTIFACT.BLIZZARD_STORMEYE]: "Глаз бури",
            [ARTIFACT.BLIZZARD_VORTEX]: "Вихрь",
            [ARTIFACT.KAROUSEL_DUSHA]: "Душа",
            [ARTIFACT.KAROUSEL_GNILEC]: "Болотный гнилец",
            [ARTIFACT.KAROUSEL_JELCH]: "Желчь",
            [ARTIFACT.KAROUSEL_BLOODSTONE]: "Кровь камня",
            [ARTIFACT.KAROUSEL_LOMOT]: "Ломоть мяса",
            [ARTIFACT.KAROUSEL_OSTOV]: "Остов",
            [ARTIFACT.KAROUSEL_PRUJINA]: "Пружина",
            [ARTIFACT.ELECTRA_BATAREYKA]: "Батарейка",
            [ARTIFACT.ELECTRA_BENGAL]: "Бенгальский огонь",
            [ARTIFACT.ELECTRA_CRYSTAL]: "Частотный кристалл",
            [ARTIFACT.ELECTRA_DEZINTEGRATOR]: "Дезинтегратор",
            [ARTIFACT.ELECTRA_HELIUM]: "Гелий",
            [ARTIFACT.ELECTRA_MOONLIGHT]: "Лунный свет",
            [ARTIFACT.ELECTRA_PUSTISHKA]: "Пустышка",
            [ARTIFACT.ELECTRA_SNOWFLAKE]: "Снежинка",
            [ARTIFACT.ELECTRA_VSPYSHKA]: "Вспышка",
            [ARTIFACT.JARKA_CRYSTAL]: "Кристалл",
            [ARTIFACT.JARKA_FIREBALL]: "Огненный шар",
            [ARTIFACT.JARKA_GLAZ]: "Глаз",
            [ARTIFACT.JARKA_KAPLYA]: "Капля",
            [ARTIFACT.JARKA_MAMYBUSI]: "Мамины бусы",
            [ARTIFACT.JARKA_PLAMYA]: "Пламя",
            [ARTIFACT.JARKA_SOLNCE]: "Солнце",
            [ARTIFACT.JARKA_VSLEZY]: "Волчьи слёзы",
            [ARTIFACT.KISEL_CRYSTAL]: "Кислотный кристалл",
            [ARTIFACT.KISEL_KISEL]: "Кисель",
            [ARTIFACT.KISEL_PLENKA]: "Плёнка",
            [ARTIFACT.KISEL_POLYHEDRON]: "Многогранник",
            [ARTIFACT.KISEL_PUZIR]: "Пузырь",
            [ARTIFACT.KISEL_SLIZ]: "Слизь",
            [ARTIFACT.KISEL_SLIZNYAK]: "Слизняк",
            [ARTIFACT.KISEL_SLYDA]: "Слюда",
            [ARTIFACT.KISEL_SVETLYAK]: "Светляк",
            [ARTIFACT.KISEL_ULITKA]: "Улитка",
            [ARTIFACT.PUH_EJ]: "Морской ёж",
            [ARTIFACT.PUH_KOLOBOK]: "Колобок",
            [ARTIFACT.PUH_KOLUCHKA]: "Колючка",
            [ARTIFACT.PUH_KKOLUCHKA]: "Кристальная колючка",
            [ARTIFACT.PUH_STEELKOLOBOK]: "Стальной колобок",
            [ARTIFACT.PUZIR_ATOM]: "Атом",
            [ARTIFACT.PUZIR_OSKOLOK]: "Осколок",
            [ARTIFACT.PUZIR_PRISMA]: "Призма",
            [ARTIFACT.PUZIR_SPIRAL]: "Спираль",
            [ARTIFACT.PUZIR_ZERKALO]: "Зеркало",
            // [ARTIFACT.SPECIAL_RUBIK]: "Рубик",
            [ARTIFACT.TRAMPOLINE_BRASLET]: "Браслет",
            [ARTIFACT.TRAMPOLINE_DARKMEDUZA]: "Тёмная медуза",
            [ARTIFACT.TRAMPOLINE_JILNIK]: "Жильник",
            [ARTIFACT.TRAMPOLINE_KAMENCVET]: "Каменный цветок",
            [ARTIFACT.TRAMPOLINE_MEDUZA]: "Медуза",
            [ARTIFACT.TRAMPOLINE_NIGHTSTAR]: "Ночная звезда",
            [ARTIFACT.TRAMPOLINE_PROTOMEDUZA]: "Протомедуза",
            [ARTIFACT.VORONKA_CRYSTAL]: "Черный кристалл",
            [ARTIFACT.VORONKA_GOLDFISH]: "Золотая рыбка",
            [ARTIFACT.VORONKA_GOLDGRAVI]: "Золотистый грави",
            [ARTIFACT.VORONKA_GRAVI]: "Грави",
            [ARTIFACT.VORONKA_KRIOGEN]: "Криоген",
            [ARTIFACT.VORONKA_VIVERT]: "Выверт",
            [ARTIFACT.VORONKA_YANTAR]: "Янтарник",
        },
        containers: {
            [CONTAINER.TRANSFORMER_BAG]: 'Сумка-трансформер',
            [CONTAINER.SPORTS_BAG]: 'Спортивная сумка',
            [CONTAINER.ERRAND_JUNIOR_BACKPACK]: 'Errand Junior',
            [CONTAINER.MBSS_BACKPACK]: 'Рюкзак MBSS',
            [CONTAINER.HELLBOY_BACKPACK]: 'Рюкзак Hellboy',
            [CONTAINER.REINFORCED_HELLBOY_BACKPACK]: 'Рюкзак Hellboy укрепленный',
            [CONTAINER.TRI_ZIP_ASSAULT_BACKPACK]: 'Рюкзак Tri-Zip',
            [CONTAINER.KZS_1_CONTAINER]: 'КЗС-1',
            [CONTAINER.KZS_1U_CONTAINER]: 'КЗС-1у',
            [CONTAINER.KZS_2]: 'КЗС-2',
            [CONTAINER.KZS_2U]: 'КЗС-2у',
            [CONTAINER.KZS_3]: 'КЗС-3',
            [CONTAINER.KZS_3U]: 'КЗС-3у',
            [CONTAINER.KZS_4]: 'КЗС-4',
            [CONTAINER.KZS_4U]: 'КЗС-4у',
            [CONTAINER.KZS_5]: 'КЗС-5',
            [CONTAINER.KZS_5Y]: 'КЗС-5у',
            [CONTAINER.BEARS_DEN_4_CONTAINER]: 'Берлога-4',
            [CONTAINER.BERLOGA_4U_CONTAINER]: 'Берлога-4у',
            [CONTAINER.BEARS_DEN_6_CONTAINER]: 'Берлога-6',
            [CONTAINER.BERLOGA_6U_CONTAINER]: 'Берлога-6у',
            [CONTAINER.COCOON_CONTAINER]: 'Кокон',
            [CONTAINER.FORAGER_CONTAINER]: 'Добытчик',
            [CONTAINER.HIVE_CONTAINER]: 'Улей',
            [CONTAINER.FRIDGE_CONTAINER]: 'Холодильник',
            [CONTAINER.FREEZER_CONTAINER]: 'Колотун',
            [CONTAINER.PACK_BAG]: 'Сумка Вьюк',
            [CONTAINER.SMC_CONTAINER]: 'КСМ',
            [CONTAINER.GAME_BAG]: 'Ягдташ',
            [CONTAINER.NPA_BACKPACK]: 'NPA',
        },
        armors: {
            [COMBAT_ARMOR.COAT_WITH_BODY_ARMOR]: 'Куртка с бронежилетом',
            [COMBAT_ARMOR.REPORTERS_ARMOR]: 'Броня репортера',
            [COMBAT_ARMOR.WORN_FLEECE_PROTECTIVE_SUIT]: 'Поношенный защитный костюм «Ворса»',
            [COMBAT_ARMOR.WORN_HOPLITE_ARMORED_SUIT]: 'Поношенный бронекостюм «Гоплит»',
            [COMBAT_ARMOR.HUNTERS_CLOAK]: 'Охотничий плащ',
            [COMBAT_ARMOR.FLEECE_PROTECTIVE_SUIT]: 'Защитный костюм «Ворса»',
            [COMBAT_ARMOR.HOPLITE_ARMORED_SUIT]: 'Бронекостюм «Гоплит»',
            [COMBAT_ARMOR.TRAPPER_SUIT]: 'Костюм «Траппер»',
            [COMBAT_ARMOR.WORN_TRAPPER_SUIT]: 'Поношенный костюм «Траппер»',
            [COMBAT_ARMOR.WORN_STRIKER_ARMORED_SUIT]: 'Поношенный бронекостюм «Страйкер»',
            [COMBAT_ARMOR.CLOAK]: 'Плащ',
            [COMBAT_ARMOR.BANDIT_CLOAK]: 'Бандитский плащ',
            [COMBAT_ARMOR.BERILL_5_M_ARMORED_SUIT]: 'Бронекостюм «Берилл-5М»',
            [COMBAT_ARMOR.WORM_ARMORED_SUIT]: 'Бронекостюм «Червь»',
            [COMBAT_ARMOR.HEALING_BERILL]: 'Лечебный Берилл',
            [COMBAT_ARMOR.CURRANT_GHILLIE_SUIT]: 'Маскировочный костюм «Смородина»',
            [COMBAT_ARMOR.HOUNDMASTER_PROTECTIVE_SUIT]: 'Защитный костюм «Псарь»',
            [COMBAT_ARMOR.SCOUT_SUIT]: 'Костюм «Скаут»',
            [COMBAT_ARMOR.STRIKER_ARMORED_SUIT]: 'Бронекостюм «Страйкер»',
            [COMBAT_ARMOR.OSH_PROTECTIVE_SUIT]: 'Защитный костюм «Ош»',
            [COMBAT_ARMOR.JAEGER_EXOARMOR]: 'Экзоброня «Егерь»',
            [COMBAT_ARMOR.LEGIONNAIRE_HEAVY_ARMORED_SUIT]: 'Тяжелый бронекостюм «Легионер»',
            [COMBAT_ARMOR.CENTURION_ARMORED_EXOSKELETON]: 'Бронескелет «Центурион»',
            [COMBAT_ARMOR.WORN_OSH_PROTECTIVE_SUIT]: 'Поношенный защитный костюм «Ош»',
            [COMBAT_ARMOR.WORN_LEGIONNAIRE]: 'Поношенный «Легионер»',
            [COMBAT_ARMOR.DAMAGED_JAEGER]: 'Поврежденный «Егерь»',
            [COMBAT_ARMOR.WORN_JAEGER]: 'Поношенный «Егерь»',
            [COMBAT_ARMOR.DAMAGED_CENTURION]: 'Поврежденный «Центурион»',
            [COMBAT_ARMOR.WORN_CENTURION]: 'Поношенный «Центурион»',
            [COMBAT_ARMOR.YORSH_PROTECTIVE_ARMORED_SUIT]: 'Защитный бронекостюм «Йорш»',
            [COMBAT_ARMOR.LYNX_ARMORED_SUIT]: 'Бронекостюм «Рысь»',
            [COMBAT_ARMOR.PHANTOM_HUNTER_ARMORED_SUIT]: 'Бронекостюм «Призрачный охотник»',
            [COMBAT_ARMOR.RATCATCHER_EXOARMOR]: 'Экзоброня «Крысолов»',
            [COMBAT_ARMOR.GHILLIE_SUIT]: 'Маскировочный костюм',
            [COMBAT_ARMOR.CD_1_SUIT]: 'Комбинезон «К3-1»',
            [COMBAT_ARMOR.CD_2_ARMORED_SUIT]: 'Бронекостюм «К3-2»',
            [COMBAT_ARMOR.CD_3_A_ARMORED_SUIT]: 'Бронекостюм «К3-3а»',
            [COMBAT_ARMOR.ALTYN_SET]: 'Комплект «Алтын»',
            [COMBAT_ARMOR.CD_3_B_ARMORED_SUIT]: 'Бронекостюм «К3-3б»',
            [COMBAT_ARMOR.SHADE_HUNTER_HEAVY_ARMORED_SUIT]: 'Тяжелый бронекостюм «Туманный охотник»',
            [COMBAT_ARMOR.ALTYN_T_HEAVY_SET]: 'Тяжелый комплект «Алтын-Т»',
            [COMBAT_ARMOR.BLIZZARD_BOUND_HEAVY_ARMORED_SUIT]: 'Тяжелый бронекостюм «Идущий в метели»',
            [COMBAT_ARMOR.MODIFIED_EXOSKELETON]: 'Модифицированный экзоскелет',
            [COMBAT_ARMOR.SBA_TANK]: 'SBA TANK',
            [COMBAT_ARMOR.CD_4_ARMORED_SUIT]: 'Бронекостюм «К3-4»',
            [COMBAT_ARMOR.BEAST_SLAYER_EXOARMOR]: 'Экзоброня «Зверобой»',
            [COMBAT_ARMOR.WORN_BEAST_SLAYER]: 'Поношенный «Зверобой»',
            [COMBAT_ARMOR.APOSTLE_HEAVY_ARMORED_SUIT]: 'Сверхтяжелый бронекостюм «Апостол»',
            [COMBAT_ARMOR.VANGUARD_ARMORED_SUIT]: 'Бронекостюм «Авангард»',
            [COMBAT_ARMOR.REITER_SUPER_HEAVY_ARMORED_SUIT]: 'Сверхтяжелый бронекостюм «Рейтар»',
            [COMBAT_ARMOR.GRANITE_SUPER_HEAVY_ARMORED_SUIT]: 'Сверхтяжелый бронекостюм «Гранит»',
            [COMBAT_ARMOR.CHIEFTAIN_SUPER_HEAVY_ARMORED_SUIT]: 'Сверхтяжелый бронекостюм «Вождь»',

            [SCIENTIST_ARMOR.RAINCOAT]: 'Дождевик',
            [SCIENTIST_ARMOR.IP_4_M]: 'ИП-4м',
            [SCIENTIST_ARMOR.ZARYA_B_SUIT]: 'Комбинезон «Заря-Б»',
            [SCIENTIST_ARMOR.BANDIT_SUIT_WITH_GAS_TANKS]: 'Бандитский костюм с баллонами',
            [SCIENTIST_ARMOR.SEVA_SUIT]: 'Комбинезон «СЕВА»',
            [SCIENTIST_ARMOR.REAPER_SUIT]: 'Комбинезон «ЖНЕЦ»',
            [SCIENTIST_ARMOR.MIS_99_AMBER]: 'КИМ-99 «Янтарь»',
            [SCIENTIST_ARMOR.MIS_99_MALACHITE]: 'КИМ-99М «Малахит»',
            [SCIENTIST_ARMOR.MIS_105_TOPAZ]: 'КИМ-105 «Топаз»',
            [SCIENTIST_ARMOR.MIS_113_IOLITE]: 'КИМ-113 «Иолит»',
            [SCIENTIST_ARMOR.MIS_116_EMERALD]: 'КИМ-116 «Изумруд»',
            [SCIENTIST_ARMOR.MIS_122_AMETHYST]: 'КИМ-122 «Аметист»',
            [SCIENTIST_ARMOR.MIS_X_ATLAS]: 'КИМ-Х «АТЛАС»',
            [SCIENTIST_ARMOR.WORN_MIS_99_AMBER]: 'Поношенный КИМ-99 «Янтарь»',
            [SCIENTIST_ARMOR.WORN_MIS_99_M_MALACHITE]: 'Поношенный КИМ-99М «Малахит»',
            [SCIENTIST_ARMOR.WORN_SEVA_SUIT]: 'Поношенный комбинезон «СЕВА»',
            [SCIENTIST_ARMOR.WORN_REAPER_SUIT]: 'Поношенный комбинезон «ЖНЕЦ»',
            [SCIENTIST_ARMOR.WORN_TOPAZ]: 'Поношенный «Топаз»',
            [SCIENTIST_ARMOR.WORN_IOLITE]: 'Поношенный «Иолит»',
            [SCIENTIST_ARMOR.WORN_EMERALD]: 'Поношенный «Изумруд»',
            [SCIENTIST_ARMOR.WORN_AMETHYST]: 'Поношенный «Аметист»',
            [SCIENTIST_ARMOR.MIS_85_D]: 'КИМ-85Д',
            [SCIENTIST_ARMOR.IMPROVED_HAZMAT_SUIT]: 'Продвинутый ОЗК',
            [SCIENTIST_ARMOR.MIS_100_GARNET]: 'КИМ-100 «Гранат»',
            [SCIENTIST_ARMOR.MIS_102_ZIRCON]: 'КИМ-102 «Циркон»',
            [SCIENTIST_ARMOR.SATURN_SUIT]: 'Комбинезон «Сатурн»',
            [SCIENTIST_ARMOR.WORN_SATURN_SUIT]: 'Поношенный комбинезон «Сатурн»',
            [SCIENTIST_ARMOR.DAMAGED_SATURN_SUIT]: 'Поврежденный комбинезон «Сатурн»',

            [DISCOVERER_ARMOR.GORKA_3_SUIT]: 'Костюм «Горка-3»',
            [DISCOVERER_ARMOR.WORN_HAWK_ARMORED_SUIT]: 'Поношенный бронекостюм «Сокол»',
            [DISCOVERER_ARMOR.WORN_MOCKINGBIRD_ARMORED_SUIT]: 'Поношенный бронекостюм «Пересмешник»',
            [DISCOVERER_ARMOR.ZARYA_SUIT]: 'Комбинезон «Заря»',
            [DISCOVERER_ARMOR.ZARYA_SUIT_WITH_GAS_MASK]: 'Комбинезон «Заря» с противогазом',
            [DISCOVERER_ARMOR.TOURIST_SUIT]: 'Комбинезон «Турист»',
            [DISCOVERER_ARMOR.BANDIT_SUIT]: 'Бандитский костюм',
            [DISCOVERER_ARMOR.BANDIT_SUIT_WITH_GAS_MASK]: 'Бандитский костюм с противогазом',
            [DISCOVERER_ARMOR.KLEPTOMANIAC_SUIT]: 'Костюм «Клептоман»',
            [DISCOVERER_ARMOR.VOSHOD_HEAVY_ARMORED_SUIT]: 'Tяжелый бронекостюм «Восход»',
            [DISCOVERER_ARMOR.THUG_HEAVY_ARMORED_SUIT]: 'Tяжелый бронекостюм «Громила»',
            [DISCOVERER_ARMOR.HAWK_ARMORED_SUIT]: 'Бронекостюм «Сокол»',
            [DISCOVERER_ARMOR.MOCKINGBIRD_ARMORED_SUIT]: 'Бронекостюм «Пересмешник»',
            [DISCOVERER_ARMOR.SKAT_9_B_ARMORED_SUIT]: 'Бронекостюм «СКАТ-9б»',
            [DISCOVERER_ARMOR.EXOSKELETON]: 'Экзоскелет',
            [DISCOVERER_ARMOR.TRUMP_EXOSKELETON]: 'Экзоскелет «Масть»',
            [DISCOVERER_ARMOR.PSZ_10_ARMORED_SUIT]: 'Бронекостюм ПСЗ-10',
            [DISCOVERER_ARMOR.KINGPIN_ARMORED_SUIT]: 'Бронекостюм «Пахан»',
            [DISCOVERER_ARMOR.SKAT_9_M_ARMORED_SUIT]: 'Бронекостюм «СКАТ-9м»',
            [DISCOVERER_ARMOR.WORN_VOSHOD_HEAVY_ARMORED_SUIT]: 'Поношенный тяжелый бронекостюм «Восход»',
            [DISCOVERER_ARMOR.WORN_THUG_HEAVY_ARMORED_SUIT]: 'Поношенный тяжелый бронекостюм «Громила»',
            [DISCOVERER_ARMOR.WORN_PSZ_10]: 'Поношенный ПСЗ-10',
            [DISCOVERER_ARMOR.WORN_KINGPIN]: 'Поношенный «Пахан»',
            [DISCOVERER_ARMOR.WORN_EXOSKELETON]: 'Поношенный экзоскелет',
            [DISCOVERER_ARMOR.WORN_TRUMP]: 'Поношенная «Масть»',
            [DISCOVERER_ARMOR.WORN_SKAT_9_M]: 'Поношенный «СКАТ-9м»',
            [DISCOVERER_ARMOR.DAMAGED_SKAT_9_M]: 'Поврежденный «СКАТ-9м»',
            [DISCOVERER_ARMOR.SKAT_10_ARMORED_SUIT]: 'Бронекостюм «СКАТ-10»',
            [DISCOVERER_ARMOR.WORN_TOURIST]: 'Поношенный «Турист»',
            [DISCOVERER_ARMOR.WORN_KLEPTOMANIAC]: 'Поношенный «Клептоман»',
            [DISCOVERER_ARMOR.BRIGAND_ARMORED_SUIT]: 'Бронекостюм «Армай»',
            [DISCOVERER_ARMOR.GRENADIER_EXOSKELETON]: 'Экзоскелет «Гренадер»',
            [DISCOVERER_ARMOR.MULE_EXOSKELETON]: 'Экзоскелет «Мул»',
            [DISCOVERER_ARMOR.ACE_EXOSKELETON]: 'Экзоскелет «Туз»',
            [DISCOVERER_ARMOR.WORN_MULE]: 'Поношенный «Мул»',
            [DISCOVERER_ARMOR.WORN_ACE]: 'Поношенный «Туз»',
            [DISCOVERER_ARMOR.PUNISHER_ARMORED_SUIT]: 'Бронекостюм «Каратель»',

            [COMBINED_ARMOR.WORN_AH_2_NOMAD]: 'Поношенный АО-2 «Странник»',
            [COMBINED_ARMOR.DAMAGED_AH_2_NOMAD]: 'Поврежденный АО-2 «Странник»',
            [COMBINED_ARMOR.AH_1_DRIFTER_SUIT]: 'Костюм АО-1 «Бродяга»',
            [COMBINED_ARMOR.PS_1_PICKLOCK]: 'ЗК-1 «Отмычка»',
            [COMBINED_ARMOR.AH_2_NOMAD_SUIT]: 'Костюм АО-2 «Странник»',
            [COMBINED_ARMOR.AH_3_SEEKER_SUIT]: 'Костюм АО-3 «Искатель»',
            [COMBINED_ARMOR.RAPS_AH_4_RAIDER]: 'УКАЗ АО-4 «Рейдер»',
            [COMBINED_ARMOR.RAPS_AH_5_PILGRIM]: 'УКАЗ АО-5 «Пилигрим»',
            [COMBINED_ARMOR.DAMAGED_AH_3_SEEKER_SUIT]: 'Поврежденный костюм АО-3 «Искатель»',
            [COMBINED_ARMOR.WORN_AH_3_SEEKER_SUIT]: 'Поношенный костюм АО-3 «Искатель»',
            [COMBINED_ARMOR.WORN_AH_4_RAIDER]: 'Поношенный АО-4 «Рейдер»',
            [COMBINED_ARMOR.WORN_AH_5_PILGRIM]: 'Поношенный АО-5 «Пилигрим»',
            [COMBINED_ARMOR.RAPS_SPANNER]: 'УКАЗ «Спаннер»',
            [COMBINED_ARMOR.EXPEDITION_SUIT]: 'Костюм «Экспедиция»',
            [COMBINED_ARMOR.MAGNET_SUIT]: 'Комбинезон «Магнит»',
            [COMBINED_ARMOR.TONGA_SUIT]: 'Комбинезон «Тонга»',
            [COMBINED_ARMOR.ALBATROSS_SCOUT_ARMORED_EXOSKELETON]: 'Бронескелет «Альбатрос-Разведчик»',
            [COMBINED_ARMOR.JD_ZIVCAS_2_A_EXOARMOR]: 'Экзоброня JD ZIVCAS 2A',
            [COMBINED_ARMOR.ALBATROSS_INFILTRATOR_ARMORED_EXOSKELETON]: 'Бронескелет «Альбатрос-Лазутчик»',
            [COMBINED_ARMOR.ALBATROSS_HEAVY_ASSAULT_ARMORED_EXOSKELETON]: 'Бронескелет «Альбатрос-Штурмовик»',
            [COMBINED_ARMOR.RIGEL_SUIT]: 'Комбинезон «Ригель»',
            [COMBINED_ARMOR.RAPS_AH_6_NOMAD]: 'УКАЗ АО-6 «Кочевник»',
            [COMBINED_ARMOR.WORN_AH_6_NOMAD]: 'Поношенный АО-6 «Кочевник»',
            [COMBINED_ARMOR.HOUNDS_EXOSKELETON]: 'Экзокостюм «Гончий»',
        },
        rarities: {
            [Rarity.COMMON]: 'Обычный',
            [Rarity.UNCOMMON]: 'Необычный',
            [Rarity.RARE]: 'Особый',
            [Rarity.EPIC]: 'Редкий',
            [Rarity.EXCEPTIONAL]: 'Исключительный',
            [Rarity.LEGENDARY]: 'Легендарный',
            [Rarity.UNIQUE]: 'Уникальный',
        },
        artifact: {
            title: 'Артефакты',
            copy: 'Скопировать в',
            delete: 'Удалить',
            quality: '%',
            level: 'Уровень',
            search: {
                statPlaceholder: 'Искать по характеристике...',
                positive: 'Позитивный',
                negative: 'Негативный',
                noResults: 'Артефактов не найдено',
                noArtifactSelected: 'Выбрать...',
                index: '#{{index}}',
                selectedArtifact: '{{name}} | +{{level}} {{quality}}%',
            }
        },
        container: {
            title: 'Контейнер',
            search: {
                placeholder: 'Искать по имени...',
                selected: '{{container}}, {{slots}} слотов, {{protection}}%',
            }
        },
        armor: {
            title: 'Броня',
            search: {

                placeholder: 'Искать по имени...',
            }
        },
        filterDropdown: {
            inputPlaceholder: 'Начните вводить...',
        },
        calculator: {
            stat: {
                flat: '{{value}}',
                percent: '{{value}}%',
                polyhedron: 'Поглощает {{polyhedronBlockingDamage}} урона каждые {{polyhedronCooldown}} секунд',
                includeHuman: 'Учитывать врожденные',
            }
        },
        loadout: {
            save: 'Сохранить',
            load: 'Загрузить',
            delete: 'Удалить',
            new: 'Новый',
            loadoutName: 'Имя пресета',
        },
        stat: {
            title: 'Все характеристики'
        }
    }
};

export default ru;