import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const jarkaPlamya = new Artifact(ARTIFACT.JARKA_PLAMYA, {
    [STAT.regenerationBonus]: [2, 4],
    [STAT.thermalAccumulation]: [-2, -5],
    [STAT.bleedingAccumulation]: [2, 4],
}, {
    [STAT.healEfficiency]: [5, 10],
    [STAT.regenerationBonus]: [1, 2],
    [STAT.thermalAccumulation]: [0.5, 1.25],
});

export default jarkaPlamya;