import {
    AdditionalStats,
    ArtifactStats, PolyhedronStats, SPECIAL_STAT,
    SpecialArtifactStats,
    SpecialStats,
    SpectialStatsKeys,
    Stats,
    StatsKey
} from "./stats";
import {ARTIFACT} from "../data/artifact/artifact";
import {ARTIFACT_NEGATIVE_STAT_PERCENTAGE, ArtifactQuality, Level, Rarity} from "../constants/base";
import {
    floatFix,
    getArtifactStatValue,
    getValueInRangeByCoefficient
} from "../utils/calculation";
import AppError from "../utils/app-error";

export type ArtifactData = {
    id: ARTIFACT,
    quality: ArtifactQuality,
    level: Level,
    rarity: Rarity,
    additionalStats: AdditionalStats,
}

class Artifact {
    constructor(
        public id: ARTIFACT,
        public readonly stats: ArtifactStats,
        public readonly additionalStats: ArtifactStats,
        public readonly specialStats: SpecialArtifactStats = {}

    ) {}

    public static getRarityByQuality = (quality: ArtifactQuality): [Rarity] | [Rarity, Rarity] => {
        if (quality < 0 || quality > 175)
            throw new AppError(
                `Quality ${quality} is out of range [0, 175]`,
                'ERROR.QUALITY_OUT_OF_RANGE'
            );
        if (quality < 100) return [Rarity.COMMON];
        if (quality === 100) return [Rarity.COMMON, Rarity.UNCOMMON];
        if (quality < 110) return [Rarity.UNCOMMON];
        if (quality === 110) return [Rarity.UNCOMMON, Rarity.RARE];
        if (quality < 120) return [Rarity.RARE];
        if (quality === 120) return [Rarity.RARE, Rarity.EPIC];
        if (quality < 130) return [Rarity.EPIC];
        if (quality === 130) return [Rarity.EPIC, Rarity.EXCEPTIONAL];
        if (quality < 140) return [Rarity.EXCEPTIONAL];
        if (quality === 140) return [Rarity.EXCEPTIONAL, Rarity.LEGENDARY];
        if (quality < 150) return [Rarity.LEGENDARY];
        if (quality === 150) return [Rarity.LEGENDARY, Rarity.UNIQUE];
        return [Rarity.UNIQUE];
    }

    public static getQualityRangeByRarity = (rarity: Rarity) => {
        switch (rarity) {
            case Rarity.COMMON:
                return [0, 100];
            case Rarity.UNCOMMON:
                return [100, 110];
            case Rarity.RARE:
                return [110, 120];
            case Rarity.EPIC:
                return [120, 130];
            case Rarity.EXCEPTIONAL:
                return [130, 140];
            case Rarity.LEGENDARY:
                return [140, 150];
            case Rarity.UNIQUE:
                return [150, 175];
        }
    }

    public static getAllowedAdditionalStatsCount = (level: Level) => {
        return Math.floor(level / 5);
    }

    public getStats(quality: ArtifactQuality, level: Level, rarity: Rarity, additionalStats: AdditionalStats): Stats {
        const [qualityMin, qualityMax] = Artifact.getQualityRangeByRarity(rarity);
        if (quality !== floatFix(quality, 2))
            throw new AppError(
                `Only 2 digits after decimal point are allowed for quality, got ${quality}`,
                'ERROR.WRONG_QUALITY'
            );

        if (quality < qualityMin || quality > qualityMax)
            throw new AppError(
                `Quality ${quality} is out of range [${qualityMin}, ${qualityMax}] of ${rarity} rarity`,
                'ERROR.QUALITY_OUT_OF_RANGE'
            );

        if (Artifact.getAllowedAdditionalStatsCount(level) < additionalStats?.length)
            throw new AppError(
                `Level ${level} is too low to get ${additionalStats?.length} additional stats`,
                'ERROR.TOO_MANY_ADDITIONAL_STATS'
            );

        for (const stat of additionalStats) {
            if (!this.additionalStats[stat])
                throw new AppError(
                    `Additional stat ${stat} is not available for ${this.id}`,
                    'ERROR.WRONG_ADDITIONAL_STAT'
                );
        }

        const coefficient = (quality - qualityMin) / (qualityMax - qualityMin);
        const result: Stats = {};

        for (const key of Object.keys(this.stats) as StatsKey[]) {
            const value = this.stats[key];
            if (value === undefined) continue;
            const [min, max] = value;

            if (Math.min(min, max) >= 0) {
                result[key] = getArtifactStatValue(min, max, quality / 100, level);
            } else {
                if (rarity === Rarity.COMMON) {
                    result[key] = getValueInRangeByCoefficient(min, max, coefficient);
                } else {
                    const newMin = max * ARTIFACT_NEGATIVE_STAT_PERCENTAGE;
                    result[key] = floatFix(newMin + (max - newMin) * coefficient, 10);
                }
            }
        }

        for (const stat of additionalStats) {
            const [min, max] = this.additionalStats[stat] as [number, number];
            const additionalStatValue = getArtifactStatValue(min, max, quality / 100, level);
            result[stat] = floatFix((result[stat] ?? 0) + additionalStatValue, 10);
        }

        return result;
    }

    public getSpecialStats(quality: ArtifactQuality, level: Level): SpecialStats {
        const result: SpecialStats = {};
        for (const key of Object.keys(this.specialStats) as SpectialStatsKeys[]) {
            switch (key) {
                case 'polyhedron':
                    // if (!this.specialStats.polyhedron) continue;
                    const [minDamage, maxDamage] =
                        (this.specialStats.polyhedron as Record<PolyhedronStats, [number, number]>)[SPECIAL_STAT.polyhedronBlockingDamage];
                    const [minCooldown, maxCooldown] =
                        (this.specialStats.polyhedron as Record<PolyhedronStats, [number, number]>)[SPECIAL_STAT.polyhedronCooldown];
                    result.polyhedron = [{
                        [SPECIAL_STAT.polyhedronBlockingDamage]: getArtifactStatValue(minDamage, maxDamage, quality / 100, level),
                        [SPECIAL_STAT.polyhedronCooldown]: getArtifactStatValue(minCooldown, maxCooldown, quality / 100, level),
                    }];
            }
        }
        return result;
    }
}

export default Artifact;