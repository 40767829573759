import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const damagedSaturnSuit = new Armor(ARMOR.DAMAGED_SATURN_SUIT,
    {
        [STAT.maxWeightBonus]: 6,
        [STAT.bulletDmgFactor]: 70,
        [STAT.tearDmgFactor]: 79,
        [STAT.explosionDmgFactor]: 95,
        [STAT.electraDmgFactor]: 859,
        [STAT.burnDmgFactor]: 859,
        [STAT.chemicalBurnDmgFactor]: 859,
        [STAT.radiationProtection]: 355,
        [STAT.thermalProtection]: 355,
        [STAT.biologicalProtection]: 400,
        [STAT.psychoProtection]: 355,
        [STAT.bleedingProtection]: 43,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 683020,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1010,
            [RESOURCE.RED_FERN]: 590,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 360,
            [RESOURCE.SUBSTANCE_07270]: 880,
        },
        dependency: ARMOR.REAPER_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 683020,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1010,
            [RESOURCE.RED_FERN]: 590,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 360,
            [RESOURCE.SUBSTANCE_07270]: 880,
        },
        dependency: ARMOR.SEVA_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 634830,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 940,
            [RESOURCE.RED_FERN]: 540,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 330,
            [RESOURCE.SUBSTANCE_07270]: 810,
        },
        dependency: ARMOR.MIS_122_AMETHYST,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default damagedSaturnSuit;