import React from "react";
import {useTranslation} from "react-i18next";
import {ListGroup, Stack} from "react-bootstrap";
import {StatsKey} from "../../../../models/stats";
import Artifact from "../../../../models/artifact";
import {ARTIFACT} from "../../../../data/artifact/artifact";

export type ArtifactItemProps = {
    artifact: Artifact;
    setNewArtifact: (id: ARTIFACT) => void;
}

export const ARTIFACT_ITEM_TEST_ID = 'artifact-item';
export const ARTIFACT_ITEM_HEADER_TEST_ID = 'artifact-item-header';
export const ARTIFACT_ITEM_STATS_TEST_ID = 'artifact-item-stats';

export const ArtifactItem: React.FC<ArtifactItemProps> = ({artifact, setNewArtifact}) => {
    const {t} = useTranslation();

    const getStatRange = (stat: StatsKey) => {
        return (artifact.stats[stat] as [number, number])
    };

    return (
        <ListGroup.Item action onClick={() => setNewArtifact(artifact.id)} data-testid={ARTIFACT_ITEM_TEST_ID}>
            <h5 data-testid={ARTIFACT_ITEM_HEADER_TEST_ID} className='mb-2'>{t(`artifacts.${artifact.id}`)}</h5>
            <Stack direction='vertical' gap={0} data-testid={ARTIFACT_ITEM_STATS_TEST_ID}>
                {(Object.keys(artifact.stats) as StatsKey[]).map((stat) => (
                    <div
                        key={stat}
                        className={getStatRange(stat)[0] >= 0 ? 'text-success' : 'text-danger'}
                    >
                        {t(`stats.${stat}`)} ({getStatRange(stat)[0]} | {getStatRange(stat)[1]})
                    </div>
                ))}
            </Stack>
        </ListGroup.Item>
    )
};