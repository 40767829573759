import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const karouselBloodstone = new Artifact(ARTIFACT.KAROUSEL_BLOODSTONE, {
    [STAT.healEfficiency]: [-5, -10],
    [STAT.bleedingDmgFactor]: [5, 10],
    [STAT.bleedingAccumulation]: [1, 2],
}, {
    [STAT.healEfficiency]: [2, 4],
    [STAT.bleedingDmgFactor]: [2.5, 5],
    [STAT.tearDmgFactor]: [5, 10],
});

export default karouselBloodstone;