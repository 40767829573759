import Artifact, {ArtifactData} from "../models/artifact";
import AllArtifacts from "../data/artifact";
import {TFunction} from "../i18n";
import {ARTIFACT} from "../data/artifact/artifact";
import {StatsKey} from "../models/stats";
import Container, {ContainerData} from "../models/container";
import AllContainers, {
    AllBagsCompactContainersList,
    AllBagsSpaciousContainersList,
    AllBagsStandardContainersList, MediumBagsAllContainersList,
    MediumBagsCompactContainersList,
    MediumBagsSpaciousContainersList,
    MediumBagsStandardContainersList,
    LightBagsAllContainersList,
    LightBagsCompactContainersList,
    LightBagsSpaciousContainersList,
    LightBagsStandardContainersList
} from "../data/container";
import {CONTAINER, CONTAINER_TYPE} from "../data/container/container";
import AllArmor from "../data/armor";
import Armor, {ArmorData} from "../models/armor";
import {ARMOR, ARMOR_CONTAINER_TYPE} from "../data/armor/armor";

/**
 * List of all artifacts (except NONE)
 */
export const artifactList: Artifact[] = Object.values(AllArtifacts).filter(artifact => artifact.id !== ARTIFACT.NONE);
export const getArtifactTKey = (artifact: Artifact | ArtifactData) => `artifacts.${artifact.id}`;

export const getSortedList = <T extends Artifact | Armor | Container>(list: T[], getTKey: (item: T) => string, t: TFunction): T[] =>
    list.sort((a, b) => {
        return t(getTKey(a)) > t(getTKey(b)) ? 1 : -1;
    });


export const getFilteredArtifactList = (list: Artifact[], filter: string, t: TFunction) => {
    const filterLower = filter.toLowerCase();
    return list.filter(artifact => {
        return t(getArtifactTKey(artifact)).toLowerCase().includes(filterLower);
    });
};

type ArtifactByStatMap = {
    positive: Partial<Record<StatsKey, Artifact[]>>,
    negative: Partial<Record<StatsKey, Artifact[]>>,
};

export const artifactByStatMap: ArtifactByStatMap = artifactList.reduce<ArtifactByStatMap>((acc, artifact) => {
    Object.entries(artifact.stats).forEach(([stat, value]) => {
        const statKey = stat as StatsKey;
        const isPositive = Math.min(value[0], value[1]) >= 0;
        const mapKey = isPositive ? 'positive' : 'negative';
        const map = acc[mapKey];

        if (!map[statKey]) map[statKey] = [];
        (map[statKey] as Artifact[]).push(artifact);

    });
    return acc;
}, { positive: {}, negative: {} } as ArtifactByStatMap);


/**
 * List of all containers (except NONE)
 */
export const containerList: Container[] = Object.values(AllContainers).filter(container => container.id !== CONTAINER.NONE);
export const getContainerTKey = (container: Container | ContainerData) => `containers.${container.id}`;

export const ContainerListByArmorContainerType: Record<ARMOR_CONTAINER_TYPE, Container[]> = {
    [ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER]: containerList,
    [ARMOR_CONTAINER_TYPE.LIGHT_BAG_COMPACT_CONTAINER]: LightBagsCompactContainersList,
    [ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER]: LightBagsStandardContainersList,
    [ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER]: LightBagsSpaciousContainersList,
    [ARMOR_CONTAINER_TYPE.LIGHT_BAG_ALL_CONTAINER]: LightBagsAllContainersList,
    [ARMOR_CONTAINER_TYPE.MEDIUM_BAG_COMPACT_CONTAINER]: MediumBagsCompactContainersList,
    [ARMOR_CONTAINER_TYPE.MEDIUM_BAG_STANDARD_CONTAINER]: MediumBagsStandardContainersList,
    [ARMOR_CONTAINER_TYPE.MEDIUM_BAG_SPACIOUS_CONTAINER]: MediumBagsSpaciousContainersList,
    [ARMOR_CONTAINER_TYPE.MEDIUM_BAG_ALL_CONTAINER]: MediumBagsAllContainersList,
    [ARMOR_CONTAINER_TYPE.ALL_BAG_COMPACT_CONTAINER]: AllBagsCompactContainersList,
    [ARMOR_CONTAINER_TYPE.ALL_BAG_STANDARD_CONTAINER]: AllBagsStandardContainersList,
    [ARMOR_CONTAINER_TYPE.ALL_BAG_SPACIOUS_CONTAINER]: AllBagsSpaciousContainersList,
};


/**
 * List of all armor (except NONE)
 */
export const armorList: Armor[] = Object.values(AllArmor).filter(armor => armor.id !== ARMOR.NONE);

const filterByContainerTypeFabric = (types: ARMOR_CONTAINER_TYPE[], includes = true) => (armor: Armor) => types.includes(armor.containerType) === includes;
export const ArmorListByContainerType: Record<CONTAINER_TYPE, Armor[]> = {
    [CONTAINER_TYPE.LIGHT_BACKPACK]: armorList,
    [CONTAINER_TYPE.MEDIUM_BACKPACK]: armorList.filter(filterByContainerTypeFabric([ARMOR_CONTAINER_TYPE.LIGHT_BAG_COMPACT_CONTAINER, ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER, ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER, ARMOR_CONTAINER_TYPE.LIGHT_BAG_ALL_CONTAINER], false)),
    [CONTAINER_TYPE.HEAVY_BACKPACK]: armorList.filter(filterByContainerTypeFabric([ARMOR_CONTAINER_TYPE.ALL_BAG_COMPACT_CONTAINER, ARMOR_CONTAINER_TYPE.ALL_BAG_STANDARD_CONTAINER, ARMOR_CONTAINER_TYPE.ALL_BAG_SPACIOUS_CONTAINER, ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER])),
    [CONTAINER_TYPE.COMPACT_CONTAINER]: armorList,
    [CONTAINER_TYPE.STANDARD_CONTAINER]: armorList.filter(filterByContainerTypeFabric([ARMOR_CONTAINER_TYPE.LIGHT_BAG_COMPACT_CONTAINER, ARMOR_CONTAINER_TYPE.ALL_BAG_COMPACT_CONTAINER], false)),
    [CONTAINER_TYPE.SPACIOUS_CONTAINER]: armorList.filter(filterByContainerTypeFabric([ARMOR_CONTAINER_TYPE.LIGHT_BAG_COMPACT_CONTAINER, ARMOR_CONTAINER_TYPE.ALL_BAG_COMPACT_CONTAINER, ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER, ARMOR_CONTAINER_TYPE.ALL_BAG_STANDARD_CONTAINER], false)),
    [CONTAINER_TYPE.BULKY_CONTAINER]: armorList.filter(filterByContainerTypeFabric([ARMOR_CONTAINER_TYPE.LIGHT_BAG_ALL_CONTAINER, ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER])),
}

export const getArmorTKey = (armor: Armor | ArmorData) => `armors.${armor.id}`;

export const getStatTKey = (stat: StatsKey) => `stats.${stat}`;