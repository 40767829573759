import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornTrapperSuit = new Armor(ARMOR.WORN_TRAPPER_SUIT,
    {
        [STAT.maxWeightBonus]: 5,
        [STAT.bulletDmgFactor]: 39,
        [STAT.tearDmgFactor]: 112,
        [STAT.explosionDmgFactor]: 53,
        [STAT.electraDmgFactor]: 24,
        [STAT.burnDmgFactor]: 24,
        [STAT.chemicalBurnDmgFactor]: 24,
        [STAT.radiationProtection]: 105,
        [STAT.thermalProtection]: 20,
        [STAT.biologicalProtection]: 105,
        [STAT.psychoProtection]: 20,
        [STAT.bleedingProtection]: 59,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 4850,
            [RESOURCE.PIECES_OF_ALUMINUM_CABLE]: 13,
            [RESOURCE.STINKY_ROOT]: 43,
            [RESOURCE.SEEDS]: 11,
        },
        dependency: ARMOR.HOUNDMASTER_PROTECTIVE_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default wornTrapperSuit;