import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const altynTHeavySet = new Armor(ARMOR.ALTYN_T_HEAVY_SET,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 6,
        [STAT.bulletDmgFactor]: 131,
        [STAT.tearDmgFactor]: 126,
        [STAT.explosionDmgFactor]: 183,
        [STAT.electraDmgFactor]: 47,
        [STAT.burnDmgFactor]: 47,
        [STAT.chemicalBurnDmgFactor]: 47,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 30,
        [STAT.biologicalProtection]: 100,
        [STAT.psychoProtection]: 100,
        [STAT.bleedingProtection]: 57,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default altynTHeavySet;