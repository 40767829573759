import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {STAT} from "../../../models/stats";

const fridgeContainer = new Container(
    CONTAINER.FRIDGE_CONTAINER,
    {
        [STAT.speedModifier]: -1,
        [STAT.thermalAccumulation]: 1.5,
    },
    [
    ].filter(Boolean), 3, 0.85);

export default fridgeContainer;