import {ArmorData} from "./armor";
import {Level, LEVEL_VALIDATION} from "../constants/base";
import {validateNumberInput} from "../utils/validation";

class ArmorDataFactory {
    public static changeLevel(armor: ArmorData, level: Level): ArmorData {
        const newValue = validateNumberInput(level, LEVEL_VALIDATION) as Level

        if(armor.level === newValue) return armor;

        return {...armor, level: newValue};
    }
}

export default ArmorDataFactory;