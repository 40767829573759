import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const jaegerExoarmor = new Armor(ARMOR.JAEGER_EXOARMOR,
    {
        [STAT.staminaBonus]: -15,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 25,
        [STAT.bulletDmgFactor]: 97,
        [STAT.tearDmgFactor]: 291,
        [STAT.explosionDmgFactor]: 133,
        [STAT.electraDmgFactor]: 59,
        [STAT.burnDmgFactor]: 59,
        [STAT.chemicalBurnDmgFactor]: 59,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 40,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 180,
        [STAT.bleedingProtection]: 77,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 696240,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 530,
            [RESOURCE.RED_FERN]: 1250,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 350,
            [RESOURCE.SUBSTANCE_07270]: 840,
        },
        dependency: ARMOR.OSH_PROTECTIVE_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 391920,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 288,
            [RESOURCE.RED_FERN]: 680,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 189,
            [RESOURCE.SUBSTANCE_07270]: 460,
        },
        dependency: ARMOR.WORN_JAEGER,
    },
    {
        resources: {
            [RESOURCE.RUB]: 680550,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 510,
            [RESOURCE.RED_FERN]: 1200,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 340,
            [RESOURCE.SUBSTANCE_07270]: 800,
        },
        dependency: ARMOR.DAMAGED_JAEGER,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default jaegerExoarmor;