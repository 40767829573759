import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const albatrossScoutArmoredExoskeleton = new Armor(ARMOR.ALBATROSS_SCOUT_ARMORED_EXOSKELETON,
    {
        [STAT.speedModifier]: 15,
        [STAT.maxWeightBonus]: 20,
        [STAT.radiationAccumulation]: 1,
        [STAT.psychoAccumulation]: 1,
        [STAT.bleedingAccumulation]: 0.5,
        [STAT.bulletDmgFactor]: 73,
        [STAT.tearDmgFactor]: 121,
        [STAT.explosionDmgFactor]: 124,
        [STAT.electraDmgFactor]: 278,
        [STAT.burnDmgFactor]: 278,
        [STAT.chemicalBurnDmgFactor]: 278,
        [STAT.radiationProtection]: 240,
        [STAT.thermalProtection]: 100,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 240,
        [STAT.bleedingProtection]: 56,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default albatrossScoutArmoredExoskeleton;