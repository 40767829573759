import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const mis102Zircon = new Armor(ARMOR.MIS_102_ZIRCON,
    {
        [STAT.artefaktHeal]: 3,
        [STAT.radiationAccumulation]: 2,
        [STAT.bleedingAccumulation]: 0.5,
        [STAT.bulletDmgFactor]: 56,
        [STAT.tearDmgFactor]: 63,
        [STAT.explosionDmgFactor]: 76,
        [STAT.electraDmgFactor]: 644,
        [STAT.burnDmgFactor]: 644,
        [STAT.chemicalBurnDmgFactor]: 644,
        [STAT.radiationProtection]: 225,
        [STAT.thermalProtection]: 225,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 225,
        [STAT.bleedingProtection]: 38,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default mis102Zircon;