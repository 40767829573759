import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const blizzardHeel = new Artifact(ARTIFACT.BLIZZARD_HEEL, {
    [STAT.staminaBonus]: [-25, -50],
    [STAT.healEfficiency]: [10, 25],
}, {
    [STAT.healEfficiency]: [5, 12.5],
    [STAT.reactionToTear]: [1, 2],
    [STAT.staminaBonus]: [10, 20],
});

export default blizzardHeel;