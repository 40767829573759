import {SPACIOUS_CONTAINER} from "../container";
import Container from "../../../models/container";
import bearsDen6Container from "./bears-den-6-container";
import berloga6uContainer from "./berloga-6u-container";
import bearsDen4Container from "./bears-den-4-container";
import berloga4uContainer from "./berloga-4u-container";

const SpaciousContainers: Record<SPACIOUS_CONTAINER, Container> = {
    [SPACIOUS_CONTAINER.BEARS_DEN_4_CONTAINER]: bearsDen4Container,
    [SPACIOUS_CONTAINER.BERLOGA_4U_CONTAINER]: berloga4uContainer,
    [SPACIOUS_CONTAINER.BEARS_DEN_6_CONTAINER]: bearsDen6Container,
    [SPACIOUS_CONTAINER.BERLOGA_6U_CONTAINER]: berloga6uContainer,
}

export default SpaciousContainers;