import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const jarkaFireball = new Artifact(ARTIFACT.JARKA_FIREBALL, {
    [STAT.thermalDmgFactor]: [5, 15],
    [STAT.radiationAccumulation]: [-2, -5],
    [STAT.thermalAccumulation]: [1, 3],
}, {
    [STAT.thermalDmgFactor]: [2.5, 7.2],
    [STAT.explosionDmgFactor]: [5, 15],
    [STAT.radiationAccumulation]: [0.5, 1.25],
});

export default jarkaFireball;