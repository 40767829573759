import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const sportsBag = new Container(
    CONTAINER.SPORTS_BAG,
    {
        [STAT.staminaBonus]: 15,
        [STAT.maxWeightBonus]: 20,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 2190,
                [RESOURCE.STINKY_ROOT]: 15,
                [RESOURCE.SEEDS]: 5,
            },
            dependency: CONTAINER.TRANSFORMER_BAG,
        },
        {
            resources: {
                [RESOURCE.RUB]: 2700,
                [RESOURCE.STINKY_ROOT]: 23,
                [RESOURCE.SEEDS]: 8,
            },
        },
    ].filter(Boolean), 0, 0);

export default sportsBag;