import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const trapperSuit = new Armor(ARMOR.TRAPPER_SUIT,
    {
        [STAT.maxWeightBonus]: 6,
        [STAT.bulletDmgFactor]: 44,
        [STAT.tearDmgFactor]: 125,
        [STAT.explosionDmgFactor]: 60,
        [STAT.electraDmgFactor]: 27,
        [STAT.burnDmgFactor]: 27,
        [STAT.chemicalBurnDmgFactor]: 27,
        [STAT.radiationProtection]: 120,
        [STAT.thermalProtection]: 20,
        [STAT.biologicalProtection]: 120,
        [STAT.psychoProtection]: 20,
        [STAT.bleedingProtection]: 62,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 3390,
            [RESOURCE.PIECES_OF_COPPER_WIRE]: 9,
            [RESOURCE.RUSTY_MOLD]: 56,
            [RESOURCE.CRAPPITE]: 10,
        },
        dependency: ARMOR.WORN_TRAPPER_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default trapperSuit;