import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const kiselCrystal = new Artifact(ARTIFACT.KISEL_CRYSTAL, {
    [STAT.regenerationBonus]: [-0.5, -1],
    [STAT.reactionToChemicalBurn]: [1, 3],
    [STAT.biologicalAccumulation]: [1, 2],
}, {
    [STAT.reactionToChemicalBurn]: [0.5, 1.5],
    [STAT.chemicalBurnDmgFactor]: [5, 10],
    [STAT.regenerationBonus]: [0.25, 0.5],
});

export default kiselCrystal;