import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const kleptomaniacSuit = new Armor(ARMOR.KLEPTOMANIAC_SUIT,
    {
        [STAT.maxWeightBonus]: 8,
        [STAT.bulletDmgFactor]: 56,
        [STAT.tearDmgFactor]: 60,
        [STAT.explosionDmgFactor]: 86,
        [STAT.electraDmgFactor]: 40,
        [STAT.burnDmgFactor]: 40,
        [STAT.chemicalBurnDmgFactor]: 40,
        [STAT.radiationProtection]: 105,
        [STAT.thermalProtection]: 60,
        [STAT.biologicalProtection]: 140,
        [STAT.psychoProtection]: 60,
        [STAT.bleedingProtection]: 38,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 4340,
            [RESOURCE.PIECES_OF_COPPER_WIRE]: 13,
            [RESOURCE.RUSTY_MOLD]: 39,
            [RESOURCE.CRAPPITE]: 34,
        },
        dependency: ARMOR.WORN_KLEPTOMANIAC,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default kleptomaniacSuit;