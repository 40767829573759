import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const karouselOstov = new Artifact(ARTIFACT.KAROUSEL_OSTOV, {
    [STAT.staminaBonus]: [0, 10],
    [STAT.maxWeightBonus]: [0, 5],
}, {
    [STAT.maxWeightBonus]: [0, 2.5],
    [STAT.staminaBonus]: [0, 5],
    [STAT.tearDmgFactor]: [5, 10],
});

export default karouselOstov;