import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const kiselSvetlyak = new Artifact(ARTIFACT.KISEL_SVETLYAK, {
    [STAT.healthBonus]: [2, 4],
    [STAT.regenerationBonus]: [1.5, 3],
    [STAT.psychoAccumulation]: [-3, -8],
}, {
    [STAT.speedModifier]: [1, 3],
    [STAT.regenerationBonus]: [0.75, 1.5],
    [STAT.psychoAccumulation]: [0.75, 1],
});

export default kiselSvetlyak;