import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const magnetSuit = new Armor(ARMOR.MAGNET_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 20,
        [STAT.artefaktHeal]: 1,
        [STAT.bulletDmgFactor]: 68,
        [STAT.tearDmgFactor]: 78,
        [STAT.explosionDmgFactor]: 84,
        [STAT.electraDmgFactor]: 421,
        [STAT.burnDmgFactor]: 421,
        [STAT.chemicalBurnDmgFactor]: 421,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 190,
        [STAT.biologicalProtection]: 290,
        [STAT.psychoProtection]: 190,
        [STAT.bleedingProtection]: 38,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default magnetSuit;