import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const blizzardFrost = new Artifact(ARTIFACT.BLIZZARD_FROST, {
    [STAT.healEfficiency]: [-5, -10],
    [STAT.thermalDmgFactor]: [5, 15],
    [STAT.tearDmgFactor]: [5, 15],
}, {
    [STAT.burnDmgFactor]: [5, 15],
    [STAT.thermalDmgFactor]: [2.5, 7.5],
    [STAT.healEfficiency]: [2, 4],
});

export default blizzardFrost;