import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const skat9mArmoredSuit = new Armor(ARMOR.SKAT_9_M_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -6,
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 130,
        [STAT.tearDmgFactor]: 140,
        [STAT.explosionDmgFactor]: 189,
        [STAT.electraDmgFactor]: 326,
        [STAT.burnDmgFactor]: 326,
        [STAT.chemicalBurnDmgFactor]: 326,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 150,
        [STAT.biologicalProtection]: 210,
        [STAT.psychoProtection]: 200,
        [STAT.bleedingProtection]: 58,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 1230890,
            [RESOURCE.QUANTUM_BATTERY]: 400,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 910,
            [RESOURCE.RED_FERN]: 480,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 560,
            [RESOURCE.SUBSTANCE_07270]: 1430,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.WORN_SKAT_9_M,
    },
    {
        resources: {
            [RESOURCE.RUB]: 1230890,
            [RESOURCE.ANOMALOUS_BATTERY]: 33,
            [RESOURCE.LIMBOPLASMA]: 9,
            [RESOURCE.BITTERLEAF]: 870,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 330,
            [RESOURCE.LIM]: 700,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.WORN_SKAT_9_M,
    },
    {
        resources: {
            [RESOURCE.RUB]: 1626350,
            [RESOURCE.QUANTUM_BATTERY]: 530,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1200,
            [RESOURCE.RED_FERN]: 630,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 740,
            [RESOURCE.SUBSTANCE_07270]: 1890,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.DAMAGED_SKAT_9_M,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default skat9mArmoredSuit;