import {ContainerData} from "../../models/container";

export enum CONTAINER_TYPE {
    COMPACT_CONTAINER = 'COMPACT_CONTAINER',
    STANDARD_CONTAINER = 'STANDARD_CONTAINER',
    SPACIOUS_CONTAINER = 'SPACIOUS_CONTAINER',
    BULKY_CONTAINER = 'BULKY_CONTAINER',
    LIGHT_BACKPACK = 'LIGHT_BACKPACK',
    MEDIUM_BACKPACK = 'MEDIUM_BACKPACK',
    HEAVY_BACKPACK = 'HEAVY_BACKPACK',
}

export enum BULKY_CONTAINER {
    KZS_3 = '2vvv',
    KZS_3U = '1p2r',
    KZS_4 = '49gj',
    KZS_4U = 'rg1z',
    KZS_5 = 'n3v9',
    KZS_5Y = '3d11',
}

export enum SPACIOUS_CONTAINER {
    BEARS_DEN_4_CONTAINER = 'w4wz',
    BERLOGA_4U_CONTAINER = 'l3n2',
    BEARS_DEN_6_CONTAINER = 'g35n',
    BERLOGA_6U_CONTAINER = '49dj',
}

export enum STANDARD_CONTAINER {
    FORAGER_CONTAINER = 'q194',
    COCOON_CONTAINER = 'k3oj',
    HIVE_CONTAINER = 'p92d',
    FRIDGE_CONTAINER = 'g3ln',
    FREEZER_CONTAINER = 'm3pk',
    PACK_BAG = 'zjqk',
    SMC_CONTAINER = 'j3ml',
}

export enum COMPACT_CONTAINER {
    KZS_1_CONTAINER = '9yyq',
    KZS_1U_CONTAINER = 'yqq0',
    KZS_2 = 'j33l',
    KZS_2U = 'o335',
}

export enum LIGHT_BACKPACK {
    TRANSFORMER_BAG = 'jmp6',
    SPORTS_BAG = 'odq0',
}

export enum MEDIUM_BACKPACK {
    ERRAND_JUNIOR_BACKPACK = '7yl7',
    HELLBOY_BACKPACK = 'l332',
    REINFORCED_HELLBOY_BACKPACK = 'n339',
    MBSS_BACKPACK = '12r1',
    TRI_ZIP_ASSAULT_BACKPACK = 'lny1',
}

export enum HEAVY_BACKPACK {
    GAME_BAG = '0ndk',
    NPA_BACKPACK = 'nv43',
}

export enum NO_CONTAINER {
    NONE = 'NONE',
}

export const CONTAINER = {
    ...BULKY_CONTAINER,
    ...COMPACT_CONTAINER,
    ...STANDARD_CONTAINER,
    ...SPACIOUS_CONTAINER,
    ...LIGHT_BACKPACK,
    ...MEDIUM_BACKPACK,
    ...HEAVY_BACKPACK,
    ...NO_CONTAINER
};


// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CONTAINER = BULKY_CONTAINER | COMPACT_CONTAINER | STANDARD_CONTAINER | SPACIOUS_CONTAINER | LIGHT_BACKPACK| MEDIUM_BACKPACK | HEAVY_BACKPACK | NO_CONTAINER;
export const REVERSE_CONTAINER = Object.entries(CONTAINER).reduce((acc, [key, value]) => ({...acc, [value]: key}), {} as Record<CONTAINER, string>);

export const emptyContainerFactory = (): ContainerData => ({
    id: CONTAINER.NONE
});