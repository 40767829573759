import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const cocoonContainer = new Container(
    CONTAINER.COCOON_CONTAINER,
    {
        [STAT.speedModifier]: -1.5,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 1035370,
                [RESOURCE.QUANTUM_BATTERY]: 152,
                [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1080,
                [RESOURCE.RED_FERN]: 900,
                [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 530,
                [RESOURCE.SUBSTANCE_07270]: 1360,
            },
            dependency: CONTAINER.KZS_5,
        },
        {
            resources: {
                [RESOURCE.RUB]: 1035370,
                [RESOURCE.ANOMALOUS_BATTERY]: 6,
                [RESOURCE.LIMBOPLASMA]: 17,
                [RESOURCE.BITTERLEAF]: 1100,
                [RESOURCE.LAMBDA_DATA_FRAGMENT]: 320,
                [RESOURCE.LIM]: 880,
            },
            dependency: CONTAINER.KZS_5,
        },
    ].filter(Boolean), 3, 0.9);

export default cocoonContainer;