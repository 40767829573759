import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const kiselUlitka = new Artifact(ARTIFACT.KISEL_ULITKA, {
    [STAT.staminaBonus]: [-10, -20],
    [STAT.psychoDmgFactor]: [5, 15],
    [STAT.psychoAccumulation]: [2, 5],
}, {
    [STAT.psychoDmgFactor]: [2.5, 7.5],
    [STAT.chemicalBurnDmgFactor]: [5, 15],
    [STAT.staminaBonus]: [4, 8],
});

export default kiselUlitka;