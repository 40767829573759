import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const reaperSuit = new Armor(ARMOR.REAPER_SUIT,
    {
        [STAT.maxWeightBonus]: 5,
        [STAT.bulletDmgFactor]: 63,
        [STAT.tearDmgFactor]: 71,
        [STAT.explosionDmgFactor]: 86,
        [STAT.electraDmgFactor]: 773,
        [STAT.burnDmgFactor]: 773,
        [STAT.chemicalBurnDmgFactor]: 773,
        [STAT.radiationProtection]: 340,
        [STAT.thermalProtection]: 340,
        [STAT.biologicalProtection]: 400,
        [STAT.psychoProtection]: 310,
        [STAT.bleedingProtection]: 40,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 595380,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 550,
            [RESOURCE.RED_FERN]: 320,
            [RESOURCE.BETA_DATA_FRAGMENT]: 194,
            [RESOURCE.SUBSTANCE_07270]: 480,
        },
        dependency: ARMOR.WORN_REAPER_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 595380,
            [RESOURCE.LIMBOPLASMA]: 8,
            [RESOURCE.BITTERLEAF]: 350,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 80,
            [RESOURCE.LIM]: 279,
        },
        dependency: ARMOR.WORN_REAPER_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 595380,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 550,
            [RESOURCE.RED_FERN]: 320,
            [RESOURCE.BETA_DATA_FRAGMENT]: 194,
            [RESOURCE.SUBSTANCE_07270]: 480,
        },
        dependency: ARMOR.WORN_SEVA_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default reaperSuit;