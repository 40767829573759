import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const wornJaeger = new Armor(ARMOR.WORN_JAEGER,
    {
        [STAT.staminaBonus]: -15,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 25,
        [STAT.bulletDmgFactor]: 92,
        [STAT.tearDmgFactor]: 276,
        [STAT.explosionDmgFactor]: 127,
        [STAT.electraDmgFactor]: 56,
        [STAT.burnDmgFactor]: 56,
        [STAT.chemicalBurnDmgFactor]: 56,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 30,
        [STAT.biologicalProtection]: 190,
        [STAT.psychoProtection]: 170,
        [STAT.bleedingProtection]: 77,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default wornJaeger;