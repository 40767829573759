import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const tongaSuit = new Armor(ARMOR.TONGA_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 20,
        [STAT.artefaktHeal]: 2,
        [STAT.radiationAccumulation]: 1,
        [STAT.bleedingAccumulation]: 0.5,
        [STAT.bulletDmgFactor]: 76,
        [STAT.tearDmgFactor]: 87,
        [STAT.explosionDmgFactor]: 94,
        [STAT.electraDmgFactor]: 474,
        [STAT.burnDmgFactor]: 474,
        [STAT.chemicalBurnDmgFactor]: 474,
        [STAT.radiationProtection]: 240,
        [STAT.thermalProtection]: 240,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 210,
        [STAT.bleedingProtection]: 40,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default tongaSuit;