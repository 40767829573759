import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const sbaTank = new Armor(ARMOR.SBA_TANK,
    {
        [STAT.staminaBonus]: -35,
        [STAT.speedModifier]: -15,
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 240,
        [STAT.tearDmgFactor]: 291,
        [STAT.explosionDmgFactor]: 297,
        [STAT.electraDmgFactor]: 53,
        [STAT.burnDmgFactor]: 53,
        [STAT.chemicalBurnDmgFactor]: 53,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 50,
        [STAT.biologicalProtection]: 100,
        [STAT.psychoProtection]: 190,
        [STAT.bleedingProtection]: 75,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default sbaTank;