import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornAh6Nomad = new Armor(ARMOR.WORN_AH_6_NOMAD,
    {
        [STAT.staminaBonus]: -10,
        [STAT.speedModifier]: -2,
        [STAT.maxWeightBonus]: 15,
        [STAT.artefaktHeal]: 2.7,
        [STAT.bulletDmgFactor]: 97,
        [STAT.tearDmgFactor]: 113,
        [STAT.explosionDmgFactor]: 136,
        [STAT.electraDmgFactor]: 652,
        [STAT.burnDmgFactor]: 652,
        [STAT.chemicalBurnDmgFactor]: 652,
        [STAT.radiationProtection]: 240,
        [STAT.thermalProtection]: 240,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 225,
        [STAT.bleedingProtection]: 52,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 1244710,
            [RESOURCE.QUANTUM_BATTERY]: 162,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1240,
            [RESOURCE.RED_FERN]: 1110,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 340,
            [RESOURCE.SUBSTANCE_07270]: 1810,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.RAPS_AH_5_PILGRIM,
    },
    {
        resources: {
            [RESOURCE.RUB]: 1244710,
            [RESOURCE.LIMBOPLASMA]: 16,
            [RESOURCE.BITTERLEAF]: 1470,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 340,
            [RESOURCE.LIM]: 1180,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.RAPS_AH_5_PILGRIM,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default wornAh6Nomad;