import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const karouselJelch = new Artifact(ARTIFACT.KAROUSEL_JELCH, {
    [STAT.biologicalDmgFactor]: [5, 15],
    [STAT.radiationAccumulation]: [-2, -5],
    [STAT.biologicalAccumulation]: [1, 3],
}, {
    [STAT.biologicalDmgFactor]: [2.5, 7.5],
    [STAT.reactionToTear]: [1, 2],
    [STAT.radiationAccumulation]: [0.5, 1.25],
});

export default karouselJelch;