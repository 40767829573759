import React, {useCallback, useState} from "react";
import {Button, ButtonGroup, Dropdown, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import './search-dropdown.css';

export const SEARCH_DROPDOWN_BUTTON_TEST_ID = 'search-dropdown-button';
export const SEARCH_DROPDOWN_CLEAR_BUTTON_TEST_ID = 'search-dropdown-clear-button';
export const SEARCH_DROPDOWN_LIST_TEST_ID = 'search-dropdown-list';
export const SEARCH_DROPDOWN_INPUT_TEST_ID = 'search-dropdown-input';

type DropdownMenu = typeof Dropdown['Menu']['prototype'];

type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
    id: string;
    hasValue: boolean;
    onClear?: () => void;
};

const CustomToggle = React.forwardRef(
    ({onClick, children, id, hasValue, onClear}: CustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
        <ButtonGroup className='container p-0'>
            <Button id={id} ref={ref} variant="outline-secondary" onClick={onClick} data-testid={SEARCH_DROPDOWN_BUTTON_TEST_ID}>{children}</Button>
            {hasValue && <Button onClick={onClear} data-testid={SEARCH_DROPDOWN_CLEAR_BUTTON_TEST_ID} variant="outline-danger flex-grow-0">X</Button>}
        </ButtonGroup>

    )
);

type CustomMenuProps = {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    labeledBy?: string;
}

const CustomMenu = React.forwardRef<DropdownMenu, CustomMenuProps>(
    ({
        children,
        style,
        className,
        labeledBy ,
     }, ref) => {
    const [value, setValue] = useState('');
    const {t} = useTranslation();
    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    }, [setValue]);
    const filteredChildren = (child: any) => {
        return !value || child.props.children.toLowerCase().indexOf(value) !== -1;
    }

    return (
        <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
        >
            <Form.Control
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder={t('filterDropdown.inputPlaceholder') as string}
                onChange={onChange}
                value={value}
                data-testid={SEARCH_DROPDOWN_INPUT_TEST_ID}
                ref={(input: HTMLInputElement) => { input?.focus(); }}
            />
            <ul className="list-unstyled mb-0 overflow-auto search-dropdown-list" data-testid={SEARCH_DROPDOWN_LIST_TEST_ID}>
                {React.Children.toArray(children).filter(filteredChildren)}
            </ul>
        </div>
    );
});


interface SearchDropdownProps<T> {
    id: string,
    value: T | null,
    onChange: (value: T | null) => void
    list: T[],
    getLabel: (value: T) => string
    placeholder: string
    className?: string
}
function SearchDropdown<T>({
    id,
    value,
    onChange,
    list,
    getLabel,
    placeholder,
    className,
}: SearchDropdownProps<T>) {
    return (
        <Dropdown className={`${className ?? ''}`}>
            <Dropdown.Toggle id={id} as={CustomToggle as any} hasValue={value !== null} onClear={() => onChange?.(null)}>
                {value ? getLabel(value) : placeholder}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu as any}>
                {list.map((item, index) => (
                    <Dropdown.Item key={`${id}-${index}`} data-testid={`${SEARCH_DROPDOWN_LIST_TEST_ID}-${index}`} onClick={() => onChange?.(item)}>{getLabel(item)}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default SearchDropdown;