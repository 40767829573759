import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const voronkaGoldgravi = new Artifact(ARTIFACT.VORONKA_GOLDGRAVI, {
    [STAT.speedModifier]: [1, 3],
    [STAT.maxWeightBonus]: [10, 15],
    [STAT.radiationAccumulation]: [-4, -8],
}, {
    [STAT.staminaRegenerationBonus]: [1, 2.5],
    [STAT.maxWeightBonus]: [5, 7.5],
    [STAT.radiationAccumulation]: [1, 2],
});

export default voronkaGoldgravi;