import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const voronkaGravi = new Artifact(ARTIFACT.VORONKA_GRAVI, {
    [STAT.maxWeightBonus]: [3, 5],
    [STAT.radiationAccumulation]: [-2, -5],
    [STAT.tearDmgFactor]: [10, 20],
}, {
    [STAT.tearDmgFactor]: [2.5, 5],
    [STAT.radiationAccumulation]: [0.5, 1.25],
    [STAT.bleedingProtection]: [5, 15],
});

export default voronkaGravi;