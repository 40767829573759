import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const trampolineJilnik = new Artifact(ARTIFACT.TRAMPOLINE_JILNIK, {
    [STAT.healthBonus]: [-3, -5],
    [STAT.psychoAccumulation]: [1, 3],
    [STAT.bulletDmgFactor]: [10, 15],
    [STAT.electraDmgFactor]: [10, 15],
}, {
    [STAT.psychoDmgFactor]: [5, 15],
    [STAT.healthBonus]: [0.75, 1.25],
    [STAT.electraDmgFactor]: [5, 7.5],
});

export default trampolineJilnik;