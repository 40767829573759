import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const blizzardStormeye = new Artifact(ARTIFACT.BLIZZARD_STORMEYE, {
    [STAT.healthBonus]: [-3, -5],
    [STAT.radiationDmgFactor]: [10, 20],
    [STAT.biologicalDmgFactor]: [10, 20],
    [STAT.thermalDmgFactor]: [10, 20],
    [STAT.psychoDmgFactor]: [10, 20],
}, {
    [STAT.speedModifier]: [1, 3],
    [STAT.psychoDmgFactor]: [10, 20],
    [STAT.thermalDmgFactor]: [10, 20],
    [STAT.biologicalDmgFactor]: [10, 20],
    [STAT.radiationDmgFactor]: [5, 10],
});

export default blizzardStormeye;