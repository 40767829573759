import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const blizzardChickengod = new Artifact(ARTIFACT.BLIZZARD_CHICKENGOD, {
    [STAT.staminaRegenerationBonus]: [-2.5, -5],
    [STAT.reactionToElectroshock]: [3, 5],
    [STAT.reactionToBurn]: [3, 5],
    [STAT.reactionToChemicalBurn]: [3, 5],
}, {
    [STAT.burnDmgFactor]: [10, 15],
    [STAT.chemicalBurnDmgFactor]: [10, 15],
    [STAT.electraDmgFactor]: [10, 15],
});

export default blizzardChickengod;