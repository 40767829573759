import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const blizzardCrystal = new Artifact(ARTIFACT.BLIZZARD_CRYSTAL, {
    [STAT.radiationDmgFactor]: [5, 15],
    [STAT.psychoDmgFactor]: [5, 15],
    [STAT.bleedingDmgFactor]: [-25, -50],
}, {
    [STAT.psychoDmgFactor]: [2.5, 7.5],
    [STAT.radiationDmgFactor]: [2.5, 7.5],
    [STAT.bleedingDmgFactor]: [10, 20],
});

export default blizzardCrystal;