import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const puhKoluchka = new Artifact(ARTIFACT.PUH_KOLUCHKA, {
    [STAT.radiationAccumulation]: [2, 4],
    [STAT.bleedingProtection]: [-50, -100],
}, {
    [STAT.radiationAccumulation]: [0.5, 1],
    [STAT.reactionToChemicalBurn]: [1, 2],
    [STAT.bleedingProtection]: [20, 40],
});

export default puhKoluchka;