import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const transformerBag = new Container(
    CONTAINER.TRANSFORMER_BAG,
    {
        [STAT.staminaBonus]: 10,
        [STAT.maxWeightBonus]: 15,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 510,
                [RESOURCE.GREEN_MOLD]: 6,
                [RESOURCE.SWAMP_STONE]: 7,
            },
        },
    ].filter(Boolean), 0, 0);

export default transformerBag;