import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const kzs5 = new Container(
    CONTAINER.KZS_5,
    {
        [STAT.speedModifier]: -3,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 32750,
                [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 57,
                [RESOURCE.BLUE_YEAST]: 254,
                [RESOURCE.BETA_DATA_FRAGMENT]: 54,
                [RESOURCE.PICKLE]: 100,
            },
            dependency: CONTAINER.KZS_4,
        },
        {
            resources: {
                [RESOURCE.RUB]: 96780,
                [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 168,
                [RESOURCE.BLUE_YEAST]: 750,
                [RESOURCE.BETA_DATA_FRAGMENT]: 158,
                [RESOURCE.PICKLE]: 294,
            },
        },
    ].filter(Boolean), 5, 0.75);

export default kzs5;