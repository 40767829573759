import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const jarkaGlaz = new Artifact(ARTIFACT.JARKA_GLAZ, {
    [STAT.radiationAccumulation]: [-3, -5],
    [STAT.bulletDmgFactor]: [5, 15],
    [STAT.explosionDmgFactor]: [5, 15],
}, {
    [STAT.radiationAccumulation]: [0.75, 1.25],
    [STAT.explosionDmgFactor]: [1.25, 3.75],
    [STAT.bleedingAccumulation]: [1, 2],
});

export default jarkaGlaz;