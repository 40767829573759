import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {STAT} from "../../../models/stats";

const berloga6uContainer = new Container(
    CONTAINER.BERLOGA_6U_CONTAINER,
    {
        [STAT.speedModifier]: -2,
    },
    [
    ].filter(Boolean), 6, 0.6);

export default berloga6uContainer;