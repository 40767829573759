import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const mis122Amethyst = new Armor(ARMOR.MIS_122_AMETHYST,
    {
        [STAT.bulletDmgFactor]: 53,
        [STAT.tearDmgFactor]: 63,
        [STAT.explosionDmgFactor]: 76,
        [STAT.electraDmgFactor]: 933,
        [STAT.burnDmgFactor]: 933,
        [STAT.chemicalBurnDmgFactor]: 933,
        [STAT.radiationProtection]: 370,
        [STAT.thermalProtection]: 370,
        [STAT.biologicalProtection]: 400,
        [STAT.psychoProtection]: 400,
        [STAT.bleedingProtection]: 38,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 757900,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 930,
            [RESOURCE.RED_FERN]: 540,
            [RESOURCE.BETA_DATA_FRAGMENT]: 330,
            [RESOURCE.SUBSTANCE_07270]: 810,
        },
        dependency: ARMOR.MIS_99_MALACHITE,
    },
    {
        resources: {
            [RESOURCE.RUB]: 556980,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 340,
            [RESOURCE.RED_FERN]: 195,
            [RESOURCE.BETA_DATA_FRAGMENT]: 120,
            [RESOURCE.SUBSTANCE_07270]: 293,
        },
        dependency: ARMOR.WORN_AMETHYST,
    },
    {
        resources: {
            [RESOURCE.RUB]: 698780,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 760,
            [RESOURCE.RED_FERN]: 440,
            [RESOURCE.BETA_DATA_FRAGMENT]: 268,
            [RESOURCE.SUBSTANCE_07270]: 660,
        },
        dependency: ARMOR.MIS_113_IOLITE,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default mis122Amethyst;