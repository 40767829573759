import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const puzirAtom = new Artifact(ARTIFACT.PUZIR_ATOM, {
    [STAT.radiationDmgFactor]: [10, 25],
    [STAT.biologicalDmgFactor]: [10, 25],
    [STAT.radiationAccumulation]: [3, 5],
    [STAT.biologicalAccumulation]: [3, 5],
}, {
    [STAT.psychoAccumulation]: [3, 5],
    [STAT.psychoDmgFactor]: [10, 25],
    [STAT.thermalAccumulation]: [3, 5],
    [STAT.thermalDmgFactor]: [10, 25],
});

export default puzirAtom;