import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const electraPustishka = new Artifact(ARTIFACT.ELECTRA_PUSTISHKA, {
    [STAT.radiationAccumulation]: [-1, -3],
    [STAT.burnDmgFactor]: [15, 30],
}, {
    [STAT.burnDmgFactor]: [7.5, 12.5],
    [STAT.reactionToBurn]: [1, 2],
    [STAT.radiationAccumulation]: [0.5, 1.25],
});

export default electraPustishka;