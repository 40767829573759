import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const electraDezintegrator = new Artifact(ARTIFACT.ELECTRA_DEZINTEGRATOR, {
    [STAT.psychoAccumulation]: [2, 5],
    [STAT.electraDmgFactor]: [-50, -100],
}, {
    [STAT.reactionToElectroshock]: [1, 3],
    [STAT.psychoAccumulation]: [0.5, 1.25],
    [STAT.electraDmgFactor]: [20, 40],
});

export default electraDezintegrator;