import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {STAT} from "../../../models/stats";

const smcContainer = new Container(
    CONTAINER.SMC_CONTAINER,
    {
        [STAT.speedModifier]: -1.5,
    },
    [
    ].filter(Boolean), 4, 0.93);

export default smcContainer;