import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const modifiedExoskeleton = new Armor(ARMOR.MODIFIED_EXOSKELETON,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 40,
        [STAT.bulletDmgFactor]: 160,
        [STAT.tearDmgFactor]: 182,
        [STAT.explosionDmgFactor]: 229,
        [STAT.electraDmgFactor]: 89,
        [STAT.burnDmgFactor]: 89,
        [STAT.chemicalBurnDmgFactor]: 89,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 100,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 200,
        [STAT.bleedingProtection]: 65,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default modifiedExoskeleton;