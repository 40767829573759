import {
    PolyhedronStats, SPECIAL_STAT,
    SpecialStats,
    STAT,
    STAT_TYPE,
    Stats,
    StatsKey,
    statTypeMap,
} from "../../../models/stats";
import {getStatTKey} from "../../../utils/search";
import {Table} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";


export type StatRowProps = {
    stat: StatsKey;
    value: number;
    statType: STAT_TYPE
};

export const STAT_ROW_NAME_TESTID = 'stat-row-name';
export const STAT_ROW_VALUE_TESTID = 'stat-row-value';

export const StatRow: React.FC<StatRowProps> = ({stat, value, statType}) => {
    const {t} = useTranslation();

    return (
        <tr className={value >= 0 ? '' : 'text-danger'}>
            <td data-testid={STAT_ROW_NAME_TESTID}>{t(getStatTKey(stat))}</td>
            <td data-testid={STAT_ROW_VALUE_TESTID}>{t(`calculator.stat.${statType}`, { value: value.toFixed(2) })}</td>
        </tr>
    )
};

export const POLYHEDRON_ROW_TESTID = 'polyhedron-row';
export const PolyhedronRow: React.FC<Record<PolyhedronStats, number>> = ({ [SPECIAL_STAT.polyhedronCooldown]: polyhedronCooldown, [SPECIAL_STAT.polyhedronBlockingDamage]: polyhedronBlockingDamage }) => {
    const {t} = useTranslation();

    return (
        <tr>
            <td data-testid={POLYHEDRON_ROW_TESTID}>{t(`calculator.stat.polyhedron`, {
                polyhedronCooldown: +polyhedronCooldown.toFixed(2),
                polyhedronBlockingDamage: +polyhedronBlockingDamage.toFixed(1)
            })}</td>
        </tr>
    )
}

const StatOrder = [
    STAT.bulletDmgFactor,
    STAT.tearDmgFactor,
    STAT.explosionDmgFactor,

    STAT.electraDmgFactor,
    STAT.burnDmgFactor,
    STAT.chemicalBurnDmgFactor,
    STAT.radiationProtection,
    STAT.thermalProtection,
    STAT.biologicalProtection,
    STAT.psychoProtection,
    STAT.bleedingProtection,

    STAT.biologicalDmgFactor,
    STAT.bleedingDmgFactor,
    STAT.combustionDmgFactor,
    STAT.thermalDmgFactor,
    STAT.frostDmgFactor,
    STAT.psychoDmgFactor,
    STAT.toxicDmgFactor,
    STAT.radiationDmgFactor,

    STAT.healEfficiency,
    STAT.healthBonus,
    STAT.regenerationBonus,
    STAT.totalHp,

    STAT.biologicalAccumulation,
    STAT.radiationAccumulation,
    STAT.toxicAccumulation,
    STAT.frostAccumulation,
    STAT.thermalAccumulation,
    STAT.psychoAccumulation,
    STAT.bleedingAccumulation,
    STAT.combustionAccumulation,

    STAT.jumpIncrease,
    STAT.maxWeightBonus,
    STAT.speedModifier,
    STAT.staminaBonus,
    STAT.staminaRegenerationBonus,

    STAT.biologicalProtectionShort,
    STAT.combustionProtection,
    STAT.fallProtection,
    STAT.frostProtection,
    STAT.psychoProtectionShort,
    STAT.radiationProtectionShort,
    STAT.toxicProtection,
    STAT.reactionToBurn,
    STAT.reactionToChemicalBurn,
    STAT.reactionToElectroshock,
    STAT.reactionToTear,
    STAT.recoilBonus,

    STAT.artefactDamage,
    STAT.artefactHeal,
    STAT.bulletDeflection,
    STAT.bulletSlowdown,
    STAT.reloadBonus,
    STAT.spreadBonus,
    STAT.tenacity,
    STAT.wiggleBonus,
]

export type StatsProps = {
    stats: Stats,
    specialStats: SpecialStats
};

const StatsComponent: React.FC<StatsProps> = ({stats, specialStats}) => {
    return (
        <Table>
            <tbody>
            {StatOrder.map((stat) => (
                stats[stat] !== undefined && <StatRow key={stat} stat={stat} value={stats[stat] as number} statType={statTypeMap[stat] as STAT_TYPE}/>
            ))}
            {specialStats?.polyhedron?.map((polyhedron, index) => (
                <PolyhedronRow key={index} {...polyhedron}/>
            ))}
            </tbody>
        </Table>
    )
};

export default StatsComponent;