import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const vanguardArmoredSuit = new Armor(ARMOR.VANGUARD_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -25,
        [STAT.speedModifier]: -6,
        [STAT.maxWeightBonus]: 25,
        [STAT.bulletDmgFactor]: 192,
        [STAT.tearDmgFactor]: 255,
        [STAT.explosionDmgFactor]: 229,
        [STAT.electraDmgFactor]: 44,
        [STAT.burnDmgFactor]: 44,
        [STAT.chemicalBurnDmgFactor]: 44,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 50,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 200,
        [STAT.bleedingProtection]: 69.2,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 4215110,
            [RESOURCE.QUANTUM_BATTERY]: 1640,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 780,
            [RESOURCE.RED_FERN]: 5330,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 760,
            [RESOURCE.SUBSTANCE_07270]: 5350,
            [RESOURCE.ANOMALOUS_SERUM]: 90,
        },
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default vanguardArmoredSuit;