import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const rapsAh6Nomad = new Armor(ARMOR.RAPS_AH_6_NOMAD,
    {
        [STAT.staminaBonus]: -10,
        [STAT.speedModifier]: -2,
        [STAT.maxWeightBonus]: 15,
        [STAT.artefaktHeal]: 3,
        [STAT.bulletDmgFactor]: 105,
        [STAT.tearDmgFactor]: 124,
        [STAT.explosionDmgFactor]: 149,
        [STAT.electraDmgFactor]: 711,
        [STAT.burnDmgFactor]: 711,
        [STAT.chemicalBurnDmgFactor]: 711,
        [STAT.radiationProtection]: 240,
        [STAT.thermalProtection]: 240,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 240,
        [STAT.bleedingProtection]: 53,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 2193720,
            [RESOURCE.QUANTUM_BATTERY]: 285,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 2190,
            [RESOURCE.RED_FERN]: 1950,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 600,
            [RESOURCE.SUBSTANCE_07270]: 3180,
            [RESOURCE.ANOMALOUS_SERUM]: 75,
        },
        dependency: ARMOR.WORN_AH_6_NOMAD,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default rapsAh6Nomad;