import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const kzs4 = new Container(
    CONTAINER.KZS_4,
    {
        [STAT.speedModifier]: -2,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 31020,
                [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 91,
                [RESOURCE.BLUE_YEAST]: 241,
                [RESOURCE.ALPHA_DATA_FRAGMENT]: 40,
                [RESOURCE.PICKLE]: 95,
            },
            dependency: CONTAINER.KZS_3,
        },
    ].filter(Boolean), 4, 0.75);

export default kzs4;