import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const jarkaVslezy = new Artifact(ARTIFACT.JARKA_VSLEZY, {
    [STAT.maxWeightBonus]: [3, 5],
    [STAT.biologicalAccumulation]: [0, -2],
    [STAT.thermalAccumulation]: [1, 2],
}, {
    [STAT.burnDmgFactor]: [5, 10],
    [STAT.maxWeightBonus]: [1.5, 2.5],
    [STAT.biologicalAccumulation]: [0, 0.5],
});

export default jarkaVslezy;