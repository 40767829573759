import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const trampolineNightstar = new Artifact(ARTIFACT.TRAMPOLINE_NIGHTSTAR, {
    [STAT.psychoDmgFactor]: [5, 15],
    [STAT.radiationAccumulation]: [-2, -5],
    [STAT.psychoAccumulation]: [2, 5],
}, {
    [STAT.psychoDmgFactor]: [2.5, 7.5],
    [STAT.maxWeightBonus]: [5, 7.5],
    [STAT.radiationAccumulation]: [0.5, 1.25],
});

export default trampolineNightstar;