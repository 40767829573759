import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornMis99mMalachite = new Armor(ARMOR.WORN_MIS_99_M_MALACHITE,
    {
        [STAT.bulletDmgFactor]: 42,
        [STAT.tearDmgFactor]: 47,
        [STAT.explosionDmgFactor]: 57,
        [STAT.electraDmgFactor]: 516,
        [STAT.burnDmgFactor]: 516,
        [STAT.chemicalBurnDmgFactor]: 516,
        [STAT.radiationProtection]: 210,
        [STAT.thermalProtection]: 210,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 120,
        [STAT.bleedingProtection]: 31,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 54070,
            [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 183,
            [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 180,
            [RESOURCE.ALPHA_DATA_FRAGMENT]: 33,
            [RESOURCE.PIECE_OF_INFERNAL_COAL]: 97,
        },
        dependency: ARMOR.MIS_99_AMBER,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default wornMis99mMalachite;