import {STANDARD_CONTAINER} from "../container";
import Container from "../../../models/container";
import fridgeContainer from "./fridge-container";
import foragerContainer from "./forager-container";
import hiveContainer from "./hive-container";
import cocoonContainer from "./cocoon-container";
import freezerContainer from "./freezer-container";
import packBag from "./pack-bag";
import smcContainer from "./smc-container";

const StandardContainers: Record<STANDARD_CONTAINER, Container> = {
    [STANDARD_CONTAINER.FORAGER_CONTAINER]: foragerContainer,
    [STANDARD_CONTAINER.HIVE_CONTAINER]: hiveContainer,
    [STANDARD_CONTAINER.COCOON_CONTAINER]: cocoonContainer,
    [STANDARD_CONTAINER.FRIDGE_CONTAINER]: fridgeContainer,
    [STANDARD_CONTAINER.FREEZER_CONTAINER]: freezerContainer,
    [STANDARD_CONTAINER.PACK_BAG]: packBag,
    [STANDARD_CONTAINER.SMC_CONTAINER]: smcContainer,
}

export default StandardContainers;