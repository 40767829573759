import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const karouselGnilec = new Artifact(ARTIFACT.KAROUSEL_GNILEC, {
    [STAT.staminaBonus]: [-5, -15],
    [STAT.reactionToChemicalBurn]: [2, 3],
    [STAT.biologicalAccumulation]: [2, 5],
}, {
    [STAT.reactionToChemicalBurn]: [1, 1.5],
    [STAT.chemicalBurnDmgFactor]: [5, 10],
    [STAT.staminaBonus]: [4, 6],
});

export default karouselGnilec;