import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {STAT} from "../../../models/stats";

const gameBag = new Container(
    CONTAINER.GAME_BAG,
    {
        [STAT.staminaBonus]: 20,
        [STAT.maxWeightBonus]: 15,
    },
    [
    ].filter(Boolean), 4, 0.85);

export default gameBag;