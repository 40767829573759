import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const centurionArmoredExoskeleton = new Armor(ARMOR.CENTURION_ARMORED_EXOSKELETON,
    {
        [STAT.staminaBonus]: -15,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 25,
        [STAT.bulletDmgFactor]: 182,
        [STAT.tearDmgFactor]: 182,
        [STAT.explosionDmgFactor]: 251,
        [STAT.electraDmgFactor]: 71,
        [STAT.burnDmgFactor]: 71,
        [STAT.chemicalBurnDmgFactor]: 71,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 50,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 200,
        [STAT.bleedingProtection]: 66,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 2212890,
            [RESOURCE.QUANTUM_BATTERY]: 1160,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 410,
            [RESOURCE.RED_FERN]: 1290,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 800,
            [RESOURCE.SUBSTANCE_07270]: 2580,
            [RESOURCE.ANOMALOUS_SERUM]: 75,
        },
        dependency: ARMOR.WORN_CENTURION,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default centurionArmoredExoskeleton;