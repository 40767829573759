import {SPECIAL_STAT, STAT} from "../models/stats";
import {ARTIFACT} from "../data/artifact/artifact";
import {CONTAINER} from "../data/container/container";
import {COMBAT_ARMOR, COMBINED_ARMOR, DISCOVERER_ARMOR, SCIENTIST_ARMOR} from "../data/armor/armor";
import {Rarity} from "../constants/base";

const en = {
    translation: {
        navbar: {
            home: 'Home',
            calc: 'Calculator',
            craft: 'Crafting',
            linkToAnotherRegion: 'Switch region to RU',
        },
        overview: {
            title: 'Welcome to StalCraft Tools!',
            description: 'This web application is still under active development. If you have any suggestions or found a bug, please join my <discord>Discord server</discord>.',
            functionality: 'Functionality available: <calc>Artifact Calculator</calc>',
        },
        stats: {
            [STAT.healthBonus]: "Vitality",
            [STAT.staminaBonus]: "Stamina",
            [STAT.speedModifier]: "Speed",
            [STAT.maxWeightBonus]: "Carry weight",
            [STAT.regenerationBonus]: "Regeneration",
            [STAT.staminaRegenerationBonus]: "Stamina regeneration",
            [STAT.artefactHeal]: "Healing",
            [STAT.artefactDamage]: "Damage",
            [STAT.healEfficiency]: "Healing effectiveness",
            [STAT.bulletDmgFactor]: "Bullet resistance",
            [STAT.tearDmgFactor]: "Melee protection",
            [STAT.explosionDmgFactor]: "Explosion protection",
            [STAT.electraDmgFactor]: "Resistance to electricity",
            [STAT.burnDmgFactor]: "Resistance to fire",
            [STAT.chemicalBurnDmgFactor]: "Resistance to chemicals",
            [STAT.radiationDmgFactor]: "Radiation res.",
            [STAT.thermalDmgFactor]: "Thermal res.",
            [STAT.biologicalDmgFactor]: "Bioinfection res.",
            [STAT.psychoDmgFactor]: "Psy-emission res.",
            [STAT.bleedingDmgFactor]: "Bleeding res.",
            [STAT.combustionDmgFactor]: "Burning res.",
            [STAT.toxicDmgFactor]: "Poison res.",
            [STAT.frostDmgFactor]: "Frost res.",
            [STAT.radiationAccumulation]: "Radiation",
            [STAT.thermalAccumulation]: "Temperature",
            [STAT.biologicalAccumulation]: "Bioinfection",
            [STAT.psychoAccumulation]: "Psy-emissions",
            [STAT.bleedingAccumulation]: "Bleeding",
            [STAT.combustionAccumulation]: "Burning",
            [STAT.frostAccumulation]: "Frost",
            [STAT.toxicAccumulation]: "Poison",
            [STAT.radiationProtection]: "Radiation protection",
            [STAT.radiationProtectionShort]: "Rad. protection",
            [STAT.thermalProtection]: "Thermal protection",
            [STAT.biologicalProtection]: "Bioinfection Protection",
            [STAT.biologicalProtectionShort]: "Bioprotection",
            [STAT.psychoProtection]: "Psy-Emission Protection",
            [STAT.psychoProtectionShort]: "Psy-protection",
            [STAT.frostProtection]: "Frost protection",
            [STAT.bleedingProtection]: "Bleeding protection",
            [STAT.combustionProtection]: "Burning protection",
            [STAT.toxicProtection]: "Poison protection",
            [STAT.spreadBonus]: "Spread",
            [STAT.recoilBonus]: "Recoil",
            [STAT.wiggleBonus]: "Sway",
            [STAT.reloadBonus]: "Reload speed",
            [STAT.bulletDeflection]: "Bullet deflection",
            [STAT.bulletSlowdown]: "Bullet slowdown",
            [STAT.tenacity]: "Stability",
            [STAT.reactionToElectroshock]: "Reaction to electricity",
            [STAT.reactionToBurn]: "Reaction to burns",
            [STAT.reactionToChemicalBurn]: "Reaction to chemical burns",
            [STAT.reactionToTear]: "Reaction to lacerations",
            [STAT.totalHp]: "Total HP",
            [STAT.jumpIncrease]: "Jump height",
            [STAT.fallProtection]: "Fall damage reduction",
            [STAT.artefaktHeal]: "Periodic healing",
            [SPECIAL_STAT.polyhedronCooldown]: "Protection recharge",
            [SPECIAL_STAT.polyhedronBlockingDamage]: "Lethal damage protection",
        },
        artifacts: {
            [ARTIFACT.BLIZZARD_CHICKENGOD]: "Chicken God",
            [ARTIFACT.BLIZZARD_CRUST]: "Crust",
            [ARTIFACT.BLIZZARD_CRYSTAL]: "Crystal",
            [ARTIFACT.BLIZZARD_FAHRENHEIT]: "Fahrenheit",
            [ARTIFACT.BLIZZARD_FROST]: "Frost",
            [ARTIFACT.BLIZZARD_HEEL]: "Heel",
            [ARTIFACT.BLIZZARD_STORMEYE]: "Eye of the Storm",
            [ARTIFACT.BLIZZARD_VORTEX]: "Whirlwind",
            [ARTIFACT.KAROUSEL_DUSHA]: "Soul",
            [ARTIFACT.KAROUSEL_GNILEC]: "Swamp Rot",
            [ARTIFACT.KAROUSEL_JELCH]: "Stone Bile",
            [ARTIFACT.KAROUSEL_BLOODSTONE]: "Stone Blood",
            [ARTIFACT.KAROUSEL_LOMOT]: "Meat Chunk",
            [ARTIFACT.KAROUSEL_OSTOV]: "Frame",
            [ARTIFACT.KAROUSEL_PRUJINA]: "Spring",
            [ARTIFACT.ELECTRA_BATAREYKA]: "Battery",
            [ARTIFACT.ELECTRA_BENGAL]: "Sparkler",
            [ARTIFACT.ELECTRA_CRYSTAL]: "Electric Crystal",
            [ARTIFACT.ELECTRA_DEZINTEGRATOR]: "Disintegrator",
            [ARTIFACT.ELECTRA_HELIUM]: "Helium",
            [ARTIFACT.ELECTRA_MOONLIGHT]: "Moonlight",
            [ARTIFACT.ELECTRA_PUSTISHKA]: "Shell",
            [ARTIFACT.ELECTRA_SNOWFLAKE]: "Snowflake",
            [ARTIFACT.ELECTRA_VSPYSHKA]: "Flare",
            [ARTIFACT.JARKA_CRYSTAL]: "Crystal",
            [ARTIFACT.JARKA_FIREBALL]: "Fireball",
            [ARTIFACT.JARKA_GLAZ]: "Eye",
            [ARTIFACT.JARKA_KAPLYA]: "Droplet",
            [ARTIFACT.JARKA_MAMYBUSI]: "Mama’s Beads",
            [ARTIFACT.JARKA_PLAMYA]: "Flame",
            [ARTIFACT.JARKA_SOLNCE]: "Sun",
            [ARTIFACT.JARKA_VSLEZY]: "Wolf Tears",
            [ARTIFACT.KISEL_CRYSTAL]: "Acid Crystal",
            [ARTIFACT.KISEL_KISEL]: "Lollipop",
            [ARTIFACT.KISEL_PLENKA]: "Pellicle",
            [ARTIFACT.KISEL_POLYHEDRON]: "Polyhedron",
            [ARTIFACT.KISEL_PUZIR]: "Bubble",
            [ARTIFACT.KISEL_SLIZ]: "Slime",
            [ARTIFACT.KISEL_SLIZNYAK]: "Slug",
            [ARTIFACT.KISEL_SLYDA]: "Mica",
            [ARTIFACT.KISEL_SVETLYAK]: "Firefly",
            [ARTIFACT.KISEL_ULITKA]: "Snail",
            [ARTIFACT.PUH_EJ]: "Urchin",
            [ARTIFACT.PUH_KOLOBOK]: "Kolobok",
            [ARTIFACT.PUH_KOLUCHKA]: "Thorn",
            [ARTIFACT.PUH_KKOLUCHKA]: "Crystal Thorn",
            [ARTIFACT.PUH_STEELKOLOBOK]: "Steel Kolobok",
            [ARTIFACT.PUZIR_ATOM]: "Atom",
            [ARTIFACT.PUZIR_OSKOLOK]: "Shard",
            [ARTIFACT.PUZIR_PRISMA]: "Prism",
            [ARTIFACT.PUZIR_SPIRAL]: "Spiral",
            [ARTIFACT.PUZIR_ZERKALO]: "Mirror",
            // [ARTIFACT.SPECIAL_RUBIK]: "Rubik",
            [ARTIFACT.TRAMPOLINE_BRASLET]: "Bracelet",
            [ARTIFACT.TRAMPOLINE_DARKMEDUZA]: "Dark Jellyfish",
            [ARTIFACT.TRAMPOLINE_JILNIK]: "Veiner",
            [ARTIFACT.TRAMPOLINE_KAMENCVET]: "Stone Flower",
            [ARTIFACT.TRAMPOLINE_MEDUZA]: "Jellyfish",
            [ARTIFACT.TRAMPOLINE_NIGHTSTAR]: "Night Star",
            [ARTIFACT.TRAMPOLINE_PROTOMEDUZA]: "Proto-Jellyfish",
            [ARTIFACT.VORONKA_CRYSTAL]: "Black Crystal",
            [ARTIFACT.VORONKA_GOLDFISH]: "Goldfish",
            [ARTIFACT.VORONKA_GOLDGRAVI]: "Gold Gravi",
            [ARTIFACT.VORONKA_GRAVI]: "Gravi",
            [ARTIFACT.VORONKA_KRIOGEN]: "Cryogen",
            [ARTIFACT.VORONKA_VIVERT]: "Quirk",
            [ARTIFACT.VORONKA_YANTAR]: "Amberite",
        },
        containers: {
            [CONTAINER.TRANSFORMER_BAG]: 'Bag-transformer',
            [CONTAINER.SPORTS_BAG]: 'Sport bag',
            [CONTAINER.ERRAND_JUNIOR_BACKPACK]: 'Errand Junior',
            [CONTAINER.MBSS_BACKPACK]: 'MBSS',
            [CONTAINER.HELLBOY_BACKPACK]: 'Hellboy',
            [CONTAINER.REINFORCED_HELLBOY_BACKPACK]: 'Reinforced Hellboy',
            [CONTAINER.TRI_ZIP_ASSAULT_BACKPACK]: 'Tri-Zip',
            [CONTAINER.KZS_1_CONTAINER]: 'KZS-1',
            [CONTAINER.KZS_1U_CONTAINER]: 'KZS-1u',
            [CONTAINER.KZS_2]: 'KZS-2',
            [CONTAINER.KZS_2U]: 'KZS-2u',
            [CONTAINER.KZS_3]: 'KZS-3',
            [CONTAINER.KZS_3U]: 'KZS-3u',
            [CONTAINER.KZS_4]: 'KZS-4',
            [CONTAINER.KZS_4U]: 'KZS-4u',
            [CONTAINER.KZS_5]: 'KZS-5',
            [CONTAINER.KZS_5Y]: 'KZS-5u',
            [CONTAINER.BEARS_DEN_4_CONTAINER]: 'Bear`s Den-4',
            [CONTAINER.BERLOGA_4U_CONTAINER]: 'Bear`s Den-4u',
            [CONTAINER.BEARS_DEN_6_CONTAINER]: 'Bear`s Den-6',
            [CONTAINER.BERLOGA_6U_CONTAINER]: 'Bear`s Den-6u',
            [CONTAINER.COCOON_CONTAINER]: 'Cocoon',
            [CONTAINER.FORAGER_CONTAINER]: 'Forager',
            [CONTAINER.HIVE_CONTAINER]: 'Hive',
            [CONTAINER.FRIDGE_CONTAINER]: 'Fridge',
            [CONTAINER.FREEZER_CONTAINER]: 'Freezer',
            [CONTAINER.PACK_BAG]: 'Pack Bag',
            [CONTAINER.SMC_CONTAINER]: 'SMC',
            [CONTAINER.GAME_BAG]: 'Game Bag',
            [CONTAINER.NPA_BACKPACK]: 'NPA',
        },
        armors: {
            [COMBAT_ARMOR.COAT_WITH_BODY_ARMOR]: 'Coat with Body Armor',
            [COMBAT_ARMOR.REPORTERS_ARMOR]: 'Reporter\'s Armor',
            [COMBAT_ARMOR.WORN_FLEECE_PROTECTIVE_SUIT]: 'Worn Fleece Protective Suit',
            [COMBAT_ARMOR.WORN_HOPLITE_ARMORED_SUIT]: 'Worn Hoplite Armored Suit',
            [COMBAT_ARMOR.HUNTERS_CLOAK]: 'Hunter\'s Cloak',
            [COMBAT_ARMOR.FLEECE_PROTECTIVE_SUIT]: 'Fleece Protective Suit',
            [COMBAT_ARMOR.HOPLITE_ARMORED_SUIT]: 'Hoplite Armored Suit',
            [COMBAT_ARMOR.TRAPPER_SUIT]: 'Trapper Suit',
            [COMBAT_ARMOR.WORN_TRAPPER_SUIT]: 'Worn Trapper Suit',
            [COMBAT_ARMOR.WORN_STRIKER_ARMORED_SUIT]: 'Worn Striker Armored Suit.',
            [COMBAT_ARMOR.CLOAK]: 'Cloak',
            [COMBAT_ARMOR.BANDIT_CLOAK]: 'Bandit Cloak',
            [COMBAT_ARMOR.BERILL_5_M_ARMORED_SUIT]: 'Berill-5M Armored Suit',
            [COMBAT_ARMOR.WORM_ARMORED_SUIT]: 'Worm Armored Suit',
            [COMBAT_ARMOR.HEALING_BERILL]: 'Healing Berill',
            [COMBAT_ARMOR.CURRANT_GHILLIE_SUIT]: 'Currant Ghillie Suit',
            [COMBAT_ARMOR.HOUNDMASTER_PROTECTIVE_SUIT]: 'Houndmaster Protective Suit',
            [COMBAT_ARMOR.SCOUT_SUIT]: 'Scout Suit',
            [COMBAT_ARMOR.STRIKER_ARMORED_SUIT]: 'Striker Armored Suit',
            [COMBAT_ARMOR.OSH_PROTECTIVE_SUIT]: 'Osh Protective Suit',
            [COMBAT_ARMOR.JAEGER_EXOARMOR]: 'Jaeger Exoarmor',
            [COMBAT_ARMOR.LEGIONNAIRE_HEAVY_ARMORED_SUIT]: 'Legionnaire Heavy Armored Suit',
            [COMBAT_ARMOR.CENTURION_ARMORED_EXOSKELETON]: 'Centurion Armored Exoskeleton',
            [COMBAT_ARMOR.WORN_OSH_PROTECTIVE_SUIT]: 'Worn Osh Protective Suit',
            [COMBAT_ARMOR.WORN_LEGIONNAIRE]: 'Worn Legionnaire',
            [COMBAT_ARMOR.DAMAGED_JAEGER]: 'Damaged Jaeger',
            [COMBAT_ARMOR.WORN_JAEGER]: 'Worn Jaeger',
            [COMBAT_ARMOR.DAMAGED_CENTURION]: 'Damaged Centurion',
            [COMBAT_ARMOR.WORN_CENTURION]: 'Worn Centurion',
            [COMBAT_ARMOR.YORSH_PROTECTIVE_ARMORED_SUIT]: 'Yorsh Protective Armored Suit',
            [COMBAT_ARMOR.LYNX_ARMORED_SUIT]: 'Lynx Armored Suit',
            [COMBAT_ARMOR.PHANTOM_HUNTER_ARMORED_SUIT]: 'Phantom Hunter Armored Suit',
            [COMBAT_ARMOR.RATCATCHER_EXOARMOR]: 'Ratcatcher Exoarmor',
            [COMBAT_ARMOR.GHILLIE_SUIT]: 'Gillie Suit',
            [COMBAT_ARMOR.CD_1_SUIT]: 'CD-1 Suit',
            [COMBAT_ARMOR.CD_2_ARMORED_SUIT]: 'CD-2 Armored Suit',
            [COMBAT_ARMOR.CD_3_A_ARMORED_SUIT]: 'CD-3a Armored Suit',
            [COMBAT_ARMOR.ALTYN_SET]: 'Altyn Set',
            [COMBAT_ARMOR.CD_3_B_ARMORED_SUIT]: 'CD-3b Armored Suit',
            [COMBAT_ARMOR.SHADE_HUNTER_HEAVY_ARMORED_SUIT]: 'Shade Hunter Heavy Armored Suit',
            [COMBAT_ARMOR.ALTYN_T_HEAVY_SET]: 'Altyn-T Heavy Set',
            [COMBAT_ARMOR.BLIZZARD_BOUND_HEAVY_ARMORED_SUIT]: 'Blizzard-Bound Heavy Armored Suit',
            [COMBAT_ARMOR.MODIFIED_EXOSKELETON]: 'Modified Exoskeleton',
            [COMBAT_ARMOR.SBA_TANK]: 'SBA TANK',
            [COMBAT_ARMOR.CD_4_ARMORED_SUIT]: 'CD-4 Armored Suit',
            [COMBAT_ARMOR.BEAST_SLAYER_EXOARMOR]: 'Beast Slayer Exoarmor',
            [COMBAT_ARMOR.WORN_BEAST_SLAYER]: 'Worn Beast Slayer',
            [COMBAT_ARMOR.APOSTLE_HEAVY_ARMORED_SUIT]: 'Apostle Heavy Armored Suit',
            [COMBAT_ARMOR.VANGUARD_ARMORED_SUIT]: 'Vanguard Armored Suit',
            [COMBAT_ARMOR.REITER_SUPER_HEAVY_ARMORED_SUIT]: 'Reiter Super-Heavy Armored Suit',
            [COMBAT_ARMOR.GRANITE_SUPER_HEAVY_ARMORED_SUIT]: 'Granite Super-Heavy Armored Suit',
            [COMBAT_ARMOR.CHIEFTAIN_SUPER_HEAVY_ARMORED_SUIT]: 'Chieftain Super-Heavy Armored Suit',


            [SCIENTIST_ARMOR.RAINCOAT]: 'Raincoat',
            [SCIENTIST_ARMOR.IP_4_M]: 'IP-4M',
            [SCIENTIST_ARMOR.ZARYA_B_SUIT]: 'Zarya-B Suit',
            [SCIENTIST_ARMOR.BANDIT_SUIT_WITH_GAS_TANKS]: 'Bandit Suit with Gas Tanks',
            [SCIENTIST_ARMOR.SEVA_SUIT]: 'SEVA Suit',
            [SCIENTIST_ARMOR.REAPER_SUIT]: 'REAPER Suit',
            [SCIENTIST_ARMOR.MIS_99_AMBER]: 'MIS-99 Amber',
            [SCIENTIST_ARMOR.MIS_99_MALACHITE]: 'MIS-99 Malachite',
            [SCIENTIST_ARMOR.MIS_105_TOPAZ]: 'MIS-105 Topaz',
            [SCIENTIST_ARMOR.MIS_113_IOLITE]: 'MIS-113 Iolite',
            [SCIENTIST_ARMOR.MIS_116_EMERALD]: 'MIS-116 Emerald',
            [SCIENTIST_ARMOR.MIS_122_AMETHYST]: 'MIS-122 Amethyst',
            [SCIENTIST_ARMOR.MIS_X_ATLAS]: 'MIS-X ATLAS',
            [SCIENTIST_ARMOR.WORN_MIS_99_AMBER]: 'Worn MIS-99 Amber',
            [SCIENTIST_ARMOR.WORN_MIS_99_M_MALACHITE]: 'Worn MIS-99M Malachite',
            [SCIENTIST_ARMOR.WORN_SEVA_SUIT]: 'Worn SEVA Suit',
            [SCIENTIST_ARMOR.WORN_REAPER_SUIT]: 'Worn REAPER Suit',
            [SCIENTIST_ARMOR.WORN_TOPAZ]: 'Worn Topaz',
            [SCIENTIST_ARMOR.WORN_IOLITE]: 'Worn Iolite',
            [SCIENTIST_ARMOR.WORN_EMERALD]: 'Worn Emerald',
            [SCIENTIST_ARMOR.WORN_AMETHYST]: 'Worn Amethyst',
            [SCIENTIST_ARMOR.MIS_85_D]: 'MIS-85D',
            [SCIENTIST_ARMOR.IMPROVED_HAZMAT_SUIT]: 'Improved Hazmat Suit',
            [SCIENTIST_ARMOR.MIS_100_GARNET]: 'MIS-100 Garnet',
            [SCIENTIST_ARMOR.MIS_102_ZIRCON]: 'MIS-102 Zircon',
            [SCIENTIST_ARMOR.SATURN_SUIT]: 'Saturn Suit',
            [SCIENTIST_ARMOR.WORN_SATURN_SUIT]: 'Worn Saturn Suit',
            [SCIENTIST_ARMOR.DAMAGED_SATURN_SUIT]: 'Damaged Saturn Suit',

            [DISCOVERER_ARMOR.GORKA_3_SUIT]: 'Gorka-3 Suit',
            [DISCOVERER_ARMOR.WORN_HAWK_ARMORED_SUIT]: 'Worn Hawk Armored Suit',
            [DISCOVERER_ARMOR.WORN_MOCKINGBIRD_ARMORED_SUIT]: 'Worn Mockingbird Armored Suit',
            [DISCOVERER_ARMOR.ZARYA_SUIT]: 'Zarya Suit',
            [DISCOVERER_ARMOR.ZARYA_SUIT_WITH_GAS_MASK]: 'Zarya Suit with Gas Mask',
            [DISCOVERER_ARMOR.TOURIST_SUIT]: 'Tourist Suit',
            [DISCOVERER_ARMOR.BANDIT_SUIT]: 'Bandit Suit',
            [DISCOVERER_ARMOR.BANDIT_SUIT_WITH_GAS_MASK]: 'Bandit Suit with Gas Mask',
            [DISCOVERER_ARMOR.KLEPTOMANIAC_SUIT]: 'Kleptomaniac Suit',
            [DISCOVERER_ARMOR.VOSHOD_HEAVY_ARMORED_SUIT]: 'Voshod Heavy Armored Suit',
            [DISCOVERER_ARMOR.THUG_HEAVY_ARMORED_SUIT]: 'Thug Heavy Armored Suit',
            [DISCOVERER_ARMOR.HAWK_ARMORED_SUIT]: 'Hawk Armored Suit',
            [DISCOVERER_ARMOR.MOCKINGBIRD_ARMORED_SUIT]: 'Mockingbird Armored Suit',
            [DISCOVERER_ARMOR.SKAT_9_B_ARMORED_SUIT]: 'SKAT-9b Armored Suit',
            [DISCOVERER_ARMOR.EXOSKELETON]: 'Exoskeleton',
            [DISCOVERER_ARMOR.TRUMP_EXOSKELETON]: 'Trump Exoskeleton',
            [DISCOVERER_ARMOR.PSZ_10_ARMORED_SUIT]: 'PSZ-10 Armored Suit',
            [DISCOVERER_ARMOR.KINGPIN_ARMORED_SUIT]: 'Kingpin Armored Suit',
            [DISCOVERER_ARMOR.SKAT_9_M_ARMORED_SUIT]: 'SKAT-9m Armored Suit',
            [DISCOVERER_ARMOR.WORN_VOSHOD_HEAVY_ARMORED_SUIT]: 'Worn Voshod Heavy Armored Suit',
            [DISCOVERER_ARMOR.WORN_THUG_HEAVY_ARMORED_SUIT]: 'Worn Thug Heavy Armored Suit',
            [DISCOVERER_ARMOR.WORN_PSZ_10]: 'Worn PSZ-10',
            [DISCOVERER_ARMOR.WORN_KINGPIN]: 'Worn Kingpin',
            [DISCOVERER_ARMOR.WORN_EXOSKELETON]: 'Worn Exoskeleton',
            [DISCOVERER_ARMOR.WORN_TRUMP]: 'Worn Trump',
            [DISCOVERER_ARMOR.WORN_SKAT_9_M]: 'Worn SKAT-9m',
            [DISCOVERER_ARMOR.DAMAGED_SKAT_9_M]: 'Damaged SKAT-9m',
            [DISCOVERER_ARMOR.SKAT_10_ARMORED_SUIT]: 'SKAT-10 Armored Suit',
            [DISCOVERER_ARMOR.WORN_TOURIST]: 'Worn Tourist',
            [DISCOVERER_ARMOR.WORN_KLEPTOMANIAC]: 'Worn Kleptomaniac',
            [DISCOVERER_ARMOR.BRIGAND_ARMORED_SUIT]: 'Brigand Armored Suit',
            [DISCOVERER_ARMOR.GRENADIER_EXOSKELETON]: 'Grenadier Exoskeleton',
            [DISCOVERER_ARMOR.MULE_EXOSKELETON]: 'Mule Exoskeleton',
            [DISCOVERER_ARMOR.ACE_EXOSKELETON]: 'Ace Exoskeleton',
            [DISCOVERER_ARMOR.WORN_MULE]: 'Worn Mule',
            [DISCOVERER_ARMOR.WORN_ACE]: 'Worn Ace',
            [DISCOVERER_ARMOR.PUNISHER_ARMORED_SUIT]: 'Punisher Armored Suit',

            [COMBINED_ARMOR.WORN_AH_2_NOMAD]: 'Worn AH-2 Nomad',
            [COMBINED_ARMOR.DAMAGED_AH_2_NOMAD]: 'Damaged AH-2 Nomad',
            [COMBINED_ARMOR.AH_1_DRIFTER_SUIT]: 'AH-1 Drifter Suit',
            [COMBINED_ARMOR.PS_1_PICKLOCK]: 'PS-1 Picklock',
            [COMBINED_ARMOR.AH_2_NOMAD_SUIT]: 'AH-2 Nomad Suit',
            [COMBINED_ARMOR.AH_3_SEEKER_SUIT]: 'AH-3 Seeker Suit',
            [COMBINED_ARMOR.RAPS_AH_4_RAIDER]: 'RAPS AH-4 Raider',
            [COMBINED_ARMOR.RAPS_AH_5_PILGRIM]: 'RAPS AH-5 Pilgrim',
            [COMBINED_ARMOR.DAMAGED_AH_3_SEEKER_SUIT]: 'Damaged AH-3 Seeker Suit',
            [COMBINED_ARMOR.WORN_AH_3_SEEKER_SUIT]: 'Worn AH-3 Seeker Suit',
            [COMBINED_ARMOR.WORN_AH_4_RAIDER]: 'Worn AH-4 Raider',
            [COMBINED_ARMOR.WORN_AH_5_PILGRIM]: 'Worn AH-5 Pilgrim',
            [COMBINED_ARMOR.RAPS_SPANNER]: 'RAPS Spanner',
            [COMBINED_ARMOR.EXPEDITION_SUIT]: 'Expedition Suit',
            [COMBINED_ARMOR.MAGNET_SUIT]: 'Magnet Suit',
            [COMBINED_ARMOR.TONGA_SUIT]: 'Tonga Suit',
            [COMBINED_ARMOR.ALBATROSS_SCOUT_ARMORED_EXOSKELETON]: 'Albatross Scout Armored Exoskeleton',
            [COMBINED_ARMOR.JD_ZIVCAS_2_A_EXOARMOR]: 'JD ZIVCAS 2A Exoarmor',
            [COMBINED_ARMOR.ALBATROSS_INFILTRATOR_ARMORED_EXOSKELETON]: 'Albatross Infiltrator Armored Exoskeleton',
            [COMBINED_ARMOR.ALBATROSS_HEAVY_ASSAULT_ARMORED_EXOSKELETON]: 'Albatross Heavy Assault Armored Exoskeleton',
            [COMBINED_ARMOR.RIGEL_SUIT]: 'Rigel Suit',
            [COMBINED_ARMOR.RAPS_AH_6_NOMAD]: 'RAPS AH-6 Nomad',
            [COMBINED_ARMOR.WORN_AH_6_NOMAD]: 'Worn AH-6 Nomad',
            [COMBINED_ARMOR.HOUNDS_EXOSKELETON]: 'Hounds Exoskeleton',
        },
        rarities: {
            [Rarity.COMMON]: 'Common',
            [Rarity.UNCOMMON]: 'Uncommon',
            [Rarity.RARE]: 'Special',
            [Rarity.EPIC]: 'Rare',
            [Rarity.EXCEPTIONAL]: 'Exclusive',
            [Rarity.LEGENDARY]: 'Legendary',
            [Rarity.UNIQUE]: 'Unique',
        },
        artifact: {
            title: 'Artifacts',
            copy: 'Copy to',
            delete: 'Delete',
            quality: '%',
            level: 'Level',
            search: {
                statPlaceholder: 'Search by stat...',
                positive: 'Positive',
                negative: 'Negative',
                noResults: 'No artifacts found',
                noArtifactSelected: 'Select...',
                index: '#{{index}}',
                selectedArtifact: '{{name}} | +{{level}} {{quality}}%',
            }
        },
        container: {
            title: 'Container',
            search: {
                placeholder: 'Search by name...',
                selected: '{{container}}, {{slots}} slots, {{protection}}%',
            }
        },
        armor: {
            title: 'Armor',
            search: {

                placeholder: 'Search by name...',
            }
        },
        filterDropdown: {
            inputPlaceholder: 'Type to filter...',
        },
        calculator: {
            stat: {
                flat: '{{value}}',
                percent: '{{value}}%',
                polyhedron: 'Preserve {{polyhedronBlockingDamage}} damage every {{polyhedronCooldown}} seconds',
                includeHuman: 'Include human',
            }
        },
        loadout: {
            save: 'Save',
            load: 'Load',
            delete: 'Delete',
            new: 'New',
            loadoutName: 'Loadout name',
        },
        stat: {
            title: 'Stats total'
        }
    }
};

export default en;