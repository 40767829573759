import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const puhKkoluchka = new Artifact(ARTIFACT.PUH_KKOLUCHKA, {
    [STAT.radiationAccumulation]: [3, 6],
    [STAT.bleedingProtection]: [-25, -50],
}, {
    [STAT.reactionToElectroshock]: [1, 2],
    [STAT.radiationAccumulation]: [0.75, 1.5],
    [STAT.bleedingProtection]: [10, 20],
});

export default puhKkoluchka;