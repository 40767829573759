import CraftNavbar from "./craft-navbar";
import {Route, Routes} from "react-router-dom";
import React from "react";


const Craft = () => {
    return (
        <div>
            <CraftNavbar/>
            <Routes>
                <Route path="" element={<h1>Craft</h1>}/>
                <Route path="/armor" element={<h1>Armor</h1>}/>
                <Route path="/weapon" element={<h1>Weapon</h1>}/>
            </Routes>

        </div>
    )
}

export default Craft;