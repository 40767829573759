import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const mis105Topaz = new Armor(ARMOR.MIS_105_TOPAZ,
    {
        [STAT.bulletDmgFactor]: 49,
        [STAT.tearDmgFactor]: 55,
        [STAT.explosionDmgFactor]: 67,
        [STAT.electraDmgFactor]: 333,
        [STAT.burnDmgFactor]: 1333,
        [STAT.chemicalBurnDmgFactor]: 778,
        [STAT.radiationProtection]: 310,
        [STAT.thermalProtection]: 400,
        [STAT.biologicalProtection]: 400,
        [STAT.psychoProtection]: 310,
        [STAT.bleedingProtection]: 35,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 185740,
            [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 830,
            [RESOURCE.ZONSHROOM]: 860,
            [RESOURCE.BETA_DATA_FRAGMENT]: 310,
            [RESOURCE.CHLORINEX]: 980,
        },
    },
    {
        resources: {
            [RESOURCE.RUB]: 60350,
            [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 490,
            [RESOURCE.ZONSHROOM]: 500,
            [RESOURCE.BETA_DATA_FRAGMENT]: 181,
            [RESOURCE.CHLORINEX]: 570,
        },
        dependency: ARMOR.MIS_99_MALACHITE,
    },
    {
        resources: {
            [RESOURCE.RUB]: 45320,
            [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 126,
            [RESOURCE.ZONSHROOM]: 131,
            [RESOURCE.BETA_DATA_FRAGMENT]: 48,
            [RESOURCE.CHLORINEX]: 149,
        },
        dependency: ARMOR.WORN_TOPAZ,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default mis105Topaz;