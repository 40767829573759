import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornCenturion = new Armor(ARMOR.WORN_CENTURION,
    {
        [STAT.staminaBonus]: -15,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 25,
        [STAT.bulletDmgFactor]: 167,
        [STAT.tearDmgFactor]: 167,
        [STAT.explosionDmgFactor]: 230,
        [STAT.electraDmgFactor]: 65,
        [STAT.burnDmgFactor]: 65,
        [STAT.chemicalBurnDmgFactor]: 65,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 40,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 180,
        [STAT.bleedingProtection]: 63,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 1236380,
            [RESOURCE.QUANTUM_BATTERY]: 650,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 229,
            [RESOURCE.RED_FERN]: 720,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 450,
            [RESOURCE.SUBSTANCE_07270]: 1440,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.DAMAGED_CENTURION,
    },
    {
        resources: {
            [RESOURCE.RUB]: 1236380,
            [RESOURCE.ANOMALOUS_BATTERY]: 38,
            [RESOURCE.LIMBOPLASMA]: 12,
            [RESOURCE.BITTERLEAF]: 590,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 200,
            [RESOURCE.LIM]: 940,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.DAMAGED_CENTURION,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default wornCenturion;