import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornThugHeavyArmoredSuit = new Armor(ARMOR.WORN_THUG_HEAVY_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 93,
        [STAT.tearDmgFactor]: 102,
        [STAT.explosionDmgFactor]: 133,
        [STAT.electraDmgFactor]: 62,
        [STAT.burnDmgFactor]: 62,
        [STAT.chemicalBurnDmgFactor]: 62,
        [STAT.radiationProtection]: 180,
        [STAT.thermalProtection]: 60,
        [STAT.biologicalProtection]: 150,
        [STAT.psychoProtection]: 105,
        [STAT.bleedingProtection]: 50,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 47470,
            [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 138,
            [RESOURCE.BLUE_YEAST]: 294,
            [RESOURCE.ALPHA_DATA_FRAGMENT]: 37,
            [RESOURCE.PICKLE]: 116,
        },
        dependency: ARMOR.WORN_MOCKINGBIRD_ARMORED_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 47470,
            [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 138,
            [RESOURCE.BLUE_YEAST]: 294,
            [RESOURCE.ALPHA_DATA_FRAGMENT]: 37,
            [RESOURCE.PICKLE]: 116,
        },
        dependency: ARMOR.WORN_HAWK_ARMORED_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default wornThugHeavyArmoredSuit;