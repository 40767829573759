import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const puzirPrisma = new Artifact(ARTIFACT.PUZIR_PRISMA, {
    [STAT.bulletDmgFactor]: [15, 20],
    [STAT.bleedingProtection]: [-25, -50],
}, {
    [STAT.bulletDmgFactor]: [3.5, 5],
    [STAT.explosionDmgFactor]: [15, 20],
    [STAT.bleedingProtection]: [10, 20],
});

export default puzirPrisma;