import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const puhSteelkolobok = new Artifact(ARTIFACT.PUH_STEELKOLOBOK, {
    [STAT.staminaBonus]: [10, 20],
    [STAT.psychoAccumulation]: [-4, -6],
    [STAT.tearDmgFactor]: [15, 25],
}, {
    [STAT.tearDmgFactor]: [3.5, 6.5],
    [STAT.psychoAccumulation]: [1, 1.5],
    [STAT.bleedingProtection]: [5, 15],
});

export default puhSteelkolobok;