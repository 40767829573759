import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {STAT} from "../../../models/stats";

const kzs5y = new Container(
    CONTAINER.KZS_5Y,
    {
        [STAT.speedModifier]: -3,
    },
    [
    ].filter(Boolean), 5, 0.75);

export default kzs5y;