import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const shadeHunterHeavyArmoredSuit = new Armor(ARMOR.SHADE_HUNTER_HEAVY_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -20,
        [STAT.speedModifier]: -2,
        [STAT.maxWeightBonus]: 4,
        [STAT.bulletDmgFactor]: 124,
        [STAT.tearDmgFactor]: 133,
        [STAT.explosionDmgFactor]: 190,
        [STAT.electraDmgFactor]: 127,
        [STAT.burnDmgFactor]: 127,
        [STAT.chemicalBurnDmgFactor]: 127,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 60,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 135,
        [STAT.bleedingProtection]: 64,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default shadeHunterHeavyArmoredSuit;