import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const hawkArmoredSuit = new Armor(ARMOR.HAWK_ARMORED_SUIT,
    {
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 83,
        [STAT.tearDmgFactor]: 89,
        [STAT.explosionDmgFactor]: 120,
        [STAT.electraDmgFactor]: 104,
        [STAT.burnDmgFactor]: 104,
        [STAT.chemicalBurnDmgFactor]: 104,
        [STAT.radiationProtection]: 180,
        [STAT.thermalProtection]: 90,
        [STAT.biologicalProtection]: 180,
        [STAT.psychoProtection]: 140,
        [STAT.bleedingProtection]: 47,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 55160,
            [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 161,
            [RESOURCE.BLUE_YEAST]: 285,
            [RESOURCE.ALPHA_DATA_FRAGMENT]: 71,
            [RESOURCE.PICKLE]: 112,
        },
        dependency: ARMOR.WORN_HAWK_ARMORED_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 55160,
            [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 161,
            [RESOURCE.BLUE_YEAST]: 285,
            [RESOURCE.ALPHA_DATA_FRAGMENT]: 71,
            [RESOURCE.PICKLE]: 112,
        },
        dependency: ARMOR.WORN_MOCKINGBIRD_ARMORED_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default hawkArmoredSuit;