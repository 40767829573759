import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const electraSnowflake = new Artifact(ARTIFACT.ELECTRA_SNOWFLAKE, {
    [STAT.speedModifier]: [1, 3],
    [STAT.staminaRegenerationBonus]: [1, 3],
    [STAT.biologicalAccumulation]: [-1, -3],
}, {
    [STAT.speedModifier]: [0.5, 1.5],
    [STAT.thermalAccumulation]: [1, 2],
    [STAT.biologicalAccumulation]: [0.25, 0.75],
});

export default electraSnowflake;