import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import './App.css';
import Navbar from "./components/navbar";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Overview from "./components/overview";
import Craft from "./components/craft";
import CalculatorComponent from "./components/calculator/calculator";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Navbar/>
            <Routes>
                <Route path="/" Component={Overview}/>
                <Route path="/craft/*" Component={Craft}/>
                <Route path="/calc" Component={CalculatorComponent}/>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
