import {THERMAL_ARTIFACT} from "../artifact";
import Artifact from "../../../models/artifact";
import jarkaGlaz from "./jarka-glaz";
import jarkaCrystal from "./jarka-crystal";
import jarkaFireball from "./jarka-fireball";
import jarkaKaplya from "./jarka-kaplya";
import jarkaVslezy from "./jarka-vslezy";
import jarkaMamybusi from "./jarka-mamybusi";
import jarkaPlamya from "./jarka-plamya";
import jarkaSolnce from "./jarka-solnce";
import blizzardFrost from "./blizzard-frost";
import blizzardCrystal from "./blizzard-crystal";
import blizzardCrust from "./blizzard-crust";
import blizzardVortex from "./blizzard-vortex";
import blizzardFahrenheit from "./blizzard-fahrenheit";
import blizzardHeel from "./blizzard-heel";
import blizzardChickengod from "./blizzard-chickengod";
import blizzardStormeye from "./blizzard-stormeye";

const ThermalArtifacts: Record<THERMAL_ARTIFACT, Artifact> = {
    [THERMAL_ARTIFACT.JARKA_GLAZ]: jarkaGlaz,
    [THERMAL_ARTIFACT.JARKA_CRYSTAL]: jarkaCrystal,
    [THERMAL_ARTIFACT.JARKA_FIREBALL]: jarkaFireball,
    [THERMAL_ARTIFACT.JARKA_KAPLYA]: jarkaKaplya,
    [THERMAL_ARTIFACT.JARKA_VSLEZY]: jarkaVslezy,
    [THERMAL_ARTIFACT.JARKA_MAMYBUSI]: jarkaMamybusi,
    [THERMAL_ARTIFACT.JARKA_PLAMYA]: jarkaPlamya,
    [THERMAL_ARTIFACT.JARKA_SOLNCE]: jarkaSolnce,
    [THERMAL_ARTIFACT.BLIZZARD_FROST]: blizzardFrost,
    [THERMAL_ARTIFACT.BLIZZARD_CRYSTAL]: blizzardCrystal,
    [THERMAL_ARTIFACT.BLIZZARD_CRUST]: blizzardCrust,
    [THERMAL_ARTIFACT.BLIZZARD_VORTEX]: blizzardVortex,
    [THERMAL_ARTIFACT.BLIZZARD_FAHRENHEIT]: blizzardFahrenheit,
    [THERMAL_ARTIFACT.BLIZZARD_HEEL]: blizzardHeel,
    [THERMAL_ARTIFACT.BLIZZARD_CHICKENGOD]: blizzardChickengod,
    [THERMAL_ARTIFACT.BLIZZARD_STORMEYE]: blizzardStormeye
}

export default ThermalArtifacts;