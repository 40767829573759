import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const zaryaSuitWithGasMask = new Armor(ARMOR.ZARYA_SUIT_WITH_GAS_MASK,
    {
        [STAT.staminaBonus]: -4,
        [STAT.maxWeightBonus]: 5,
        [STAT.bulletDmgFactor]: 37,
        [STAT.tearDmgFactor]: 40,
        [STAT.explosionDmgFactor]: 57,
        [STAT.electraDmgFactor]: 27,
        [STAT.burnDmgFactor]: 27,
        [STAT.chemicalBurnDmgFactor]: 27,
        [STAT.radiationProtection]: 90,
        [STAT.thermalProtection]: 30,
        [STAT.biologicalProtection]: 120,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 30,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 5580,
            [RESOURCE.STINKY_ROOT]: 26,
            [RESOURCE.SEEDS]: 20,
        },
    },
    {
        resources: {
            [RESOURCE.RUB]: 1770,
            [RESOURCE.STINKY_ROOT]: 8,
            [RESOURCE.SEEDS]: 7,
        },
        dependency: ARMOR.ZARYA_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 1770,
            [RESOURCE.STINKY_ROOT]: 8,
            [RESOURCE.SEEDS]: 7,
        },
        dependency: ARMOR.BANDIT_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default zaryaSuitWithGasMask;