import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornExoskeleton = new Armor(ARMOR.WORN_EXOSKELETON,
    {
        [STAT.staminaBonus]: -16,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 25,
        [STAT.bulletDmgFactor]: 118,
        [STAT.tearDmgFactor]: 120,
        [STAT.explosionDmgFactor]: 171,
        [STAT.electraDmgFactor]: 80,
        [STAT.burnDmgFactor]: 80,
        [STAT.chemicalBurnDmgFactor]: 80,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 60,
        [STAT.biologicalProtection]: 150,
        [STAT.psychoProtection]: 140,
        [STAT.bleedingProtection]: 56,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 575310,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 203,
            [RESOURCE.RED_FERN]: 283,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 295,
            [RESOURCE.SUBSTANCE_07270]: 850,
        },
        dependency: ARMOR.VOSHOD_HEAVY_ARMORED_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 575310,
            [RESOURCE.LIMBOPLASMA]: 5,
            [RESOURCE.BITTERLEAF]: 350,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 59,
            [RESOURCE.LIM]: 560,
        },
        dependency: ARMOR.VOSHOD_HEAVY_ARMORED_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 575310,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 203,
            [RESOURCE.RED_FERN]: 283,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 295,
            [RESOURCE.SUBSTANCE_07270]: 850,
        },
        dependency: ARMOR.THUG_HEAVY_ARMORED_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_STANDARD_CONTAINER,
)

export default wornExoskeleton;