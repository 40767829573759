import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {STAT} from "../../../models/stats";

const berloga4uContainer = new Container(
    CONTAINER.BERLOGA_4U_CONTAINER,
    {
        [STAT.speedModifier]: -1.5,
    },
    [
    ].filter(Boolean), 4, 0.6);

export default berloga4uContainer;