import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornAh2Nomad = new Armor(ARMOR.WORN_AH_2_NOMAD,
    {
        [STAT.staminaBonus]: -5,
        [STAT.artefaktHeal]: 1.25,
        [STAT.bulletDmgFactor]: 35,
        [STAT.tearDmgFactor]: 41,
        [STAT.explosionDmgFactor]: 50,
        [STAT.electraDmgFactor]: 237,
        [STAT.burnDmgFactor]: 237,
        [STAT.chemicalBurnDmgFactor]: 237,
        [STAT.radiationProtection]: 105,
        [STAT.thermalProtection]: 105,
        [STAT.biologicalProtection]: 105,
        [STAT.psychoProtection]: 60,
        [STAT.bleedingProtection]: 26,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 4540,
            [RESOURCE.PIECES_OF_ALUMINUM_CABLE]: 28,
            [RESOURCE.STINKY_ROOT]: 22,
            [RESOURCE.SEEDS]: 15,
        },
        dependency: ARMOR.AH_1_DRIFTER_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 2670,
            [RESOURCE.PIECES_OF_ALUMINUM_CABLE]: 17,
            [RESOURCE.STINKY_ROOT]: 13,
            [RESOURCE.SEEDS]: 9,
        },
        dependency: ARMOR.DAMAGED_AH_2_NOMAD,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default wornAh2Nomad;