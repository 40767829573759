import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const zaryaSuit = new Armor(ARMOR.ZARYA_SUIT,
    {
        [STAT.staminaBonus]: -2,
        [STAT.maxWeightBonus]: 5,
        [STAT.bulletDmgFactor]: 31,
        [STAT.tearDmgFactor]: 35,
        [STAT.explosionDmgFactor]: 48,
        [STAT.electraDmgFactor]: 22,
        [STAT.burnDmgFactor]: 22,
        [STAT.chemicalBurnDmgFactor]: 22,
        [STAT.radiationProtection]: 60,
        [STAT.thermalProtection]: 0,
        [STAT.biologicalProtection]: 60,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 26,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 2680,
            [RESOURCE.GREEN_MOLD]: 18,
            [RESOURCE.SWAMP_STONE]: 10,
        },
        dependency: ARMOR.GORKA_3_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default zaryaSuit;