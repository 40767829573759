import Artifact from "../../../models/artifact";
import {BIOCHEMICAL_ARTIFACT} from "../artifact";
import {SPECIAL_STAT, STAT} from "../../../models/stats";

const kiselPolyhedron = new Artifact(BIOCHEMICAL_ARTIFACT.KISEL_POLYHEDRON, {
    [STAT.radiationAccumulation]: [-3, -4],
    [STAT.speedModifier]: [-5, -10],
}, {
    [STAT.radiationAccumulation]: [0.5, 1.5],
    [STAT.biologicalAccumulation]: [0.5, 1.5],
    [STAT.speedModifier]: [4, 6],
}, {
    polyhedron: {
        [SPECIAL_STAT.polyhedronBlockingDamage]: [50, 62],
        [SPECIAL_STAT.polyhedronCooldown]: [30, 18],
    }
});

export default kiselPolyhedron;