import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const jdZivcas2aExoarmor = new Armor(ARMOR.JD_ZIVCAS_2_A_EXOARMOR,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 25,
        [STAT.artefaktHeal]: 4,
        [STAT.bulletDmgFactor]: 102,
        [STAT.tearDmgFactor]: 116,
        [STAT.explosionDmgFactor]: 183,
        [STAT.electraDmgFactor]: 533,
        [STAT.burnDmgFactor]: 533,
        [STAT.chemicalBurnDmgFactor]: 1244,
        [STAT.radiationProtection]: 240,
        [STAT.thermalProtection]: 240,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 240,
        [STAT.bleedingProtection]: 55,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_SPACIOUS_CONTAINER,
)

export default jdZivcas2aExoarmor;