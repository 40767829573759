import {ArtifactData} from "../../../../models/artifact";
import {Button, Card, Container, Stack} from "react-bootstrap";
import ArtifactQualityInput from "./artifact-quality-input";
import ArtifactAdditionalStats from "./artifact-additional-stats";
import ArtifactRaritySelector from "./artifact-rarity-selector";
import ArtifactCopy from "./artifact-copy";
import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {emptyArtifactFactory} from "../../../../data/artifact/artifact";
import StatsComponent from "../../stats/stats-component";
import AllArtifacts from "../../../../data/artifact";
import {ArtifactLevelInput} from "../../level-input";

type ArtifactEditProps = {
    artifact: ArtifactData,
    setArtifactFactory: (index: number) => (artifact: ArtifactData) => void,
    slots: number,
    index: number
}

export const ARTIFACT_EDIT_TEST_ID = 'artifact-edit';
export const ARTIFACT_EDIT_DELETE_TEST_ID = 'artifact-edit-delete';

const ArtifactEdit: React.FC<ArtifactEditProps> = ({ artifact, setArtifactFactory, slots, index }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setArtifact = useCallback(setArtifactFactory(index), [index, setArtifactFactory]);
    const clearArtifact = useCallback(() => setArtifact(emptyArtifactFactory()), [setArtifact]);
    const {t} = useTranslation();

    const [stats, specialStats] = useMemo(() => {
        const art = AllArtifacts[artifact.id];
        return [
            art.getStats(artifact.quality, artifact.level, artifact.rarity, artifact.additionalStats),
            art.getSpecialStats(artifact.quality, artifact.level)
        ];
    }, [artifact])

    return <Card className={'border-0'} data-testid={ARTIFACT_EDIT_TEST_ID}>
        <Card.Body>
            <Stack direction='horizontal' gap={2}>
                <ArtifactQualityInput artifact={artifact} setArtifact={setArtifact}/>
                <ArtifactLevelInput value={artifact} onChange={setArtifact}/>
            </Stack>
            <ArtifactAdditionalStats artifact={artifact} setArtifact={setArtifact} index={index}/>
            <StatsComponent stats={stats} specialStats={specialStats}/>
            <Container className={'d-flex justify-content-end px-0'}>
                <Stack gap={2} direction='horizontal' className={'flex-wrap'}>
                    <ArtifactRaritySelector artifact={artifact} setArtifact={setArtifact} index={index}/>
                    <ArtifactCopy artifact={artifact} setArtifactFactory={setArtifactFactory} slots={slots}
                                  index={index}/>
                    <Button
                        onClick={clearArtifact}
                        variant={'danger'}
                        data-testid={ARTIFACT_EDIT_DELETE_TEST_ID}
                    >
                        {t('artifact.delete')}
                    </Button>
                </Stack>
            </Container>
        </Card.Body>
    </Card>
};

export default ArtifactEdit;