import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const karouselPrujina = new Artifact(ARTIFACT.KAROUSEL_PRUJINA, {
    [STAT.staminaBonus]: [-5, -15],
    [STAT.reactionToTear]: [1, 2],
    [STAT.radiationAccumulation]: [-3, -5],
    [STAT.tearDmgFactor]: [10, 20],
}, {
    [STAT.tearDmgFactor]: [2.5, 5],
    [STAT.radiationAccumulation]: [0.75, 1.25],
    [STAT.staminaBonus]: [2, 6],
});

export default karouselPrujina;