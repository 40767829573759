import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const electraCrystal = new Artifact(ARTIFACT.ELECTRA_CRYSTAL, {
    [STAT.regenerationBonus]: [-0.5, -1],
    [STAT.reactionToElectroshock]: [1, 3],
    [STAT.psychoAccumulation]: [1, 2],
}, {
    [STAT.reactionToElectroshock]: [0.5, 1.5],
    [STAT.electraDmgFactor]: [5, 10],
    [STAT.regenerationBonus]: [0.25, 0.5],
});

export default electraCrystal;