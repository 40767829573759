import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const mis100Garnet = new Armor(ARMOR.MIS_100_GARNET,
    {
        [STAT.artefaktHeal]: 5,
        [STAT.radiationAccumulation]: 2,
        [STAT.bleedingAccumulation]: 1,
        [STAT.bulletDmgFactor]: 49,
        [STAT.tearDmgFactor]: 55,
        [STAT.explosionDmgFactor]: 67,
        [STAT.electraDmgFactor]: 601,
        [STAT.burnDmgFactor]: 601,
        [STAT.chemicalBurnDmgFactor]: 601,
        [STAT.radiationProtection]: 210,
        [STAT.thermalProtection]: 210,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 180,
        [STAT.bleedingProtection]: 35,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default mis100Garnet;