import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const kiselKisel = new Artifact(ARTIFACT.KISEL_KISEL, {
    [STAT.radiationAccumulation]: [3, 6],
    [STAT.biologicalAccumulation]: [-5, -15],
}, {
    [STAT.reactionToTear]: [3, 5],
    [STAT.radiationAccumulation]: [0.75, 1.5],
    [STAT.biologicalAccumulation]: [1.25, 3.75],
});

export default kiselKisel;