import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ArtifactData} from "../../models/artifact";
import {emptyArtifactFactory} from "../../data/artifact/artifact";
import ArtifactList from "./artifact/artifact-list";
import {Button, Card, Col, Container, FloatingLabel, Form, Row, Stack} from "react-bootstrap";
import AllContainers from "../../data/container";
import Calculator from "../../models/calculator";
import StatsComponent from "./stats/stats-component";
import SearchDropdown from "../common/search-dropdown";
import {
    deleteLoadout,
    emptyLoadoutFactory,
    getSortedLoadouts,
    getTempLoadout,
    Loadout,
    saveLoadout,
    saveTempLoadout
} from "../../utils/loadout";
import {ContainerData} from "../../models/container";
import {ArmorData} from "../../models/armor";
import {useTranslation} from "react-i18next";
import {ArmorSelector, ContainerSelector} from "./base-selector";
import {ArmorLevelInput} from "./level-input";
import {ARMOR} from "../../data/armor/armor";

export type CalculatorProps = {};

const CalculatorComponent: React.FC<CalculatorProps> = () => {
    const {t} = useTranslation();
    const calculator = useMemo(() => {
        return new Calculator()
    }, []);
    const [stats, setStats] = useState(calculator.getStats());
    const [specialStats, setSpecialStats] = useState(calculator.getSpecialStats());

    const [currentLoadout, setCurrentLoadout] = useState<Loadout>(getTempLoadout());

    const [includeHuman, setIncludeHuman] = useState(localStorage.getItem('includeHuman') === 'true' ?? true);

    useEffect(() => {
        localStorage.setItem('includeHuman', includeHuman.toString());
    }, [includeHuman]);

    const setArtifacts = useCallback((artifacts: ArtifactData[]) => {
        setCurrentLoadout({...currentLoadout, artifacts});
    }, [currentLoadout, setCurrentLoadout]);

    const setContainer = useCallback((container: ContainerData) => {
        setCurrentLoadout({...currentLoadout, container});
    }, [currentLoadout, setCurrentLoadout]);

    const setArmor = useCallback((armor: ArmorData) => {
        setCurrentLoadout({...currentLoadout, armor});
    }, [currentLoadout, setCurrentLoadout]);

    const setArtifactFactory = useCallback(
        (index: number) => (artifact: ArtifactData) => {
            setArtifacts(currentLoadout.artifacts.map((a, i) => i === index ? artifact : a));
        },
        [currentLoadout, setArtifacts]
    );

    const setLoadoutName = useCallback((name: string) => {
        setCurrentLoadout({...currentLoadout, name});
    }, [currentLoadout, setCurrentLoadout]);


    useEffect(() => {
        calculator.setContainer(currentLoadout.container);
        calculator.setArmor(currentLoadout.armor);
        const cont = AllContainers[currentLoadout.container.id];
        let artifacts = currentLoadout.artifacts;
        if (cont.capacity > currentLoadout.artifacts.length) {
            artifacts = [...currentLoadout.artifacts, ...Array(cont.capacity - currentLoadout.artifacts.length).fill(emptyArtifactFactory())];
        } else if (cont.capacity < currentLoadout.artifacts.length) {
            artifacts = currentLoadout.artifacts.slice(0, cont.capacity);
        }

        if(artifacts !== currentLoadout.artifacts) {
            setArtifacts(artifacts);
        }

        calculator.setArtifacts(artifacts);

    }, [currentLoadout.container, currentLoadout.artifacts, currentLoadout.armor, calculator, setArtifacts]);

    useEffect(() => {
        setStats(calculator.getStats(includeHuman));
        setSpecialStats(calculator.getSpecialStats());
    }, [currentLoadout, calculator, includeHuman]);

    const loadLoadout = useCallback((loadout: Loadout | null) => {
        if (!loadout) return;

        const newLoadout = JSON.parse(JSON.stringify(loadout)) as Loadout;
        setCurrentLoadout(newLoadout);
    }, [setCurrentLoadout]);

    useEffect(() => {
        saveTempLoadout(currentLoadout);
    }, [currentLoadout]);

    const [loadouts, setLoadouts] = useState<Loadout[]>(getSortedLoadouts());
    const save = useCallback(() => {
        const loadouts = saveLoadout(currentLoadout.name, currentLoadout);
        const sortedLoadouts = getSortedLoadouts(loadouts);
        setLoadouts(sortedLoadouts);
    }, [currentLoadout, setLoadouts]);

    const createNewLoadout = useCallback(() => {
        setCurrentLoadout(emptyLoadoutFactory());
    }, [setCurrentLoadout]);

    const deleteCurrentLoadout = useCallback(() => {
        const loadouts = deleteLoadout(currentLoadout.name);
        setLoadouts(getSortedLoadouts(loadouts));
        setCurrentLoadout(emptyLoadoutFactory());
    }, [currentLoadout, setCurrentLoadout, setLoadouts]);

    const getLabel = useCallback((loadout: Loadout) => loadout.name, []);

    return (
        <Container fluid='xxl'>
            <Row>
                <Col className='col-12 col-md-6 mb-2'>
                    <Container fluid className='p-0 mb-2'>
                        <Card>
                            <Card.Body>
                                <FloatingLabel
                                    controlId='loadout-name'
                                    label={t('loadout.loadoutName')}
                                    className='flex-grow-1 mb-2'
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder={t('loadout.loadoutName').toString()}
                                        autoComplete='off'
                                        value={currentLoadout.name}
                                        onChange={(e) => setLoadoutName(e.target.value)}
                                    />
                                </FloatingLabel>
                                <Stack direction='horizontal' gap={2} className={'flex-wrap'}>
                                    <Button variant='success' onClick={save} disabled={currentLoadout.name.length === 0}>{t('loadout.save')}</Button>
                                    <SearchDropdown<Loadout> id={'loadout-load'} value={null} onChange={loadLoadout} list={loadouts} getLabel={getLabel} placeholder={t('loadout.load')} />
                                    <Button variant='outline-success' onClick={createNewLoadout}>{t('loadout.new')}</Button>
                                    <Button variant='danger' onClick={deleteCurrentLoadout}>{t('loadout.delete')}</Button>
                                </Stack>
                            </Card.Body>
                        </Card>
                    </Container>
                    <ArmorSelector
                        value={currentLoadout.armor}
                        onChange={setArmor}
                        selectedContainer={currentLoadout.container}
                        title={t('armor.title')}
                        placeholder={t('armor.search.placeholder')}
                    >
                        {currentLoadout.armor.id !== ARMOR.NONE && <ArmorLevelInput value={currentLoadout.armor} onChange={setArmor}/>}
                    </ArmorSelector>
                    <ContainerSelector
                        value={currentLoadout.container}
                        onChange={setContainer}
                        selectedArmor={currentLoadout.armor}
                        title={t('container.title')}
                        placeholder={t('container.search.placeholder')}
                    />
                    <ArtifactList artifacts={currentLoadout.artifacts} setArtifactFactory={setArtifactFactory} />

                </Col>
                <Col className='col-12 col-md-6'>
                    <Card>
                        <Card.Header className={'bg-transparent'}>
                            <Stack direction='horizontal' gap={2}>
                                <div>
                                    {t('stat.title')}
                                </div>
                                <Form.Check
                                    type="switch"
                                    label={t('calculator.stat.includeHuman')}
                                    className={'ms-auto'}
                                    checked={includeHuman}
                                    onChange={(e) => setIncludeHuman(e.target.checked)}
                                />
                            </Stack>
                        </Card.Header>
                        <Card.Body>
                            <StatsComponent stats={stats} specialStats={specialStats}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )

};

export default CalculatorComponent;