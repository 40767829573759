import React, {useCallback} from "react";
import {ArtifactData} from "../../models/artifact";
import {FloatingLabel, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ArtifactDataFactory from "../../models/artifact-data-factory";
import {ArmorData} from "../../models/armor";
import {Level} from "../../constants/base";
import ArmorDataFactory from "../../models/armor-data-factory";

type LevelInputProps<TData> = {
    value: TData,
    onChange: (artifact: TData) => void
}

export const LEVEL_INPUT_TEST_ID = 'level-input';

function LevelInputFactory<TData extends ArtifactData | ArmorData>(updater: (data: TData, level: Level) => TData): React.FC<LevelInputProps<TData>> {
    const LevelInput: React.FC<LevelInputProps<TData>> = ({value, onChange}) => {
        const {t} = useTranslation();
        const onLevelChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = Number(event.target.value) as Level;

            const newData = updater(value, newValue);
            if (newData === value) return;

            onChange(newData);
        }, [onChange, value]);

        return (
            <FloatingLabel
                label={t('artifact.level')}
                className="mb-2"
            >
                <Form.Control
                    data-testid={LEVEL_INPUT_TEST_ID}
                    type="number"
                    placeholder="0"
                    value={value.level.toString()}
                    onChange={onLevelChange}
                />
            </FloatingLabel>

        )
    };

    LevelInput.displayName = 'LevelInput';

    return LevelInput;
}


export const ArtifactLevelInput = LevelInputFactory(ArtifactDataFactory.changeLevel);

export const ArmorLevelInput = LevelInputFactory(ArmorDataFactory.changeLevel);

