import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const mis85d = new Armor(ARMOR.MIS_85_D,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 5,
        [STAT.bulletDmgFactor]: 45,
        [STAT.tearDmgFactor]: 55,
        [STAT.explosionDmgFactor]: 57,
        [STAT.electraDmgFactor]: 433,
        [STAT.burnDmgFactor]: 433,
        [STAT.chemicalBurnDmgFactor]: 433,
        [STAT.radiationProtection]: 300,
        [STAT.thermalProtection]: 250,
        [STAT.biologicalProtection]: 400,
        [STAT.psychoProtection]: 395,
        [STAT.bleedingProtection]: 37,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default mis85d;