import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const cd3bArmoredSuit = new Armor(ARMOR.CD_3_B_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 136,
        [STAT.tearDmgFactor]: 158,
        [STAT.explosionDmgFactor]: 189,
        [STAT.electraDmgFactor]: 93,
        [STAT.burnDmgFactor]: 93,
        [STAT.chemicalBurnDmgFactor]: 93,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 75,
        [STAT.biologicalProtection]: 100,
        [STAT.psychoProtection]: 100,
        [STAT.bleedingProtection]: 60,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default cd3bArmoredSuit;