import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const electraVspyshka = new Artifact(ARTIFACT.ELECTRA_VSPYSHKA, {
    [STAT.staminaBonus]: [7.5, 15],
    [STAT.electraDmgFactor]: [-25, -50],
}, {
    [STAT.staminaBonus]: [3.75, 7.5],
    [STAT.staminaRegenerationBonus]: [1.5, 3.75],
    [STAT.electraDmgFactor]: [10, 20],
});

export default electraVspyshka;