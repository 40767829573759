import Base from "../models/base";
import {STAT} from "../models/stats";

export const HUMAN = 'human';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HUMAN = 'human';

const human = new Base(HUMAN, {
    [STAT.biologicalAccumulation]: 1,
    [STAT.thermalAccumulation]: 1,
    [STAT.radiationAccumulation]: 1,
    [STAT.psychoAccumulation]: 2,
    [STAT.maxWeightBonus]: 50,
    [STAT.speedModifier]: 100,
    [STAT.healthBonus]: 100,
    [STAT.regenerationBonus]: 2.5,
    [STAT.staminaBonus]: 100,
    [STAT.staminaRegenerationBonus]: 10,
});

export default human;