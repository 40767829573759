import React, {useCallback} from "react";
import {ArtifactData} from "../../../../models/artifact";
import {FloatingLabel, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ArtifactDataFactory from "../../../../models/artifact-data-factory";

type ArtifactQualityInputProps = {
    artifact: ArtifactData,
    setArtifact: (artifact: ArtifactData) => void
}

export const ARTIFACT_QUALITY_INPUT_TEST_ID = 'artifact-quality-input';

const ArtifactQualityInput: React.FC<ArtifactQualityInputProps> = ({ artifact, setArtifact}) => {
    const {t} = useTranslation();
    const onQualityChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value);

        const newArtifact = ArtifactDataFactory.changeQuality(artifact, newValue);

        if(newArtifact === artifact) return;

        setArtifact(newArtifact);
    }, [setArtifact, artifact]);

    return (
        <FloatingLabel
            label={t('artifact.quality')}
            className="mb-2"
        >
            <Form.Control
                type="number"
                placeholder="100"
                data-testid={ARTIFACT_QUALITY_INPUT_TEST_ID}
                value={artifact.quality.toString()}
                onChange={onQualityChange}
            />
        </FloatingLabel>
    )
};

export default ArtifactQualityInput;