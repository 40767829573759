import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const puhEj = new Artifact(ARTIFACT.PUH_EJ, {
    [STAT.bleedingDmgFactor]: [-25, -50],
    [STAT.radiationAccumulation]: [3, 6],
    [STAT.biologicalAccumulation]: [2, 4],
}, {
    [STAT.biologicalAccumulation]: [0.5, 1],
    [STAT.staminaBonus]: [5, 10],
    [STAT.bleedingDmgFactor]: [10, 20],
});

export default puhEj;