import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wormArmoredSuit = new Armor(ARMOR.WORM_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -3,
        [STAT.maxWeightBonus]: 3,
        [STAT.bulletDmgFactor]: 61,
        [STAT.tearDmgFactor]: 61,
        [STAT.explosionDmgFactor]: 84,
        [STAT.electraDmgFactor]: 24,
        [STAT.burnDmgFactor]: 24,
        [STAT.chemicalBurnDmgFactor]: 24,
        [STAT.radiationProtection]: 90,
        [STAT.thermalProtection]: 0,
        [STAT.biologicalProtection]: 75,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 39,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 4470,
            [RESOURCE.PIECES_OF_ALUMINUM_CABLE]: 12,
            [RESOURCE.STINKY_ROOT]: 16,
            [RESOURCE.SEEDS]: 29,
        },
        dependency: ARMOR.SCOUT_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default wormArmoredSuit;