import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const wornIolite = new Armor(ARMOR.WORN_IOLITE,
    {
        [STAT.bulletDmgFactor]: 49,
        [STAT.tearDmgFactor]: 55,
        [STAT.explosionDmgFactor]: 67,
        [STAT.electraDmgFactor]: 1111,
        [STAT.burnDmgFactor]: 500,
        [STAT.chemicalBurnDmgFactor]: 500,
        [STAT.radiationProtection]: 400,
        [STAT.thermalProtection]: 310,
        [STAT.biologicalProtection]: 400,
        [STAT.psychoProtection]: 310,
        [STAT.bleedingProtection]: 35,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default wornIolite;