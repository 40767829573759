import {ArtifactData} from "../../../../models/artifact";
import {useTranslation} from "react-i18next";
import {ButtonGroup, Dropdown} from "react-bootstrap";
import React, {useCallback} from "react";

type ArtifactCopyProps = {
    artifact: ArtifactData,
    setArtifactFactory: (index: number) => (artifact: ArtifactData) => void,
    slots: number,
    index: number
}

const ArtifactCopy: React.FC<ArtifactCopyProps> = ({ artifact, setArtifactFactory, slots, index }) => {
    const {t} = useTranslation();
    const copyArtifact = useCallback((i: number) => {
        return () => {
            setArtifactFactory(i)({
                ...artifact,
                additionalStats: [...artifact.additionalStats]
            });
        }
    }, [setArtifactFactory, artifact]);

    if (slots < 2) return null;

    return (
        <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle variant="success" data-testid="artifact-copy-button" id={`artifact-copy-button-${artifact.id}-${index}`}>
                {t('artifact.copy')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {new Array(slots).fill(0).map((_, i) => (
                    (index !== i) && <Dropdown.Item key={i} onClick={copyArtifact(i)} eventKey={i}>{i + 1}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
};

export default ArtifactCopy;