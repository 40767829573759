import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {STAT} from "../../../models/stats";

const reinforcedHellboyBackpack = new Container(
    CONTAINER.REINFORCED_HELLBOY_BACKPACK,
    {
        [STAT.staminaBonus]: 20,
        [STAT.maxWeightBonus]: 28,
    },
    [
    ].filter(Boolean), 2, 0.6);

export default reinforcedHellboyBackpack;