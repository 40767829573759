import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const trampolineProtomeduza = new Artifact(ARTIFACT.TRAMPOLINE_PROTOMEDUZA, {
    [STAT.regenerationBonus]: [-0.75, -1.5],
    [STAT.staminaRegenerationBonus]: [-1.5, -3],
    [STAT.radiationAccumulation]: [5, 10],
}, {
    [STAT.radiationAccumulation]: [1.25, 2.5],
    [STAT.staminaRegenerationBonus]: [0.6, 1.2],
    [STAT.regenerationBonus]: [0.3, 0.6],
});

export default trampolineProtomeduza;