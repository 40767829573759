import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const hellboyBackpack = new Container(
    CONTAINER.HELLBOY_BACKPACK,
    {
        [STAT.staminaBonus]: 20,
        [STAT.maxWeightBonus]: 28,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 25620,
                [RESOURCE.PIECES_OF_COPPER_WIRE]: 43,
                [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 124,
                [RESOURCE.PIECE_OF_INFERNAL_COAL]: 67,
            },
            dependency: CONTAINER.MBSS_BACKPACK,
        },
        {
            resources: {
                [RESOURCE.RUB]: 32180,
                [RESOURCE.PIECES_OF_COPPER_WIRE]: 72,
                [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 210,
                [RESOURCE.PIECE_OF_INFERNAL_COAL]: 113,
            },
        },
    ].filter(Boolean), 2, 0.6);

export default hellboyBackpack;