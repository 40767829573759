import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const mis99Malachite = new Armor(ARMOR.MIS_99_MALACHITE,
    {
        [STAT.bulletDmgFactor]: 49,
        [STAT.tearDmgFactor]: 55,
        [STAT.explosionDmgFactor]: 67,
        [STAT.electraDmgFactor]: 601,
        [STAT.burnDmgFactor]: 601,
        [STAT.chemicalBurnDmgFactor]: 601,
        [STAT.radiationProtection]: 210,
        [STAT.thermalProtection]: 210,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 180,
        [STAT.bleedingProtection]: 35,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 54560,
            [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 151,
            [RESOURCE.BLUE_YEAST]: 248,
            [RESOURCE.BETA_DATA_FRAGMENT]: 57,
            [RESOURCE.PICKLE]: 98,
        },
        dependency: ARMOR.WORN_MIS_99_M_MALACHITE,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default mis99Malachite;