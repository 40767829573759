import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {STAT} from "../../../models/stats";

const kzs3u = new Container(
    CONTAINER.KZS_3U,
    {
        [STAT.speedModifier]: -1,
    },
    [
    ].filter(Boolean), 3, 0.75);

export default kzs3u;