import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornFleeceProtectiveSuit = new Armor(ARMOR.WORN_FLEECE_PROTECTIVE_SUIT,
    {
        [STAT.maxWeightBonus]: 7,
        [STAT.bulletDmgFactor]: 58,
        [STAT.tearDmgFactor]: 175,
        [STAT.explosionDmgFactor]: 80,
        [STAT.electraDmgFactor]: 36,
        [STAT.burnDmgFactor]: 36,
        [STAT.chemicalBurnDmgFactor]: 36,
        [STAT.radiationProtection]: 150,
        [STAT.thermalProtection]: 20,
        [STAT.biologicalProtection]: 120,
        [STAT.psychoProtection]: 60,
        [STAT.bleedingProtection]: 68,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 52320,
            [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 90,
            [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 370,
            [RESOURCE.ALPHA_DATA_FRAGMENT]: 30,
            [RESOURCE.PIECE_OF_INFERNAL_COAL]: 86,
        },
        dependency: ARMOR.TRAPPER_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default wornFleeceProtectiveSuit;