import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const mis99Amber = new Armor(ARMOR.MIS_99_AMBER,
    {
        [STAT.bulletDmgFactor]: 31,
        [STAT.tearDmgFactor]: 35,
        [STAT.explosionDmgFactor]: 43,
        [STAT.electraDmgFactor]: 333,
        [STAT.burnDmgFactor]: 333,
        [STAT.chemicalBurnDmgFactor]: 333,
        [STAT.radiationProtection]: 180,
        [STAT.thermalProtection]: 210,
        [STAT.biologicalProtection]: 255,
        [STAT.psychoProtection]: 105,
        [STAT.bleedingProtection]: 25,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 3880,
            [RESOURCE.PIECES_OF_ALUMINUM_CABLE]: 28,
            [RESOURCE.STINKY_ROOT]: 16,
            [RESOURCE.SEEDS]: 13,
        },
        dependency: ARMOR.WORN_MIS_99_AMBER,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default mis99Amber;