import React, {useCallback, useMemo} from "react";
import {
    ArmorListByContainerType,
    ContainerListByArmorContainerType,
    getArmorTKey,
    getContainerTKey,
    getSortedList
} from "../../utils/search";
import {Card} from "react-bootstrap";
import SearchDropdown from "../common/search-dropdown";
import Artifact, {ArtifactData} from "../../models/artifact";
import Armor, {ArmorData} from "../../models/armor";
import Container, {ContainerData} from "../../models/container";
import {ARTIFACT} from "../../data/artifact/artifact";
import {ARMOR, emptyArmorFactory} from "../../data/armor/armor";
import {CONTAINER, emptyContainerFactory} from "../../data/container/container";
import {useTranslation} from "react-i18next";
import AllArmor from "../../data/armor";
import AllContainers from "../../data/container";

export type BaseSelectorProps<T, TData> = {
    list: T[],
    value: TData,
    onChange: (value: TData) => void,
    title: string,
    placeholder: string,
    children?: React.ReactNode,
}

function baseSelectorFactory<
    T extends Artifact | Armor | Container,
    TData extends ArtifactData | ArmorData | ContainerData,
    TKey extends ARTIFACT | ARMOR | CONTAINER
>
    (
        hash: Record<TKey, T>,
        emptyFactory: () => TData,
        getTKey: (item: T) => string,
    ): React.FC<BaseSelectorProps<T, TData>> {
    const Component: React.FC<BaseSelectorProps<T, TData>> =
        ({
            list,
            value,
            onChange,
            title,
            placeholder,
            children
        }) => {
        const {t} = useTranslation();
        const name = list.length > 0 ? list[0].constructor.name : 'BaseSelector';
        const items = useMemo(() => {
            return getSortedList<T>(list, getTKey, t);
        }, [t, list]);
        const getLabel = useCallback((item: T) => {
            return t(getTKey(item));
        }, [t]);
        const onItemChange = useCallback((item: T | null) => {
            const empty = emptyFactory();
            if (item === null) {
                onChange(empty);
            } else {
                onChange({ ...empty, id: item.id} as TData);
            }
        }, [onChange]);

        const item = useMemo(() => {
            const id = value.id as TKey;
            switch (id) {
                case CONTAINER.NONE:
                case ARMOR.NONE:
                case ARTIFACT.NONE:
                    return null;
                default:
                    return hash[id] as T;
            }
        }, [value]);

        return (
            <Card className={'border-0 mb-2'}>
                <Card.Header className={'bg-transparent'}>
                    {title}
                </Card.Header>
                <Card.Body>
                    <div className={children ? 'mb-2' : ''}>
                        <SearchDropdown<T>
                            id={`${name}-selector`}
                            list={items}
                            getLabel={getLabel}
                            placeholder={placeholder}
                            value={item}
                            onChange={onItemChange}
                        />
                    </div>
                    {children}
                </Card.Body>
            </Card>
        )
    };

    Component.displayName = 'BaseSelector';

    return Component;
}

// export const ArtifactSelector = baseSelectorFactory<Artifact, ArtifactData, ARTIFACT>(AllArtifacts, emptyArtifactFactory, getArtifactTKey);

const ArmorSelectorBase = baseSelectorFactory<Armor, ArmorData, ARMOR>(AllArmor, emptyArmorFactory, getArmorTKey);

const ContainerSelectorBase = baseSelectorFactory<Container, ContainerData, CONTAINER>(AllContainers, emptyContainerFactory, getContainerTKey);

export type ArmorSelectorProps = {
    value: ArmorData,
    onChange: (value: ArmorData) => void,
    selectedContainer: ContainerData,
    title: string,
    placeholder: string,
    children?: React.ReactNode,
}

export const ArmorSelector: React.FC<ArmorSelectorProps> = (props) => {
    const {value, onChange, selectedContainer, title, placeholder, children} = props;
    const list = useMemo(() => {
        const container = AllContainers[selectedContainer.id];
        const containerType = container.type;

        return ArmorListByContainerType[containerType];
    }, [selectedContainer]);
    return (
        <ArmorSelectorBase
            list={list}
            value={value}
            onChange={onChange}
            title={title}
            placeholder={placeholder}
        >
            {children}
        </ArmorSelectorBase>
    )
}

export type ContainerSelectorProps = {
    value: ContainerData,
    onChange: (value: ContainerData) => void,
    selectedArmor: ArmorData,
    title: string,
    placeholder: string,
    children?: React.ReactNode,
}

export const ContainerSelector: React.FC<ContainerSelectorProps> = (props) => {
    const {value, onChange, selectedArmor, title, placeholder, children} = props;
    const list = useMemo(() => {
        return ContainerListByArmorContainerType[AllArmor[selectedArmor.id].containerType];
    }, [selectedArmor]);
    return (
        <ContainerSelectorBase
            list={list}
            value={value}
            onChange={onChange}
            title={title}
            placeholder={placeholder}
        >
            {children}
        </ContainerSelectorBase>
    )
}
