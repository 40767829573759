import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const kiselSliz = new Artifact(ARTIFACT.KISEL_SLIZ, {
    [STAT.bleedingAccumulation]: [1, 2],
    [STAT.burnDmgFactor]: [-50, -100],
    [STAT.bleedingProtection]: [5, 10],
}, {
    [STAT.bleedingDmgFactor]: [5, 10],
    [STAT.burnDmgFactor]: [20, 40],
    [STAT.bleedingProtection]: [2.5, 5],
});

export default kiselSliz;