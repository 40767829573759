import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornMis99Amber = new Armor(ARMOR.WORN_MIS_99_AMBER,
    {
        [STAT.bulletDmgFactor]: 28,
        [STAT.tearDmgFactor]: 32,
        [STAT.explosionDmgFactor]: 38,
        [STAT.electraDmgFactor]: 296,
        [STAT.burnDmgFactor]: 296,
        [STAT.chemicalBurnDmgFactor]: 296,
        [STAT.radiationProtection]: 150,
        [STAT.thermalProtection]: 180,
        [STAT.biologicalProtection]: 210,
        [STAT.psychoProtection]: 100,
        [STAT.bleedingProtection]: 23,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 4850,
            [RESOURCE.PIECES_OF_ALUMINUM_CABLE]: 35,
            [RESOURCE.STINKY_ROOT]: 20,
            [RESOURCE.SEEDS]: 16,
        },
        dependency: ARMOR.BANDIT_SUIT_WITH_GAS_TANKS,
    },
    {
        resources: {
            [RESOURCE.RUB]: 4850,
            [RESOURCE.PIECES_OF_ALUMINUM_CABLE]: 35,
            [RESOURCE.STINKY_ROOT]: 20,
            [RESOURCE.SEEDS]: 16,
        },
        dependency: ARMOR.ZARYA_B_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default wornMis99Amber;