import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, ru } from './locales';

const lang = localStorage.getItem('lang') ?? 'en';
export default i18next.use(initReactI18next)
    .use(initReactI18next)
    .init({
        lng: lang,
        fallbackLng: 'en',
        // debug: true,
        resources: {
            en,
            ru,
        }
});

const t = (key: string) => i18next.t(key);
export type TFunction = typeof t;