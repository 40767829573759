import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const lynxArmoredSuit = new Armor(ARMOR.LYNX_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -5,
        [STAT.speedModifier]: 2,
        [STAT.maxWeightBonus]: 6,
        [STAT.bulletDmgFactor]: 115,
        [STAT.tearDmgFactor]: 119,
        [STAT.explosionDmgFactor]: 160,
        [STAT.electraDmgFactor]: 47,
        [STAT.burnDmgFactor]: 47,
        [STAT.chemicalBurnDmgFactor]: 47,
        [STAT.radiationProtection]: 100,
        [STAT.thermalProtection]: 30,
        [STAT.biologicalProtection]: 100,
        [STAT.psychoProtection]: 75,
        [STAT.bleedingProtection]: 55,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default lynxArmoredSuit;