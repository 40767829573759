import {ArtifactData} from "../../models/artifact";
import {Rarity} from "../../constants/base";

export enum ELECTROPHYSICAL_ARTIFACT {
    ELECTRA_SNOWFLAKE = 'electra_snowflake',
    ELECTRA_CRYSTAL = 'electra_crystal',
    ELECTRA_BENGAL = 'electra_bengal',
    ELECTRA_VSPYSHKA = 'electra_vspyshka',
    ELECTRA_BATAREYKA = 'electra_batareyka',
    ELECTRA_PUSTISHKA = 'electra_pustishka',
    ELECTRA_DEZINTEGRATOR = 'electra_dezintegrator',
    ELECTRA_MOONLIGHT = 'electra_moonlight',
    ELECTRA_HELIUM = 'electra_helium',
    PUZIR_SPIRAL = 'puzir_spiral',
    PUZIR_ZERKALO = 'puzir_zerkalo',
    PUZIR_OSKOLOK = 'puzir_oskolok',
    PUZIR_PRISMA = 'puzir_prisma',
    PUZIR_ATOM = 'puzir_atom',
}

export enum THERMAL_ARTIFACT {
    JARKA_GLAZ = 'jarka_glaz',
    JARKA_CRYSTAL = 'jarka_crystal',
    JARKA_FIREBALL = 'jarka_fireball',
    JARKA_KAPLYA = 'jarka_kaplya',
    JARKA_VSLEZY = 'jarka_vslezy',
    JARKA_MAMYBUSI = 'jarka_mamybusi',
    JARKA_PLAMYA = 'jarka_plamya',
    JARKA_SOLNCE = 'jarka_solnce',
    BLIZZARD_FROST = 'blizzard_frost',
    BLIZZARD_CRYSTAL = 'blizzard_crystal',
    BLIZZARD_CRUST = 'blizzard_crust',
    BLIZZARD_VORTEX = 'blizzard_vortex',
    BLIZZARD_FAHRENHEIT = 'blizzard_fahrenheit',
    BLIZZARD_HEEL = 'blizzard_heel',
    BLIZZARD_CHICKENGOD = 'blizzard_chickengod',
    BLIZZARD_STORMEYE = 'blizzard_stormeye',
}


export enum GRAVI_ARTIFACT {
    VORONKA_GOLDFISH = 'voronka_goldfish',
    TRAMPOLINE_BRASLET = 'trampoline_braslet',
    VORONKA_VIVERT = 'voronka_vivert',
    VORONKA_GRAVI = 'voronka_gravi',
    VORONKA_GOLDGRAVI = 'voronka_goldgravi',
    VORONKA_YANTAR = 'voronka_yantar',
    VORONKA_KRIOGEN = 'voronka_kriogen',
    VORONKA_CRYSTAL = 'voronka_crystal',
    KAROUSEL_BLOODSTONE = 'karousel_bloodstone',
    KAROUSEL_LOMOT = 'karousel_lomot',
    KAROUSEL_JELCH = 'karousel_jelch',
    KAROUSEL_GNILEC = 'karousel_gnilec',
    KAROUSEL_DUSHA = 'karousel_dusha',
    KAROUSEL_PRUJINA = 'karousel_prujina',
    KAROUSEL_OSTOV = 'karousel_ostov',
    TRAMPOLINE_KAMENCVET = 'trampoline_kamencvet',
    TRAMPOLINE_MEDUZA = 'trampoline_meduza',
    TRAMPOLINE_DARKMEDUZA = 'trampoline_darkmeduza',
    TRAMPOLINE_JILNIK = 'trampoline_jilnik',
    TRAMPOLINE_NIGHTSTAR = 'trampoline_nightstar',
    TRAMPOLINE_PROTOMEDUZA = 'trampoline_protomeduza',
}


export enum BIOCHEMICAL_ARTIFACT {
    KISEL_CRYSTAL = 'kisel_crystal',
    KISEL_KISEL = 'kisel_kisel',
    KISEL_SLIZ = 'kisel_sliz',
    KISEL_SLIZNYAK = 'kisel_sliznyak',
    KISEL_SLYDA = 'kisel_slyda',
    KISEL_SVETLYAK = 'kisel_svetlyak',
    KISEL_ULITKA = 'kisel_ulitka',
    KISEL_PUZIR = 'kisel_puzir',
    KISEL_PLENKA = 'kisel_plenka',
    KISEL_POLYHEDRON = 'kisel_polyhedron',
    PUH_KOLUCHKA = 'puh_koluchka',
    PUH_KKOLUCHKA = 'puh_kkoluchka',
    PUH_EJ = 'puh_ej',
    PUH_KOLOBOK = 'puh_kolobok',
    PUH_STEELKOLOBOK = 'puh_steelkolobok',
}

export enum NO_ARTIFACT {
    NONE = 'none',
}


// export type ARTIFACT = typeof ELECTRA_ARTIFACT & typeof FIRE_ARTIFACT;
export const ARTIFACT = { ...ELECTROPHYSICAL_ARTIFACT, ...THERMAL_ARTIFACT, ...GRAVI_ARTIFACT, ...BIOCHEMICAL_ARTIFACT, ...NO_ARTIFACT };
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ARTIFACT = ELECTROPHYSICAL_ARTIFACT | THERMAL_ARTIFACT | GRAVI_ARTIFACT | BIOCHEMICAL_ARTIFACT | NO_ARTIFACT;

export const REVERSE_ARTIFACT = Object.keys(ARTIFACT).reduce((acc, key) => {
    acc[ARTIFACT[key as keyof typeof ARTIFACT]] = key;
    return acc;
}, {} as Record<ARTIFACT, string>);

export const emptyArtifactFactory = (): ArtifactData => ({
    id: ARTIFACT.NONE,
    level: 0,
    rarity: Rarity.COMMON,
    quality: 0,
    additionalStats: []
});



