import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const voronkaGoldfish = new Artifact(ARTIFACT.VORONKA_GOLDFISH, {
    [STAT.maxWeightBonus]: [5, 10],
    [STAT.psychoAccumulation]: [-3, -8],
    [STAT.bulletDmgFactor]: [5, 15],
}, {
    [STAT.bulletDmgFactor]: [2.5, 3.75],
    [STAT.explosionDmgFactor]: [10, 20],
    [STAT.psychoAccumulation]: [0.75, 2],
});

export default voronkaGoldfish;