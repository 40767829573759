import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const brigandArmoredSuit = new Armor(ARMOR.BRIGAND_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 10,
        [STAT.artefaktHeal]: 1,
        [STAT.thermalAccumulation]: 1,
        [STAT.bulletDmgFactor]: 109,
        [STAT.tearDmgFactor]: 121,
        [STAT.explosionDmgFactor]: 163,
        [STAT.electraDmgFactor]: 211,
        [STAT.burnDmgFactor]: 211,
        [STAT.chemicalBurnDmgFactor]: 211,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 120,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 170,
        [STAT.bleedingProtection]: 56,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default brigandArmoredSuit;