import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const zaryaBSuit = new Armor(ARMOR.ZARYA_B_SUIT,
    {
        [STAT.bulletDmgFactor]: 21,
        [STAT.tearDmgFactor]: 24,
        [STAT.explosionDmgFactor]: 29,
        [STAT.electraDmgFactor]: 178,
        [STAT.burnDmgFactor]: 178,
        [STAT.chemicalBurnDmgFactor]: 178,
        [STAT.radiationProtection]: 120,
        [STAT.thermalProtection]: 105,
        [STAT.biologicalProtection]: 180,
        [STAT.psychoProtection]: 90,
        [STAT.bleedingProtection]: 18,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 6110,
            [RESOURCE.STINKY_ROOT]: 25,
            [RESOURCE.SEEDS]: 20,
        },
    },
    {
        resources: {
            [RESOURCE.RUB]: 2920,
            [RESOURCE.STINKY_ROOT]: 12,
            [RESOURCE.SEEDS]: 10,
        },
        dependency: ARMOR.IP_4_M,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default zaryaBSuit;