import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const rigelSuit = new Armor(ARMOR.RIGEL_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.speedModifier]: -2,
        [STAT.maxWeightBonus]: 20,
        [STAT.bulletDmgFactor]: 105,
        [STAT.tearDmgFactor]: 116,
        [STAT.explosionDmgFactor]: 171,
        [STAT.electraDmgFactor]: 711,
        [STAT.burnDmgFactor]: 1200,
        [STAT.chemicalBurnDmgFactor]: 711,
        [STAT.radiationProtection]: 240,
        [STAT.thermalProtection]: 270,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 240,
        [STAT.bleedingProtection]: 52,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default rigelSuit;