import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const misXAtlas = new Armor(ARMOR.MIS_X_ATLAS,
    {
        [STAT.staminaBonus]: -15,
        [STAT.maxWeightBonus]: 4,
        [STAT.radiationAccumulation]: 2,
        [STAT.bleedingAccumulation]: 0.5,
        [STAT.bulletDmgFactor]: 64,
        [STAT.tearDmgFactor]: 81,
        [STAT.explosionDmgFactor]: 80,
        [STAT.electraDmgFactor]: 840,
        [STAT.burnDmgFactor]: 840,
        [STAT.chemicalBurnDmgFactor]: 840,
        [STAT.radiationProtection]: 385,
        [STAT.thermalProtection]: 370,
        [STAT.biologicalProtection]: 400,
        [STAT.psychoProtection]: 310,
        [STAT.bleedingProtection]: 35,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default misXAtlas;