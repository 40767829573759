import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import {Card, Container} from "react-bootstrap";
import {Link} from "react-router-dom";

const Overview = () => {
    const {t} = useTranslation();
    return (
        <Container>
            <Card>
                <Card.Header className={'bg-transparent'}>
                    {t('overview.title')}
                </Card.Header>
                <Card.Body>
                    <div className={'mb-3'}>
                        <Trans
                            i18nKey='overview.description'
                            components={{
                                discord: <Link target='_blank' to='https://discord.gg/SwZkfXXv' />,
                            }}
                        />
                    </div>
                    <div>
                        <Trans
                            i18nKey='overview.functionality'
                            components={{
                                calc: <Link to='/calc' />,
                            }}
                        />
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Overview;