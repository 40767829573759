import Artifact, {ArtifactData} from "./artifact";
import {ArtifactQuality, Level, LEVEL_VALIDATION, QUALITY_VALIDATION, Rarity} from "../constants/base";
import {validateNumberInput} from "../utils/validation";
import AppError from "../utils/app-error";
import {StatsKey} from "./stats";
import AllArtifacts from "../data/artifact";

class ArtifactDataFactory {
    public static changeLevel(artifact: ArtifactData, level: Level): ArtifactData {
        const newValue = validateNumberInput(level, LEVEL_VALIDATION) as Level

        if(artifact.level === newValue) return artifact;

        const newArtifact: Partial<ArtifactData> = {level: newValue};
        const maxAdditionalStats = Artifact.getAllowedAdditionalStatsCount(newValue);
        if (artifact.additionalStats.length > maxAdditionalStats) {
            newArtifact.additionalStats = artifact.additionalStats.slice(0, maxAdditionalStats);
        }

        return {...artifact, ...newArtifact};
    }

    public static changeQuality(artifact: ArtifactData, quality: number): ArtifactData {
        const newValue = validateNumberInput(quality, QUALITY_VALIDATION) as ArtifactQuality;

        if(artifact.quality === newValue) return artifact;

        const newArtifact: Partial<ArtifactData> = {quality: newValue};

        const rarities = Artifact.getRarityByQuality(newValue);

        if (rarities.length === 1) {
            newArtifact.rarity = rarities[0];
        } else if (rarities[0] !== artifact.rarity && rarities[1] !== artifact.rarity) {
            newArtifact.rarity = rarities[1];
        }

        return {...artifact, ...newArtifact};
    }

    public static changeRarity(artifact: ArtifactData, rarity: Rarity): ArtifactData {
        const availableRarities = Artifact.getRarityByQuality(artifact.quality);
        if (!availableRarities.includes(rarity))
            throw new AppError(
                `Rarity ${rarity} is not available for quality ${artifact.quality}`,
                'ERROR.RARITY_NOT_AVAILABLE'
            );

        if (artifact.rarity === rarity) return artifact;

        return {...artifact, rarity};
    }

    public static addAdditionalStat(artifact: ArtifactData, stat: StatsKey): ArtifactData {
        if(artifact.additionalStats.length >= Artifact.getAllowedAdditionalStatsCount(artifact.level))
            throw new AppError(
                `Artifact already has maximum allowed additional stats`,
                'ERROR.MAX_ADDITIONAL_STATS'
            );
        if(artifact.additionalStats.includes(stat))
            throw new AppError(
                `Artifact already has stat ${stat}`,
                'ERROR.STAT_ALREADY_EXISTS'
            );

        const art = AllArtifacts[artifact.id];
        if(!art.additionalStats[stat])
            throw new AppError(
                `Stat ${stat} is not available for artifact ${artifact.id}`,
                'ERROR.STAT_NOT_AVAILABLE'
            );

        const newArtifact: Partial<ArtifactData> = {additionalStats: [...artifact.additionalStats, stat]};
        return {...artifact, ...newArtifact};
    }

    public static removeAdditionalStat(artifact: ArtifactData, stat: StatsKey): ArtifactData {
        if(!artifact.additionalStats.includes(stat))
            throw new AppError(
                `Artifact doesn't have stat ${stat}`,
                'ERROR.STAT_NOT_EXISTS'
            );
        const newArtifact: Partial<ArtifactData> = {additionalStats: artifact.additionalStats.filter(s => s !== stat)};
        return {...artifact, ...newArtifact};
    }
}

export default ArtifactDataFactory;