import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const skat10ArmoredSuit = new Armor(ARMOR.SKAT_10_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 142,
        [STAT.tearDmgFactor]: 153,
        [STAT.explosionDmgFactor]: 206,
        [STAT.electraDmgFactor]: 356,
        [STAT.burnDmgFactor]: 356,
        [STAT.chemicalBurnDmgFactor]: 356,
        [STAT.radiationProtection]: 225,
        [STAT.thermalProtection]: 160,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 210,
        [STAT.bleedingProtection]: 60,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 2271140,
            [RESOURCE.QUANTUM_BATTERY]: 890,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1260,
            [RESOURCE.RED_FERN]: 880,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 1030,
            [RESOURCE.SUBSTANCE_07270]: 2640,
            [RESOURCE.ANOMALOUS_SERUM]: 75,
        },
        dependency: ARMOR.SKAT_9_M_ARMORED_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 2273900,
            [RESOURCE.QUANTUM_BATTERY]: 890,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1260,
            [RESOURCE.RED_FERN]: 880,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 1030,
            [RESOURCE.SUBSTANCE_07270]: 2640,
            [RESOURCE.ANOMALOUS_SERUM]: 75,
        },
        dependency: ARMOR.SKAT_9_B_ARMORED_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default skat10ArmoredSuit;