import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const kiselPuzir = new Artifact(ARTIFACT.KISEL_PUZIR, {
    [STAT.radiationDmgFactor]: [5, 10],
    [STAT.radiationAccumulation]: [3, 9],
    [STAT.thermalAccumulation]: [-1, -3],
}, {
    [STAT.biologicalDmgFactor]: [5, 10],
    [STAT.radiationDmgFactor]: [2.5, 5],
    [STAT.thermalAccumulation]: [0.25, 0.75],
});

export default kiselPuzir;