import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const grenadierExoskeleton = new Armor(ARMOR.GRENADIER_EXOSKELETON,
    {
        [STAT.speedModifier]: 5,
        [STAT.maxWeightBonus]: 30,
        [STAT.bulletDmgFactor]: 110,
        [STAT.tearDmgFactor]: 125,
        [STAT.explosionDmgFactor]: 175,
        [STAT.electraDmgFactor]: 50,
        [STAT.burnDmgFactor]: 50,
        [STAT.chemicalBurnDmgFactor]: 50,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 40,
        [STAT.biologicalProtection]: 270,
        [STAT.psychoProtection]: 150,
        [STAT.bleedingProtection]: 60,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_STANDARD_CONTAINER,
)

export default grenadierExoskeleton;