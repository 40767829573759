import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const improvedHazmatSuit = new Armor(ARMOR.IMPROVED_HAZMAT_SUIT,
    {
        [STAT.staminaBonus]: -20,
        [STAT.bulletDmgFactor]: 49,
        [STAT.tearDmgFactor]: 55,
        [STAT.explosionDmgFactor]: 67,
        [STAT.electraDmgFactor]: 370,
        [STAT.burnDmgFactor]: 370,
        [STAT.chemicalBurnDmgFactor]: 370,
        [STAT.radiationProtection]: 150,
        [STAT.thermalProtection]: 150,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 100,
        [STAT.bleedingProtection]: 35,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default improvedHazmatSuit;