import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const cd1Suit = new Armor(ARMOR.CD_1_SUIT,
    {
        [STAT.maxWeightBonus]: 6,
        [STAT.bulletDmgFactor]: 33,
        [STAT.tearDmgFactor]: 47,
        [STAT.explosionDmgFactor]: 63,
        [STAT.electraDmgFactor]: 53,
        [STAT.burnDmgFactor]: 53,
        [STAT.chemicalBurnDmgFactor]: 53,
        [STAT.radiationProtection]: 60,
        [STAT.thermalProtection]: 45,
        [STAT.biologicalProtection]: 60,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 33,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default cd1Suit;