import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const mbssBackpack = new Container(
    CONTAINER.MBSS_BACKPACK,
    {
        [STAT.staminaBonus]: 25,
        [STAT.maxWeightBonus]: 30,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 3860,
                [RESOURCE.PIECES_OF_ALUMINUM_CABLE]: 26,
                [RESOURCE.STINKY_ROOT]: 33,
                [RESOURCE.SEEDS]: 11,
            },
            dependency: CONTAINER.ERRAND_JUNIOR_BACKPACK,
        },
    ].filter(Boolean), 0, 0);

export default mbssBackpack;