import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornHopliteArmoredSuit = new Armor(ARMOR.WORN_HOPLITE_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -8,
        [STAT.speedModifier]: -1,
        [STAT.maxWeightBonus]: 5,
        [STAT.bulletDmgFactor]: 91,
        [STAT.tearDmgFactor]: 91,
        [STAT.explosionDmgFactor]: 126,
        [STAT.electraDmgFactor]: 36,
        [STAT.burnDmgFactor]: 36,
        [STAT.chemicalBurnDmgFactor]: 36,
        [STAT.radiationProtection]: 150,
        [STAT.thermalProtection]: 20,
        [STAT.biologicalProtection]: 120,
        [STAT.psychoProtection]: 60,
        [STAT.bleedingProtection]: 49,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 52590,
            [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 46,
            [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 164,
            [RESOURCE.ALPHA_DATA_FRAGMENT]: 41,
            [RESOURCE.PIECE_OF_INFERNAL_COAL]: 220,
        },
        dependency: ARMOR.STRIKER_ARMORED_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default wornHopliteArmoredSuit;