import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const triZipAssaultBackpack = new Container(
    CONTAINER.TRI_ZIP_ASSAULT_BACKPACK,
    {
        [STAT.staminaBonus]: 30,
        [STAT.maxWeightBonus]: 40,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 24800,
                [RESOURCE.PIECES_OF_COPPER_WIRE]: 41,
                [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 119,
                [RESOURCE.PIECE_OF_INFERNAL_COAL]: 64,
            },
            dependency: CONTAINER.MBSS_BACKPACK,
        },
        {
            resources: {
                [RESOURCE.RUB]: 31360,
                [RESOURCE.PIECES_OF_COPPER_WIRE]: 71,
                [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 204,
                [RESOURCE.PIECE_OF_INFERNAL_COAL]: 110,
            },
        },
    ].filter(Boolean), 0, 0);

export default triZipAssaultBackpack;