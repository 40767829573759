import {ContainerData} from "../models/container";
import {ArtifactData} from "../models/artifact";
import {ArmorData} from "../models/armor";
import {CONTAINER} from "../data/container/container";
import {ARMOR} from "../data/armor/armor";
import AllContainers from "../data/container";
import AllArmor from "../data/armor";
import AllArtifacts from "../data/artifact";
import {emptyArtifactFactory} from "../data/artifact/artifact";

export type Loadout = {
    name: string;
    container: ContainerData,
    artifacts: ArtifactData[],
    armor: ArmorData
};

export const LOADOUTS_LS_KEY = 'loadouts';
export const TEMP_LOADOUT_LS_KEY = 'tempLoadout';
export const DEFAULT_LOADOUT_NAME = 'Default';

export const getLoadouts = (): Record<string, Loadout> => {
    const loadouts = localStorage.getItem(LOADOUTS_LS_KEY);
    let changed = false;
    if (loadouts) {
        try {
            const result = JSON.parse(loadouts) as Record<string, Loadout>;
            for (const key in result) {
                changed = changed || validateLoadout(result[key]);
            }

            if(changed) {
                saveLoadouts(result);
            }

            return result;
        } catch (e) {
            console.error('Error while parsing loadouts', e);
        }
    }

    return {};
};

export const saveLoadouts = (loadouts: Record<string, Loadout>) => {
    localStorage.setItem(LOADOUTS_LS_KEY, JSON.stringify(loadouts));
}


export const saveLoadout = (name: string, loadout: Loadout) => {
    const loadouts = getLoadouts();
    loadouts[name] = JSON.parse(JSON.stringify(loadout));
    saveLoadouts(loadouts);

    return loadouts;
};

export const saveTempLoadout = (loadout: Loadout) => {
    localStorage.setItem(TEMP_LOADOUT_LS_KEY, JSON.stringify(loadout));
};

export const emptyLoadoutFactory = (name = '' ): Loadout => ({
    name,
    container: {
        id: CONTAINER.NONE
    },
    artifacts: [],
    armor: {
        id: ARMOR.NONE,
        level: 0,
    }
});

export const deleteLoadout = (name: string) => {
    const loadouts = getLoadouts();
    delete loadouts[name];
    saveLoadouts(loadouts);

    return loadouts;
};

/**
 * Mutates loadout to replace old ids
 * @param loadout
 */
const validateLoadout = (loadout: Loadout): boolean => {
    let changed = false;

    if(!AllContainers[loadout.container.id]) {
        loadout.container.id = CONTAINER.BEARS_DEN_6_CONTAINER;
        changed = true;
    }
    if(!AllArmor[loadout.armor.id]) {
        loadout.armor.id = ARMOR.NONE;
        loadout.armor.level = 0;
        changed = true;
    }

    loadout.artifacts = loadout.artifacts.map(artifact => {
        if(AllArtifacts[artifact.id]) {
            return artifact;
        }

        changed = true;
        return emptyArtifactFactory();
    });

    return changed;
}

export const getTempLoadout = (): Loadout => {
    const loadout = localStorage.getItem(TEMP_LOADOUT_LS_KEY);
    if (loadout) {
        try {
            const result = JSON.parse(loadout) as Loadout;
            const changed = validateLoadout(result);
            if(changed) {
                saveTempLoadout(result);
            }

            return result;
        } catch (e) {
            console.error('Error while parsing loadout', e);
        }
    }

    const loadouts = getLoadouts();
    if (loadouts[DEFAULT_LOADOUT_NAME]) {
        return loadouts[DEFAULT_LOADOUT_NAME];
    }

    return emptyLoadoutFactory(DEFAULT_LOADOUT_NAME);
};

export const getSortedLoadouts = (loadouts = getLoadouts()): Loadout[] => {
    return Object.keys(loadouts).map(key => loadouts[key]).sort((a, b) => a.name.localeCompare(b.name));
}

