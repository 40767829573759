import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const apostleHeavyArmoredSuit = new Armor(ARMOR.APOSTLE_HEAVY_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -40,
        [STAT.speedModifier]: -20,
        [STAT.bulletDmgFactor]: 256,
        [STAT.tearDmgFactor]: 300,
        [STAT.explosionDmgFactor]: 300,
        [STAT.electraDmgFactor]: 53,
        [STAT.burnDmgFactor]: 53,
        [STAT.chemicalBurnDmgFactor]: 53,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 50,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 170,
        [STAT.bleedingProtection]: 75,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 4141130,
            [RESOURCE.QUANTUM_BATTERY]: 2140,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 760,
            [RESOURCE.RED_FERN]: 2380,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 1490,
            [RESOURCE.SUBSTANCE_07270]: 4780,
            [RESOURCE.ANOMALOUS_SERUM]: 90,
        },
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default apostleHeavyArmoredSuit;