import ScientistArmor from "./scientist";
import CombatArmor from "./combat";
import {ARMOR, ARMOR_CONTAINER_TYPE, NO_ARMOR} from "./armor";
import Armor from "../../models/armor";
import DiscovererArmor from "./discoverer";
import CombinedArmor from "./combined";

const AllArmor: Record<ARMOR, Armor> = {
    ...ScientistArmor,
    ...CombatArmor,
    ...DiscovererArmor,
    ...CombinedArmor,
    [NO_ARMOR.NONE]: new Armor(NO_ARMOR.NONE, {}, [], ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER)
}

export default AllArmor;