import {
    COMPACT_CONTAINER,
    CONTAINER,
    CONTAINER_TYPE, SPACIOUS_CONTAINER, NO_CONTAINER,
    MEDIUM_BACKPACK,
    LIGHT_BACKPACK, STANDARD_CONTAINER
} from "../data/container/container";
import {Recipe} from "./resource";
import {Stats} from "./stats";
import Craftable from "./craftable";
import {Protection} from "../constants/base";

export type ContainerData = {
    id: CONTAINER,
}

class Container extends Craftable {
    public readonly type: CONTAINER_TYPE;

    constructor(id: CONTAINER, stats: Stats, recipes: Recipe[], public readonly capacity: number, public readonly protection: Protection) {
        super(id, stats, recipes);

        this.type = this.getContainerType(id);
    }

    private getContainerType(id: CONTAINER): CONTAINER_TYPE {
        switch (true) {
            // no container counts as light backpack to avoid filtering
            case NO_CONTAINER.hasOwnProperty(id):
            case LIGHT_BACKPACK.hasOwnProperty(id):
                return CONTAINER_TYPE.LIGHT_BACKPACK;
            case MEDIUM_BACKPACK.hasOwnProperty(id):
                return CONTAINER_TYPE.MEDIUM_BACKPACK;
            case COMPACT_CONTAINER.hasOwnProperty(id):
                return CONTAINER_TYPE.COMPACT_CONTAINER;
            case STANDARD_CONTAINER.hasOwnProperty(id):
                return CONTAINER_TYPE.STANDARD_CONTAINER;
            case SPACIOUS_CONTAINER.hasOwnProperty(id):
                return CONTAINER_TYPE.SPACIOUS_CONTAINER;
            default:
                return CONTAINER_TYPE.BULKY_CONTAINER;

        }
    }
}

export default Container;