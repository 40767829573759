import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";

const kzs1Container = new Container(
    CONTAINER.KZS_1_CONTAINER,
    {
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 4040,
                [RESOURCE.STINKY_ROOT]: 28,
                [RESOURCE.SEEDS]: 22,
            },
        },
    ].filter(Boolean), 1, 0.75);

export default kzs1Container;