import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const voronkaYantar = new Artifact(ARTIFACT.VORONKA_YANTAR, {
    [STAT.staminaBonus]: [5, 15],
    [STAT.maxWeightBonus]: [10, 25],
    [STAT.radiationAccumulation]: [-2, -5],
    [STAT.psychoAccumulation]: [-2, -5],
}, {
    [STAT.maxWeightBonus]: [7.5, 12.5],
    [STAT.radiationAccumulation]: [0.5, 1.25],
    [STAT.psychoAccumulation]: [0.5, 1.25],
});

export default voronkaYantar;