import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const rapsAh5Pilgrim = new Armor(ARMOR.RAPS_AH_5_PILGRIM,
    {
        [STAT.staminaBonus]: -10,
        [STAT.speedModifier]: -2,
        [STAT.maxWeightBonus]: 15,
        [STAT.artefaktHeal]: 2.5,
        [STAT.bulletDmgFactor]: 88,
        [STAT.tearDmgFactor]: 103,
        [STAT.explosionDmgFactor]: 124,
        [STAT.electraDmgFactor]: 593,
        [STAT.burnDmgFactor]: 593,
        [STAT.chemicalBurnDmgFactor]: 593,
        [STAT.radiationProtection]: 225,
        [STAT.thermalProtection]: 225,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 210,
        [STAT.bleedingProtection]: 47,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 699740,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 700,
            [RESOURCE.RED_FERN]: 620,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 570,
            [RESOURCE.SUBSTANCE_07270]: 1020,
        },
        dependency: ARMOR.RAPS_AH_4_RAIDER,
    },
    {
        resources: {
            [RESOURCE.RUB]: 699740,
            [RESOURCE.LIMBOPLASMA]: 9,
            [RESOURCE.BITTERLEAF]: 830,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 188,
            [RESOURCE.LIM]: 660,
        },
        dependency: ARMOR.RAPS_AH_4_RAIDER,
    },
    {
        resources: {
            [RESOURCE.RUB]: 382580,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 390,
            [RESOURCE.RED_FERN]: 340,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 310,
            [RESOURCE.SUBSTANCE_07270]: 560,
        },
        dependency: ARMOR.WORN_AH_5_PILGRIM,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default rapsAh5Pilgrim;