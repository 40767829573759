import React from "react";
import {ArtifactData} from "../../../models/artifact";
import {Accordion} from "react-bootstrap";
import {Rarity} from "../../../constants/base";
import ArtifactComponent from "./artifact";
import {useTranslation} from "react-i18next";
import {ARTIFACT} from "../../../data/artifact/artifact";
import {getArtifactTKey} from "../../../utils/search";

export type ArtifactListProps = {
    artifacts: ArtifactData[];
    setArtifactFactory: (index: number) => (artifacts: ArtifactData) => void;
}

export const ARTIFACT_LIST_ITEM_TEST_ID = 'artifact-list-item';
export const ARTIFACT_LIST_BUTTON_TEST_ID = 'artifact-list-button';

const ArtifactList: React.FC<ArtifactListProps> = ({artifacts, setArtifactFactory}) => {
    const {t} = useTranslation();

    return (
        <Accordion>
            {artifacts.map((artifact, index) => (
                <Accordion.Item
                    key={`${artifact.id}-${index}`}
                    eventKey={`artifact-${index}`}
                    className='rounded-top-0'
                    data-testid={ARTIFACT_LIST_ITEM_TEST_ID}
                >
                    {artifact.id !== ARTIFACT.NONE ?
                        <Accordion.Button className={`bg-${artifact.rarity}`} data-testid={ARTIFACT_LIST_BUTTON_TEST_ID}>
                            <h5 className='mb-0 me-2'>{t('artifact.search.index', {index: index + 1})}</h5>
                            {t('artifact.search.selectedArtifact',
                                {
                                    name: t(getArtifactTKey(artifact)),
                                    level: artifact.level,
                                    quality: artifact.quality,
                                    rarity: artifact.rarity
                                })
                            }
                        </Accordion.Button> :
                        <Accordion.Button className={`bg-${Rarity.COMMON}`} data-testid={ARTIFACT_LIST_BUTTON_TEST_ID}>
                            <h5 className='mb-0 me-2'>{t('artifact.search.index', {index: index + 1})}</h5>
                            {t('artifact.search.noArtifactSelected')}
                        </Accordion.Button>
                    }
                    <Accordion.Body className={'p-0'}>
                        <ArtifactComponent artifact={artifact} setArtifactFactory={setArtifactFactory} index={index} slots={artifacts.length}/>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>);
};

export default ArtifactList;