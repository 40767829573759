import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const ah1DrifterSuit = new Armor(ARMOR.AH_1_DRIFTER_SUIT,
    {
        [STAT.bulletDmgFactor]: 26,
        [STAT.tearDmgFactor]: 31,
        [STAT.explosionDmgFactor]: 37,
        [STAT.electraDmgFactor]: 178,
        [STAT.burnDmgFactor]: 178,
        [STAT.chemicalBurnDmgFactor]: 178,
        [STAT.radiationProtection]: 100,
        [STAT.thermalProtection]: 100,
        [STAT.biologicalProtection]: 100,
        [STAT.psychoProtection]: 20,
        [STAT.bleedingProtection]: 21,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 5620,
            [RESOURCE.STINKY_ROOT]: 27,
            [RESOURCE.SEEDS]: 18,
        },
    },
    {
        resources: {
            [RESOURCE.RUB]: 2510,
            [RESOURCE.STINKY_ROOT]: 12,
            [RESOURCE.SEEDS]: 8,
        },
        dependency: ARMOR.PS_1_PICKLOCK,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default ah1DrifterSuit;