import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const mis113Iolite = new Armor(ARMOR.MIS_113_IOLITE,
    {
        [STAT.bulletDmgFactor]: 49,
        [STAT.tearDmgFactor]: 55,
        [STAT.explosionDmgFactor]: 67,
        [STAT.electraDmgFactor]: 1333,
        [STAT.burnDmgFactor]: 556,
        [STAT.chemicalBurnDmgFactor]: 556,
        [STAT.radiationProtection]: 400,
        [STAT.thermalProtection]: 310,
        [STAT.biologicalProtection]: 400,
        [STAT.psychoProtection]: 340,
        [STAT.bleedingProtection]: 35,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 184510,
            [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 820,
            [RESOURCE.ZONSHROOM]: 850,
            [RESOURCE.BETA_DATA_FRAGMENT]: 310,
            [RESOURCE.CHLORINEX]: 970,
        },
    },
    {
        resources: {
            [RESOURCE.RUB]: 59120,
            [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 480,
            [RESOURCE.ZONSHROOM]: 500,
            [RESOURCE.BETA_DATA_FRAGMENT]: 179,
            [RESOURCE.CHLORINEX]: 570,
        },
        dependency: ARMOR.MIS_99_MALACHITE,
    },
    {
        resources: {
            [RESOURCE.RUB]: 43120,
            [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 125,
            [RESOURCE.ZONSHROOM]: 130,
            [RESOURCE.BETA_DATA_FRAGMENT]: 47,
            [RESOURCE.CHLORINEX]: 148,
        },
        dependency: ARMOR.WORN_IOLITE,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default mis113Iolite;