import {LIGHT_BACKPACK} from "../container";
import Container from "../../../models/container";
import transformerBag from "./transformer-bag";
import sportsBag from "./sports-bag";

const LightBackpacks: Record<LIGHT_BACKPACK, Container> = {
    [LIGHT_BACKPACK.SPORTS_BAG]: sportsBag,
    [LIGHT_BACKPACK.TRANSFORMER_BAG]: transformerBag,
}

export default LightBackpacks;