import {BIOCHEMICAL_ARTIFACT} from "../artifact";
import Artifact from "../../../models/artifact";
import kiselCrystal from "./kisel-crystal";
import kiselKisel from "./kisel-kisel";
import kiselSliz from "./kisel-sliz";
import kiselSliznyak from "./kisel-sliznyak";
import kiselSlyda from "./kisel-slyda";
import kiselSvetlyak from "./kisel-svetlyak";
import kiselUlitka from "./kisel-ulitka";
import kiselPuzir from "./kisel-puzir";
import kiselPlenka from "./kisel-plenka";
import kiselPolyhedron from "./kisel-polyhedron";
import puhKoluchka from "./puh-koluchka";
import puhEj from "./puh-ej";
import puhKolobok from "./puh-kolobok";
import puhSteelkolobok from "./puh-steelkolobok";
import puhKkoluchka from "./puh-kkoluchka";

const BioArtifacts: Record<BIOCHEMICAL_ARTIFACT, Artifact> = {
    [BIOCHEMICAL_ARTIFACT.KISEL_CRYSTAL]: kiselCrystal,
    [BIOCHEMICAL_ARTIFACT.KISEL_KISEL]: kiselKisel,
    [BIOCHEMICAL_ARTIFACT.KISEL_SLIZ]: kiselSliz,
    [BIOCHEMICAL_ARTIFACT.KISEL_SLIZNYAK]: kiselSliznyak,
    [BIOCHEMICAL_ARTIFACT.KISEL_SLYDA]: kiselSlyda,
    [BIOCHEMICAL_ARTIFACT.KISEL_SVETLYAK]: kiselSvetlyak,
    [BIOCHEMICAL_ARTIFACT.KISEL_ULITKA]: kiselUlitka,
    [BIOCHEMICAL_ARTIFACT.KISEL_PUZIR]: kiselPuzir,
    [BIOCHEMICAL_ARTIFACT.KISEL_PLENKA]: kiselPlenka,
    [BIOCHEMICAL_ARTIFACT.KISEL_POLYHEDRON]: kiselPolyhedron,
    [BIOCHEMICAL_ARTIFACT.PUH_KOLUCHKA]: puhKoluchka,
    [BIOCHEMICAL_ARTIFACT.PUH_KKOLUCHKA]: puhKkoluchka,
    [BIOCHEMICAL_ARTIFACT.PUH_EJ]: puhEj,
    [BIOCHEMICAL_ARTIFACT.PUH_KOLOBOK]: puhKolobok,
    [BIOCHEMICAL_ARTIFACT.PUH_STEELKOLOBOK]: puhSteelkolobok,

}

export default BioArtifacts;