import {Nav} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const CraftNavbar = () => {
    const {t} = useTranslation();

    return (
        <Nav activeKey='/' >
            <Nav.Item><Nav.Link as={Link} to={'/craft'}>{t('craft.navbar.overview')}</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link as={Link} to={'/craft/armor'}>{t('craft.navbar.armor')}</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link as={Link} to={'/craft/weapon'}>{t('craft.navbar.weapon')}</Nav.Link></Nav.Item>
        </Nav>)
}

export default CraftNavbar;