import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const muleExoskeleton = new Armor(ARMOR.MULE_EXOSKELETON,
    {
        [STAT.staminaBonus]: -15,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 45,
        [STAT.bulletDmgFactor]: 160,
        [STAT.tearDmgFactor]: 160,
        [STAT.explosionDmgFactor]: 229,
        [STAT.electraDmgFactor]: 107,
        [STAT.burnDmgFactor]: 107,
        [STAT.chemicalBurnDmgFactor]: 107,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 80,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 200,
        [STAT.bleedingProtection]: 65,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 2201030,
            [RESOURCE.QUANTUM_BATTERY]: 860,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 820,
            [RESOURCE.RED_FERN]: 1280,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 600,
            [RESOURCE.SUBSTANCE_07270]: 3840,
            [RESOURCE.ANOMALOUS_SERUM]: 75,
        },
        dependency: ARMOR.WORN_MULE,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_STANDARD_CONTAINER,
)

export default muleExoskeleton;