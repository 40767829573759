import {ITEM} from "../data/item";
import {Stats, StatsHash, StatsKey} from "./stats";
import {Level} from "../constants/base";
import {getArmorValueAfterLevelModified} from "../utils/calculation";

class Base {
    protected readonly dynamicallyChangedStats: StatsHash = {};

    constructor(public id: ITEM, private readonly stats: Stats) {}

    public getStats(level: Level = 0): Stats {
        const result: Stats = {};
        for (const key of Object.keys(this.stats) as StatsKey[]) {
            const value = this.stats[key];
            if (value === undefined) continue;

            if (this.dynamicallyChangedStats[key]) {
                result[key] = getArmorValueAfterLevelModified(value, level);
            } else {
                result[key] = value;
            }
        }

        return result;
    }
}

export default Base;