import Base from "./base";
import {Recipe} from "./resource";
import {Stats} from "./stats";
import {ITEM} from "../data/item";

class Craftable extends Base {
    constructor(public id: ITEM, stats: Stats, public recipes: Recipe[]) {
        super(id, stats);
    }
}

export default Craftable;