import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const damagedSkat9m = new Armor(ARMOR.DAMAGED_SKAT_9_M,
    {
        [STAT.staminaBonus]: -6,
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 112,
        [STAT.tearDmgFactor]: 121,
        [STAT.explosionDmgFactor]: 163,
        [STAT.electraDmgFactor]: 281,
        [STAT.burnDmgFactor]: 281,
        [STAT.chemicalBurnDmgFactor]: 281,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 90,
        [STAT.biologicalProtection]: 210,
        [STAT.psychoProtection]: 160,
        [STAT.bleedingProtection]: 58,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default damagedSkat9m;