import React, {useCallback, useEffect, useState} from "react";
import Artifact, {ArtifactData} from "../../../../models/artifact";
import {Container, Form} from "react-bootstrap";
import AllArtifacts from "../../../../data/artifact";
import {StatsKey} from "../../../../models/stats";
import {useTranslation} from "react-i18next";
import ArtifactDataFactory from "../../../../models/artifact-data-factory";

type ArtifactAdditionalStatsProps = {
    artifact: ArtifactData | null,
    setArtifact: (artifact: ArtifactData) => void,
    index: number
}

export const ARTIFACT_ADDITIONAL_STATS_TEST_ID = 'artifact-additional-stats';

const ArtifactAdditionalStats: React.FC<ArtifactAdditionalStatsProps> = ({ artifact, setArtifact, index}) => {
    const {t} = useTranslation();
    const [additionalStats, setAdditionalStats] = useState<StatsKey[]>([]);

    useEffect(() => {
        if(!artifact) return;
        const newArtifact = AllArtifacts[artifact.id];
        setAdditionalStats(Object.keys(newArtifact.additionalStats) as StatsKey[]);
    }, [artifact]);

    const isStatChecked = useCallback((stat: StatsKey) => {
        return artifact?.additionalStats.includes(stat);
    }, [artifact]);

    const isStatDisabled = useCallback((stat: StatsKey) => {
        // If the checkbox is displayed it means that artifact exists
        const art = artifact as ArtifactData;
        if (isStatChecked(stat)) return false;
        const currentStatsSelected = art.additionalStats.length;
        const maxAllowedStats =  Artifact.getAllowedAdditionalStatsCount(art.level);
        return currentStatsSelected >= maxAllowedStats;
    }, [artifact, isStatChecked]);

    const onStatChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const stat = event.target.value as StatsKey;
        const checked = event.target.checked;

        if (checked) {
            setArtifact(ArtifactDataFactory.addAdditionalStat(artifact as ArtifactData, stat));
        } else {
            setArtifact(ArtifactDataFactory.removeAdditionalStat(artifact as ArtifactData, stat));
        }
    }, [artifact, setArtifact]);

    if(!artifact || artifact.level < 5) return null;

    return (
        <Container className="mb-2" data-testid={ARTIFACT_ADDITIONAL_STATS_TEST_ID}>
            {additionalStats.map((stat) => (
                <Form.Check
                    type='checkbox'
                    checked={isStatChecked(stat)}
                    disabled={isStatDisabled(stat)}
                    value={stat}
                    onChange={onStatChange}
                    id={`art-${index}-stat-${stat}`}
                    data-testid={`art-${index}-stat-${stat}`}
                    key={stat}
                    label={t(`stats.${stat}`)}
                />
            ))}
        </Container>
    );
};

export default ArtifactAdditionalStats;