import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornAh4Raider = new Armor(ARMOR.WORN_AH_4_RAIDER,
    {
        [STAT.staminaBonus]: -10,
        [STAT.speedModifier]: -2,
        [STAT.maxWeightBonus]: 10,
        [STAT.artefaktHeal]: 1.75,
        [STAT.bulletDmgFactor]: 70,
        [STAT.tearDmgFactor]: 82,
        [STAT.explosionDmgFactor]: 99,
        [STAT.electraDmgFactor]: 474,
        [STAT.burnDmgFactor]: 474,
        [STAT.chemicalBurnDmgFactor]: 474,
        [STAT.radiationProtection]: 210,
        [STAT.thermalProtection]: 195,
        [STAT.biologicalProtection]: 240,
        [STAT.psychoProtection]: 195,
        [STAT.bleedingProtection]: 44,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 108580,
            [RESOURCE.REMAINS_OF_SCIENTIFIC_EQUIPMENT]: 248,
            [RESOURCE.ZONSHROOM]: 360,
            [RESOURCE.BETA_DATA_FRAGMENT]: 120,
            [RESOURCE.CHLORINEX]: 410,
        },
        dependency: ARMOR.AH_3_SEEKER_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default wornAh4Raider;