import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornHawkArmoredSuit = new Armor(ARMOR.WORN_HAWK_ARMORED_SUIT,
    {
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 75,
        [STAT.tearDmgFactor]: 80,
        [STAT.explosionDmgFactor]: 114,
        [STAT.electraDmgFactor]: 53,
        [STAT.burnDmgFactor]: 53,
        [STAT.chemicalBurnDmgFactor]: 53,
        [STAT.radiationProtection]: 150,
        [STAT.thermalProtection]: 90,
        [STAT.biologicalProtection]: 150,
        [STAT.psychoProtection]: 100,
        [STAT.bleedingProtection]: 46,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 53590,
            [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 115,
            [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 247,
            [RESOURCE.ALPHA_DATA_FRAGMENT]: 31,
            [RESOURCE.PIECE_OF_INFERNAL_COAL]: 133,
        },
        dependency: ARMOR.TOURIST_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 53590,
            [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 115,
            [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 247,
            [RESOURCE.ALPHA_DATA_FRAGMENT]: 31,
            [RESOURCE.PIECE_OF_INFERNAL_COAL]: 133,
        },
        dependency: ARMOR.KLEPTOMANIAC_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default wornHawkArmoredSuit;