import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornAh3SeekerSuit = new Armor(ARMOR.WORN_AH_3_SEEKER_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 3,
        [STAT.artefaktHeal]: 1.25,
        [STAT.bulletDmgFactor]: 53,
        [STAT.tearDmgFactor]: 62,
        [STAT.explosionDmgFactor]: 74,
        [STAT.electraDmgFactor]: 356,
        [STAT.burnDmgFactor]: 356,
        [STAT.chemicalBurnDmgFactor]: 356,
        [STAT.radiationProtection]: 180,
        [STAT.thermalProtection]: 180,
        [STAT.biologicalProtection]: 195,
        [STAT.psychoProtection]: 150,
        [STAT.bleedingProtection]: 38,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 56820,
            [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 164,
            [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 222,
            [RESOURCE.ALPHA_DATA_FRAGMENT]: 38,
            [RESOURCE.PIECE_OF_INFERNAL_COAL]: 119,
        },
        dependency: ARMOR.AH_2_NOMAD_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 19080,
            [RESOURCE.REMAINS_OF_A_RADIO_TRANSMITTER]: 74,
            [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 100,
            [RESOURCE.ALPHA_DATA_FRAGMENT]: 17,
            [RESOURCE.PIECE_OF_INFERNAL_COAL]: 54,
        },
        dependency: ARMOR.DAMAGED_AH_3_SEEKER_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default wornAh3SeekerSuit;