import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const jarkaCrystal = new Artifact(ARTIFACT.JARKA_CRYSTAL, {
    [STAT.regenerationBonus]: [-0.5, -1],
    [STAT.reactionToBurn]: [1, 3],
    [STAT.thermalAccumulation]: [1, 2],
}, {
    [STAT.burnDmgFactor]: [5, 10],
    [STAT.reactionToBurn]: [0.5, 1.5],
    [STAT.regenerationBonus]: [0.25, 0.5],
});

export default jarkaCrystal;