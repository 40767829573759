import {
    CONTAINER,
    NO_CONTAINER,
    LIGHT_BACKPACK,
    COMPACT_CONTAINER,
    STANDARD_CONTAINER,
    SPACIOUS_CONTAINER, BULKY_CONTAINER, MEDIUM_BACKPACK, HEAVY_BACKPACK
} from "./container";
import Container from "../../models/container";
import CompactContainers from "./compact";
import StandardContainers from "./standard";
import SpaciousContainers from "./spacious";
import MediumBackpacks from "./medium";
import LightBackpacks from "./light";
import BulkyContainers from "./bulky";
import HeavyBackpacks from "./heavy";

const AllContainers: Record<CONTAINER, Container> = {
    ...CompactContainers,
    ...StandardContainers,
    ...SpaciousContainers,
    ...BulkyContainers,
    ...LightBackpacks,
    ...MediumBackpacks,
    ...HeavyBackpacks,
    [NO_CONTAINER.NONE]: new Container(NO_CONTAINER.NONE, {}, [], 0, 0),
}

export default AllContainers;

export const LightBagsCompactContainers: Record<LIGHT_BACKPACK | COMPACT_CONTAINER, Container> = {
    ...CompactContainers,
    ...LightBackpacks,
}

export const LightBagsCompactContainersList = Object.values(LightBagsCompactContainers);

export const LightBagsStandardContainers: Record<LIGHT_BACKPACK | COMPACT_CONTAINER | STANDARD_CONTAINER, Container> = {
    ...CompactContainers,
    ...StandardContainers,
    ...LightBackpacks,
}

export const LightBagsStandardContainersList = Object.values(LightBagsStandardContainers);

export const LightBagsSpaciousContainers: Record<LIGHT_BACKPACK | COMPACT_CONTAINER | STANDARD_CONTAINER | SPACIOUS_CONTAINER, Container> = {
    ...CompactContainers,
    ...StandardContainers,
    ...SpaciousContainers,
    ...LightBackpacks,
}

export const LightBagsSpaciousContainersList = Object.values(LightBagsSpaciousContainers);

export const LightBagsAllContainers: Record<LIGHT_BACKPACK | COMPACT_CONTAINER | STANDARD_CONTAINER | SPACIOUS_CONTAINER | BULKY_CONTAINER, Container> = {
    ...CompactContainers,
    ...StandardContainers,
    ...SpaciousContainers,
    ...BulkyContainers,
    ...LightBackpacks,
}

export const LightBagsAllContainersList = Object.values(LightBagsAllContainers);

export const MediumBagsCompactContainers: Record<LIGHT_BACKPACK | MEDIUM_BACKPACK | COMPACT_CONTAINER, Container> = {
    ...CompactContainers,
    ...LightBackpacks,
    ...MediumBackpacks,
}

export const MediumBagsCompactContainersList = Object.values(MediumBagsCompactContainers)

export const MediumBagsStandardContainers: Record<LIGHT_BACKPACK | MEDIUM_BACKPACK | COMPACT_CONTAINER | STANDARD_CONTAINER, Container> = {
    ...CompactContainers,
    ...StandardContainers,
    ...LightBackpacks,
    ...MediumBackpacks,
}

export const MediumBagsStandardContainersList = Object.values(MediumBagsStandardContainers)

export const MediumBagsSpaciousContainers: Record<LIGHT_BACKPACK | MEDIUM_BACKPACK | COMPACT_CONTAINER | STANDARD_CONTAINER | SPACIOUS_CONTAINER, Container> = {
    ...CompactContainers,
    ...StandardContainers,
    ...SpaciousContainers,
    ...LightBackpacks,
    ...MediumBackpacks,
}

export const MediumBagsSpaciousContainersList = Object.values(MediumBagsSpaciousContainers)

export const MediumBagsAllContainers: Record<LIGHT_BACKPACK | MEDIUM_BACKPACK | COMPACT_CONTAINER | STANDARD_CONTAINER | SPACIOUS_CONTAINER | BULKY_CONTAINER, Container> = {
    ...CompactContainers,
    ...StandardContainers,
    ...SpaciousContainers,
    ...BulkyContainers,
    ...LightBackpacks,
    ...MediumBackpacks,
}

export const MediumBagsAllContainersList = Object.values(MediumBagsAllContainers)


export const AllBagsCompactContainers: Record<LIGHT_BACKPACK | MEDIUM_BACKPACK | HEAVY_BACKPACK | COMPACT_CONTAINER, Container> = {
    ...CompactContainers,
    ...LightBackpacks,
    ...MediumBackpacks,
    ...HeavyBackpacks
}

export const AllBagsCompactContainersList = Object.values(AllBagsCompactContainers)

export const AllBagsStandardContainers: Record<LIGHT_BACKPACK | MEDIUM_BACKPACK | HEAVY_BACKPACK | COMPACT_CONTAINER | STANDARD_CONTAINER, Container> = {
    ...CompactContainers,
    ...StandardContainers,
    ...LightBackpacks,
    ...MediumBackpacks,
    ...HeavyBackpacks
}

export const AllBagsStandardContainersList = Object.values(AllBagsStandardContainers)

export const AllBagsSpaciousContainers: Record<LIGHT_BACKPACK | MEDIUM_BACKPACK | COMPACT_CONTAINER | STANDARD_CONTAINER | SPACIOUS_CONTAINER, Container> = {
    ...CompactContainers,
    ...StandardContainers,
    ...SpaciousContainers,
    ...LightBackpacks,
    ...MediumBackpacks,
}

export const AllBagsSpaciousContainersList = Object.values(AllBagsSpaciousContainers)

