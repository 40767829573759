import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const legionnaireHeavyArmoredSuit = new Armor(ARMOR.LEGIONNAIRE_HEAVY_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 8,
        [STAT.bulletDmgFactor]: 136,
        [STAT.tearDmgFactor]: 136,
        [STAT.explosionDmgFactor]: 189,
        [STAT.electraDmgFactor]: 53,
        [STAT.burnDmgFactor]: 53,
        [STAT.chemicalBurnDmgFactor]: 53,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 30,
        [STAT.biologicalProtection]: 150,
        [STAT.psychoProtection]: 140,
        [STAT.bleedingProtection]: 59,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 592720,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 68,
            [RESOURCE.RED_FERN]: 430,
            [RESOURCE.GAMMA_DATA_FRAGMENT]: 360,
            [RESOURCE.SUBSTANCE_07270]: 740,
        },
        dependency: ARMOR.WORN_LEGIONNAIRE,
    },
    {
        resources: {
            [RESOURCE.RUB]: 592720,
            [RESOURCE.LIMBOPLASMA]: 7,
            [RESOURCE.BITTERLEAF]: 172,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 59,
            [RESOURCE.LIM]: 550,
        },
        dependency: ARMOR.WORN_LEGIONNAIRE,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default legionnaireHeavyArmoredSuit;