import {ELECTROPHYSICAL_ARTIFACT} from "../artifact";
import Artifact from "../../../models/artifact";
import electraSnowflake from "./electra-snowflake";
import electraCrystal from "./electra-crystal";
import electraBengal from "./electra-bengal";
import electraVspyshka from "./electra-vspyshka";
import electraBatareyka from "./electra-batareyka";
import electraPustishka from "./electra-pustishka";
import electraDezintegrator from "./electra-dezintegrator";
import electraMoonlight from "./electra-moonlight";
import electraHelium from "./electra-helium";
import puzirSpiral from "./puzir-spiral";
import puzirZerkalo from "./puzir-zerkalo";
import puzirOskolok from "./puzir-oskolok";
import puzirPrisma from "./puzir-prisma";
import puzirAtom from "./puzir-atom";

const ElectrophysicalArtifacts: Record<ELECTROPHYSICAL_ARTIFACT, Artifact> = {
    [ELECTROPHYSICAL_ARTIFACT.ELECTRA_SNOWFLAKE]: electraSnowflake,
    [ELECTROPHYSICAL_ARTIFACT.ELECTRA_CRYSTAL]: electraCrystal,
    [ELECTROPHYSICAL_ARTIFACT.ELECTRA_BENGAL]: electraBengal,
    [ELECTROPHYSICAL_ARTIFACT.ELECTRA_VSPYSHKA]: electraVspyshka,
    [ELECTROPHYSICAL_ARTIFACT.ELECTRA_BATAREYKA]: electraBatareyka,
    [ELECTROPHYSICAL_ARTIFACT.ELECTRA_PUSTISHKA]: electraPustishka,
    [ELECTROPHYSICAL_ARTIFACT.ELECTRA_DEZINTEGRATOR]: electraDezintegrator,
    [ELECTROPHYSICAL_ARTIFACT.ELECTRA_MOONLIGHT]: electraMoonlight,
    [ELECTROPHYSICAL_ARTIFACT.ELECTRA_HELIUM]: electraHelium,
    [ELECTROPHYSICAL_ARTIFACT.PUZIR_SPIRAL]: puzirSpiral,
    [ELECTROPHYSICAL_ARTIFACT.PUZIR_ZERKALO]: puzirZerkalo,
    [ELECTROPHYSICAL_ARTIFACT.PUZIR_OSKOLOK]: puzirOskolok,
    [ELECTROPHYSICAL_ARTIFACT.PUZIR_PRISMA]: puzirPrisma,
    [ELECTROPHYSICAL_ARTIFACT.PUZIR_ATOM]: puzirAtom,

}

export default ElectrophysicalArtifacts;