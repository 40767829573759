import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const reportersArmor = new Armor(ARMOR.REPORTERS_ARMOR,
    {
        [STAT.staminaBonus]: -8,
        [STAT.speedModifier]: -1,
        [STAT.maxWeightBonus]: 5,
        [STAT.bulletDmgFactor]: 106,
        [STAT.tearDmgFactor]: 106,
        [STAT.explosionDmgFactor]: 147,
        [STAT.electraDmgFactor]: 41,
        [STAT.burnDmgFactor]: 41,
        [STAT.chemicalBurnDmgFactor]: 41,
        [STAT.radiationProtection]: 170,
        [STAT.thermalProtection]: 20,
        [STAT.biologicalProtection]: 140,
        [STAT.psychoProtection]: 75,
        [STAT.bleedingProtection]: 52,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default reportersArmor;