import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const ps1Picklock = new Armor(ARMOR.PS_1_PICKLOCK,
    {
        [STAT.staminaBonus]: -5,
        [STAT.maxWeightBonus]: 3,
        [STAT.bulletDmgFactor]: 17,
        [STAT.tearDmgFactor]: 21,
        [STAT.explosionDmgFactor]: 27,
        [STAT.electraDmgFactor]: 89,
        [STAT.burnDmgFactor]: 89,
        [STAT.chemicalBurnDmgFactor]: 89,
        [STAT.radiationProtection]: 100,
        [STAT.thermalProtection]: 30,
        [STAT.biologicalProtection]: 100,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 12,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 1980,
            [RESOURCE.GREEN_MOLD]: 12,
            [RESOURCE.SWAMP_STONE]: 9,
        },
        dependency: ARMOR.GORKA_3_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default ps1Picklock;