import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const albatrossInfiltratorArmoredExoskeleton = new Armor(ARMOR.ALBATROSS_INFILTRATOR_ARMORED_EXOSKELETON,
    {
        [STAT.speedModifier]: 6,
        [STAT.maxWeightBonus]: 12,
        [STAT.radiationAccumulation]: 2,
        [STAT.bleedingAccumulation]: 0.5,
        [STAT.bulletDmgFactor]: 85,
        [STAT.tearDmgFactor]: 121,
        [STAT.explosionDmgFactor]: 143,
        [STAT.electraDmgFactor]: 278,
        [STAT.burnDmgFactor]: 278,
        [STAT.chemicalBurnDmgFactor]: 278,
        [STAT.radiationProtection]: 240,
        [STAT.thermalProtection]: 120,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 240,
        [STAT.bleedingProtection]: 56,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default albatrossInfiltratorArmoredExoskeleton;