import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const trampolineKamencvet = new Artifact(ARTIFACT.TRAMPOLINE_KAMENCVET, {
    [STAT.psychoAccumulation]: [-4, -6],
    [STAT.explosionDmgFactor]: [15, 25],
}, {
    [STAT.explosionDmgFactor]: [3.5, 6.5],
    [STAT.maxWeightBonus]: [2.5, 5],
    [STAT.psychoAccumulation]: [1, 1.5],
});

export default trampolineKamencvet;