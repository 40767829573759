import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const voronkaKriogen = new Artifact(ARTIFACT.VORONKA_KRIOGEN, {
    [STAT.staminaBonus]: [-10, -20],
    [STAT.regenerationBonus]: [-1, -2.5],
    [STAT.thermalDmgFactor]: [10, 25],
    [STAT.thermalAccumulation]: [4, 7],
}, {
    [STAT.thermalDmgFactor]: [5, 12.5],
    [STAT.regenerationBonus]: [0.4, 1],
    [STAT.staminaBonus]: [4, 8],
});

export default voronkaKriogen;