import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const blizzardCrust = new Artifact(ARTIFACT.BLIZZARD_CRUST, {
    [STAT.staminaBonus]: [-25, -50],
    [STAT.bulletDmgFactor]: [15, 20],
    [STAT.tearDmgFactor]: [15, 20],
}, {
    [STAT.burnDmgFactor]: [15, 20],
    [STAT.tearDmgFactor]: [3.5, 5],
    [STAT.staminaBonus]: [10, 20],
});

export default blizzardCrust;