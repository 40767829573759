import {floatFix} from "../utils/calculation";

export enum STAT {
    artefactDamage = 'artefact_damage',
    artefactHeal = 'artefact_heal',
    biologicalAccumulation = 'biological_accumulation',
    biologicalDmgFactor = 'biological_dmg_factor',
    biologicalProtection = 'biological_protection',
    biologicalProtectionShort = 'biological_protection_short',
    bleedingAccumulation = 'bleeding_accumulation',
    bleedingDmgFactor = 'bleeding_dmg_factor',
    bleedingProtection = 'bleeding_protection',
    bulletDeflection = 'bullet_deflection',
    bulletDmgFactor = 'bullet_dmg_factor',
    bulletSlowdown = 'bullet_slowdown',
    burnDmgFactor = 'burn_dmg_factor',
    chemicalBurnDmgFactor = 'chemical_burn_dmg_factor',
    combustionAccumulation = 'combustion_accumulation',
    combustionDmgFactor = 'combustion_dmg_factor',
    combustionProtection = 'combustion_protection',
    electraDmgFactor = 'electra_dmg_factor',
    explosionDmgFactor = 'explosion_dmg_factor',
    fallProtection = 'fall_protection',
    frostAccumulation = 'frost_accumulation',
    frostDmgFactor = 'frost_dmg_factor',
    frostProtection = 'frost_protection',
    healEfficiency = 'heal_efficiency',
    healthBonus = 'health_bonus',
    jumpIncrease = 'jump_increase',
    maxWeightBonus = 'max_weight_bonus',
    psychoAccumulation = 'psycho_accumulation',
    psychoDmgFactor = 'psycho_dmg_factor',
    psychoProtection = 'psycho_protection',
    psychoProtectionShort = 'psycho_protection_short',
    radiationAccumulation = 'radiation_accumulation',
    radiationDmgFactor = 'radiation_dmg_factor',
    radiationProtection = 'radiation_protection',
    radiationProtectionShort = 'radiation_protection_short',
    reactionToBurn = 'reaction_to_burn',
    reactionToChemicalBurn = 'reaction_to_chemical_burn',
    reactionToElectroshock = 'reaction_to_electroshock',
    reactionToTear = 'reaction_to_tear',
    recoilBonus = 'recoil_bonus',
    regenerationBonus = 'regeneration_bonus',
    reloadBonus = 'reload_bonus',
    speedModifier = 'speed_modifier',
    spreadBonus = 'spread_bonus',
    staminaBonus = 'stamina_bonus',
    staminaRegenerationBonus = 'stamina_regeneration_bonus',
    tearDmgFactor = 'tear_dmg_factor',
    tenacity = 'tenacity',
    thermalAccumulation = 'thermal_accumulation',
    thermalDmgFactor = 'thermal_dmg_factor',
    thermalProtection = 'thermal_protection',
    totalHp = 'total_hp',
    toxicAccumulation = 'toxic_accumulation',
    toxicDmgFactor = 'toxic_dmg_factor',
    toxicProtection = 'toxic_protection',
    wiggleBonus = 'wiggle_bonus',
    // looks like a duplicate stat
    artefaktHeal = 'artefakt_heal',
}

export const REVERSE_STAT = Object.entries(STAT).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
}, {} as Record<string, string>);

export enum SPECIAL_STAT {
    polyhedronBlockingDamage = 'polyhedron_blocking_damage',
    polyhedronCooldown = 'polyhedron_cooldown',
}




export type Stats = Partial<Record<STAT, number>>;

export type StatsKey = keyof Stats;
export type StatsHash = Partial<Record<StatsKey, boolean>>;

export type ArtifactStats = Partial<Record<StatsKey, [number, number]>>;
export type AdditionalStats = StatsKey[]

export type PolyhedronStats = SPECIAL_STAT.polyhedronBlockingDamage | SPECIAL_STAT.polyhedronCooldown;

export type SpecialArtifactStats = {
    polyhedron? : Record<PolyhedronStats, [number, number]>
}

export type SpecialStats = {
    polyhedron? : Record<PolyhedronStats, number>[]
}

export type SpectialStatsKeys = keyof SpecialStats;

export const combineStats = (stats: Stats[]): Stats => {
    return stats.reduce((acc, stat) => {
        for (const key of Object.keys(stat) as StatsKey[]) {
            const value = stat[key];
            if (!value) continue;
            acc[key] = floatFix((acc[key] ?? 0) + value, 10);
        }
        return acc;
    }, {} as Stats);
};

export const combineSpecialStats = (stats: SpecialStats[]): SpecialStats => {
    return stats.reduce((acc, stat) => {
        for (const key of Object.keys(stat) as SpectialStatsKeys[]) {
            const value = stat[key];
            if (!value) continue;
            acc[key] = [...(acc[key] ?? []), ...value];
        }
        return acc;
    }, {} as SpecialStats);
}

export const statsArray = Object.values(STAT) as StatsKey[];

export enum STAT_TYPE {
    FLAT = 'flat',
    PERCENT = 'percent',
}

export const statTypeMap: Record<StatsKey, STAT_TYPE> = {
    [STAT.artefactDamage]: STAT_TYPE.FLAT,
    [STAT.artefactHeal]: STAT_TYPE.FLAT,
    [STAT.biologicalAccumulation]: STAT_TYPE.FLAT,
    [STAT.biologicalDmgFactor]: STAT_TYPE.PERCENT,
    [STAT.biologicalProtection]: STAT_TYPE.FLAT,
    [STAT.biologicalProtectionShort]: STAT_TYPE.FLAT,
    [STAT.bleedingAccumulation]: STAT_TYPE.FLAT,
    [STAT.bleedingDmgFactor]: STAT_TYPE.PERCENT,
    [STAT.bleedingProtection]: STAT_TYPE.PERCENT,
    [STAT.bulletDeflection]: STAT_TYPE.FLAT,
    [STAT.bulletDmgFactor]: STAT_TYPE.FLAT,
    [STAT.bulletSlowdown]: STAT_TYPE.FLAT,
    [STAT.burnDmgFactor]: STAT_TYPE.FLAT,
    [STAT.chemicalBurnDmgFactor]: STAT_TYPE.FLAT,
    [STAT.combustionAccumulation]: STAT_TYPE.FLAT,
    [STAT.combustionDmgFactor]: STAT_TYPE.FLAT,
    [STAT.combustionProtection]: STAT_TYPE.FLAT,
    [STAT.electraDmgFactor]: STAT_TYPE.FLAT,
    [STAT.explosionDmgFactor]: STAT_TYPE.FLAT,
    [STAT.fallProtection]: STAT_TYPE.FLAT,
    [STAT.frostAccumulation]: STAT_TYPE.FLAT,
    [STAT.frostDmgFactor]: STAT_TYPE.FLAT,
    [STAT.frostProtection]: STAT_TYPE.FLAT,
    [STAT.healEfficiency]: STAT_TYPE.PERCENT,
    [STAT.healthBonus]: STAT_TYPE.PERCENT,
    [STAT.jumpIncrease]: STAT_TYPE.FLAT,
    [STAT.maxWeightBonus]: STAT_TYPE.FLAT,
    [STAT.psychoAccumulation]: STAT_TYPE.FLAT,
    [STAT.psychoDmgFactor]: STAT_TYPE.PERCENT,
    [STAT.psychoProtection]: STAT_TYPE.FLAT,
    [STAT.psychoProtectionShort]: STAT_TYPE.FLAT,
    [STAT.radiationAccumulation]: STAT_TYPE.FLAT,
    [STAT.radiationDmgFactor]: STAT_TYPE.FLAT,
    [STAT.radiationProtection]: STAT_TYPE.FLAT,
    [STAT.radiationProtectionShort]: STAT_TYPE.FLAT,
    [STAT.reactionToBurn]: STAT_TYPE.PERCENT,
    [STAT.reactionToChemicalBurn]: STAT_TYPE.PERCENT,
    [STAT.reactionToElectroshock]: STAT_TYPE.PERCENT,
    [STAT.reactionToTear]: STAT_TYPE.PERCENT,
    [STAT.recoilBonus]: STAT_TYPE.FLAT,
    [STAT.regenerationBonus]: STAT_TYPE.PERCENT,
    [STAT.reloadBonus]: STAT_TYPE.FLAT,
    [STAT.speedModifier]: STAT_TYPE.PERCENT,
    [STAT.spreadBonus]: STAT_TYPE.FLAT,
    [STAT.staminaBonus]: STAT_TYPE.PERCENT,
    [STAT.staminaRegenerationBonus]: STAT_TYPE.PERCENT,
    [STAT.tearDmgFactor]: STAT_TYPE.FLAT,
    [STAT.tenacity]: STAT_TYPE.FLAT,
    [STAT.thermalAccumulation]: STAT_TYPE.FLAT,
    [STAT.thermalDmgFactor]: STAT_TYPE.PERCENT,
    [STAT.thermalProtection]: STAT_TYPE.FLAT,
    [STAT.totalHp]: STAT_TYPE.FLAT,
    [STAT.toxicAccumulation]: STAT_TYPE.FLAT,
    [STAT.toxicDmgFactor]: STAT_TYPE.FLAT,
    [STAT.toxicProtection]: STAT_TYPE.FLAT,
    [STAT.wiggleBonus]: STAT_TYPE.FLAT,
    [STAT.artefaktHeal]: STAT_TYPE.PERCENT,
};