import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const kzs3 = new Container(
    CONTAINER.KZS_3,
    {
        [STAT.speedModifier]: -1,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 25980,
                [RESOURCE.PIECES_OF_COPPER_WIRE]: 57,
                [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 99,
                [RESOURCE.PIECE_OF_INFERNAL_COAL]: 53,
            },
            dependency: CONTAINER.KZS_2,
        },
        {
            resources: {
                [RESOURCE.RUB]: 33010,
                [RESOURCE.PIECES_OF_COPPER_WIRE]: 99,
                [RESOURCE.SPRIG_OF_CHERNOBYL_CHAMOMILE]: 172,
                [RESOURCE.PIECE_OF_INFERNAL_COAL]: 93,
            },
        },
    ].filter(Boolean), 3, 0.75);

export default kzs3;