import Container from "../../../models/container";
import {CONTAINER} from "../container";

const kzs2u = new Container(
    CONTAINER.KZS_2U,
    {
    },
    [
    ].filter(Boolean), 2, 0.75);

export default kzs2u;