import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const blizzardFahrenheit = new Artifact(ARTIFACT.BLIZZARD_FAHRENHEIT, {
    [STAT.regenerationBonus]: [-0.75, -1.5],
    [STAT.reactionToTear]: [1, 2],
    [STAT.bulletDmgFactor]: [5, 15],
}, {
    [STAT.thermalAccumulation]: [1, 3],
    [STAT.bulletDmgFactor]: [1.5, 3.75],
    [STAT.regenerationBonus]: [0.5, 1],
});

export default blizzardFahrenheit;