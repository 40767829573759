import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const jarkaSolnce = new Artifact(ARTIFACT.JARKA_SOLNCE, {
    [STAT.healthBonus]: [3, 5],
    [STAT.thermalAccumulation]: [-4, -8],
}, {
    [STAT.healthBonus]: [0.75, 1.25],
    [STAT.staminaBonus]: [5, 15],
    [STAT.thermalAccumulation]: [1, 2],
});

export default jarkaSolnce;