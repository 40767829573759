import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const houndsExoskeleton = new Armor(ARMOR.HOUNDS_EXOSKELETON,
    {
        [STAT.speedModifier]: 15,
        [STAT.maxWeightBonus]: 20,
        [STAT.artefaktHeal]: 2,
        [STAT.radiationAccumulation]: 2,
        [STAT.bleedingAccumulation]: 0.5,
        [STAT.bulletDmgFactor]: 95,
        [STAT.tearDmgFactor]: 145,
        [STAT.explosionDmgFactor]: 143,
        [STAT.electraDmgFactor]: 356,
        [STAT.burnDmgFactor]: 356,
        [STAT.chemicalBurnDmgFactor]: 356,
        [STAT.radiationProtection]: 270,
        [STAT.thermalProtection]: 150,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 270,
        [STAT.bleedingProtection]: 60,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 4166100,
            [RESOURCE.QUANTUM_BATTERY]: 1350,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 2290,
            [RESOURCE.RED_FERN]: 3200,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 1120,
            [RESOURCE.SUBSTANCE_07270]: 6000,
            [RESOURCE.ANOMALOUS_SERUM]: 90,
        },
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default houndsExoskeleton;