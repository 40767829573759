import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {STAT} from "../../../models/stats";

const packBag = new Container(
    CONTAINER.PACK_BAG,
    {
        [STAT.staminaBonus]: 10,
        [STAT.maxWeightBonus]: 10,
        [STAT.biologicalAccumulation]: 1.5,
    },
    [
    ].filter(Boolean), 3, 0.75);

export default packBag;