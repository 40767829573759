import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornMule = new Armor(ARMOR.WORN_MULE,
    {
        [STAT.staminaBonus]: -15,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 40,
        [STAT.bulletDmgFactor]: 147,
        [STAT.tearDmgFactor]: 147,
        [STAT.explosionDmgFactor]: 210,
        [STAT.electraDmgFactor]: 98,
        [STAT.burnDmgFactor]: 98,
        [STAT.chemicalBurnDmgFactor]: 98,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 80,
        [STAT.biologicalProtection]: 200,
        [STAT.psychoProtection]: 180,
        [STAT.bleedingProtection]: 62,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 1253680,
            [RESOURCE.QUANTUM_BATTERY]: 410,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 470,
            [RESOURCE.RED_FERN]: 970,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 340,
            [RESOURCE.SUBSTANCE_07270]: 2190,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.EXOSKELETON,
    },
    {
        resources: {
            [RESOURCE.RUB]: 1253680,
            [RESOURCE.ANOMALOUS_BATTERY]: 28,
            [RESOURCE.LIMBOPLASMA]: 10,
            [RESOURCE.BITTERLEAF]: 1190,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 202,
            [RESOURCE.LIM]: 1190,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.EXOSKELETON,
    },
    {
        resources: {
            [RESOURCE.RUB]: 1254350,
            [RESOURCE.QUANTUM_BATTERY]: 410,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 470,
            [RESOURCE.RED_FERN]: 970,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 340,
            [RESOURCE.SUBSTANCE_07270]: 2190,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.TRUMP_EXOSKELETON,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_STANDARD_CONTAINER,
)

export default wornMule;