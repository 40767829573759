import human from "../data/human";
import {combineSpecialStats, combineStats, SpecialStats, STAT, Stats, StatsKey} from "./stats";
import {ContainerData} from "./container";
import AllContainers from "../data/container";
import {ArmorData} from "./armor";
import AllArmor from "../data/armor";
import {ArtifactData} from "./artifact";
import AllArtifacts from "../data/artifact";
import AppError from "../utils/app-error";
import {MAX_ARTIFACT_CAPACITY, Protection} from "../constants/base";
class Calculator {
    private humanStats = human.getStats();
    private containerStats = {} as Stats;
    private containerCapacity = 0;
    private protection: Protection = 0;
    private armorStats = {} as Stats;
    private artifactsStats = [] as Stats[];
    private artifactsSpecialStats: SpecialStats[] = [];

    private static PROTECTED_STATS: Set<StatsKey> = new Set<StatsKey>([
        STAT.radiationAccumulation,
        STAT.biologicalAccumulation,
        STAT.psychoAccumulation,
        STAT.bleedingAccumulation,
        STAT.thermalAccumulation,
    ]);

    public setContainer(container: ContainerData) {
        this.containerStats = AllContainers[container.id].getStats();
        this.containerCapacity = AllContainers[container.id].capacity;
        this.protection = AllContainers[container.id].protection;

        if (this.containerCapacity < MAX_ARTIFACT_CAPACITY) {
            this.artifactsStats.length = Math.min(this.containerCapacity, this.artifactsStats.length);
            this.artifactsSpecialStats.length = Math.min(this.containerCapacity, this.artifactsSpecialStats.length);
        }

        return this.containerStats;
    }

    public clearContainer() {
        this.containerStats = {};
        this.containerCapacity = 0;
        this.artifactsStats = [];
        this.artifactsSpecialStats = [];

        return this.containerStats;
    }


    public setArmor(armor: ArmorData) {
        this.armorStats = AllArmor[armor.id].getStats(armor.level);

        return this.armorStats;
    }

    public clearArmor() {
        this.armorStats = {};

        return this.armorStats;
    }

    public setArtifact(index: number, artifact: ArtifactData) {
        if (this.containerCapacity === 0)
            throw new AppError(
                'Cannot set artifact without container',
                'ERROR.NO_CONTAINER'
            );
        if (index < 0 || index >= this.containerCapacity)
            throw new AppError(
                `Index ${index} is out of range [0, ${this.containerCapacity - 1}]`,
                'ERROR.ARTIFACT_INDEX_OUT_OF_RANGE'
            );

        this.artifactsStats[index] = AllArtifacts[artifact.id]
            .getStats(artifact.quality, artifact.level, artifact.rarity, artifact.additionalStats);

        this.artifactsSpecialStats[index] = AllArtifacts[artifact.id]
            .getSpecialStats(artifact.quality, artifact.level);

        // spread operator to trigger reactivity
        return [...this.artifactsStats];
    }

    public setArtifacts(artifacts: ArtifactData[]) {
        if (artifacts.length > this.containerCapacity)
            throw new AppError(
                `Cannot set ${artifacts.length} artifacts with container capacity of ${this.containerCapacity}`,
                'ERROR.ARTIFACTS_EXCEED_CONTAINER_CAPACITY'
            );

        this.artifactsStats = artifacts.map(artifact => AllArtifacts[artifact.id]
            .getStats(artifact.quality, artifact.level, artifact.rarity, artifact.additionalStats));

        this.artifactsSpecialStats = artifacts.map(artifact => AllArtifacts[artifact.id]
            .getSpecialStats(artifact.quality, artifact.level));

        return this.artifactsStats;
    }

    public clearArtifact(index: number) {
        if (this.containerCapacity === 0)
            throw new AppError(
                'Cannot clear artifact without container',
                'ERROR.NO_CONTAINER'
            );
        if (index < 0 || index >= this.containerCapacity)
            throw new AppError(
                `Index ${index} is out of range [0, ${this.containerCapacity - 1}]`,
                'ERROR.ARTIFACT_INDEX_OUT_OF_RANGE'
            );

        this.artifactsStats[index] = {};
        this.artifactsSpecialStats[index] = {};
    }

    public clearAllArtifacts() {
        this.artifactsStats = [];
        this.artifactsSpecialStats = [];
    }

    public clearAll() {
        this.clearContainer();
        this.clearArmor();
        this.clearAllArtifacts();
    }

    private get artifactStatsAfterProtection() {
        const stats = combineStats(this.artifactsStats);

        const keys = Object.keys(stats) as StatsKey[];
        const newStats = {} as Stats;
        for (const key of keys) {
            const value = stats[key];
            if (value !== undefined && value < 0 && Calculator.PROTECTED_STATS.has(key)) {
                newStats[key] = value * (100 - this.protection * 100) / 100;
            } else {
                newStats[key] = value;
            }
        }
        return newStats;
    }

    public getStats(useHumanStats = true): Stats {
        return combineStats([
            useHumanStats ? this.humanStats : {},
            this.containerStats,
            this.armorStats,
            this.artifactStatsAfterProtection
        ]);
    }

    public getSpecialStats(): SpecialStats {
        return combineSpecialStats(this.artifactsSpecialStats);
    }

    public useHumanStats(value: boolean) {
        if (value) {
            this.humanStats = human.getStats();
        } else {
            this.humanStats = {};
        }
    }
}

export default Calculator;