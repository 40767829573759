import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const ghillieSuit = new Armor(ARMOR.GHILLIE_SUIT,
    {
        [STAT.speedModifier]: 8,
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 55,
        [STAT.tearDmgFactor]: 71,
        [STAT.explosionDmgFactor]: 77,
        [STAT.electraDmgFactor]: 27,
        [STAT.burnDmgFactor]: 0,
        [STAT.chemicalBurnDmgFactor]: 22,
        [STAT.radiationProtection]: 100,
        [STAT.thermalProtection]: 0,
        [STAT.biologicalProtection]: 90,
        [STAT.psychoProtection]: 50,
        [STAT.bleedingProtection]: 42,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default ghillieSuit;