import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const banditCloak = new Armor(ARMOR.BANDIT_CLOAK,
    {
        [STAT.staminaBonus]: -5,
        [STAT.bulletDmgFactor]: 14,
        [STAT.tearDmgFactor]: 54,
        [STAT.explosionDmgFactor]: 17,
        [STAT.electraDmgFactor]: 9,
        [STAT.burnDmgFactor]: 9,
        [STAT.chemicalBurnDmgFactor]: 9,
        [STAT.radiationProtection]: 30,
        [STAT.thermalProtection]: 0,
        [STAT.biologicalProtection]: 30,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 34,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 2060,
            [RESOURCE.GREEN_MOLD]: 19,
            [RESOURCE.SWAMP_STONE]: 5,
        },
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default banditCloak;