import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const trampolineMeduza = new Artifact(ARTIFACT.TRAMPOLINE_MEDUZA, {
    [STAT.staminaBonus]: [-5, -10],
    [STAT.radiationAccumulation]: [-2, -5],
    [STAT.bulletDmgFactor]: [5, 10],
}, {
    [STAT.psychoAccumulation]: [1, 2],
    [STAT.bulletDmgFactor]: [1.25, 2.5],
    [STAT.radiationAccumulation]: [0.5, 1.25],
});

export default trampolineMeduza;