import {MEDIUM_BACKPACK} from "../container";
import Container from "../../../models/container";
import triZipAssaultBackpack from "./tri-zip-assault-backpack";
import mbssBackpack from "./mbss-backpack";
import hellboyBackpack from "./hellboy-backpack";
import reinforcedHellboyBackpack from "./reinforced-hellboy-backpack";
import errandJuniorBackpack from "./errand-junior-backpack";

const MediumBackpacks: Record<MEDIUM_BACKPACK, Container> = {
    [MEDIUM_BACKPACK.TRI_ZIP_ASSAULT_BACKPACK]: triZipAssaultBackpack,
    [MEDIUM_BACKPACK.MBSS_BACKPACK]: mbssBackpack,
    [MEDIUM_BACKPACK.HELLBOY_BACKPACK]: hellboyBackpack,
    [MEDIUM_BACKPACK.REINFORCED_HELLBOY_BACKPACK]: reinforcedHellboyBackpack,
    [MEDIUM_BACKPACK.ERRAND_JUNIOR_BACKPACK]: errandJuniorBackpack,
}

export default MediumBackpacks;