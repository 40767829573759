import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const voronkaVivert = new Artifact(ARTIFACT.VORONKA_VIVERT, {
    [STAT.reactionToTear]: [1, 2],
    [STAT.bleedingDmgFactor]: [5, 15],
    [STAT.radiationAccumulation]: [-2, -5],
}, {
    [STAT.bleedingDmgFactor]: [2.5, 7.5],
    [STAT.regenerationBonus]: [1, 2],
    [STAT.radiationAccumulation]: [0.5, 1.25],
});

export default voronkaVivert;