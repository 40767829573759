import {BULKY_CONTAINER} from "../container";
import Container from "../../../models/container";
import kzs3 from "./kzs-3";
import kzs4 from "./kzs-4";
import kzs5 from "./kzs-5";
import kzs3u from "./kzs-3u";
import kzs4u from "./kzs-4u";
import kzs5y from "./kzs-5y";

const BulkyContainers: Record<BULKY_CONTAINER, Container> = {
    [BULKY_CONTAINER.KZS_3]: kzs3,
    [BULKY_CONTAINER.KZS_3U]: kzs3u,
    [BULKY_CONTAINER.KZS_4]: kzs4,
    [BULKY_CONTAINER.KZS_4U]: kzs4u,
    [BULKY_CONTAINER.KZS_5]: kzs5,
    [BULKY_CONTAINER.KZS_5Y]: kzs5y,
}

export default BulkyContainers;