import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const damagedCenturion = new Armor(ARMOR.DAMAGED_CENTURION,
    {
        [STAT.staminaBonus]: -15,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 25,
        [STAT.bulletDmgFactor]: 152,
        [STAT.tearDmgFactor]: 152,
        [STAT.explosionDmgFactor]: 210,
        [STAT.electraDmgFactor]: 59,
        [STAT.burnDmgFactor]: 59,
        [STAT.chemicalBurnDmgFactor]: 59,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 30,
        [STAT.biologicalProtection]: 180,
        [STAT.psychoProtection]: 160,
        [STAT.bleedingProtection]: 61,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 715010,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 133,
            [RESOURCE.RED_FERN]: 840,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 390,
            [RESOURCE.SUBSTANCE_07270]: 1460,
        },
        dependency: ARMOR.LEGIONNAIRE_HEAVY_ARMORED_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 715010,
            [RESOURCE.LIMBOPLASMA]: 11,
            [RESOURCE.BITTERLEAF]: 680,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 155,
            [RESOURCE.LIM]: 820,
        },
        dependency: ARMOR.LEGIONNAIRE_HEAVY_ARMORED_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default damagedCenturion;