import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const wornTopaz = new Armor(ARMOR.WORN_TOPAZ,
    {
        [STAT.bulletDmgFactor]: 49,
        [STAT.tearDmgFactor]: 55,
        [STAT.explosionDmgFactor]: 67,
        [STAT.electraDmgFactor]: 333,
        [STAT.burnDmgFactor]: 1111,
        [STAT.chemicalBurnDmgFactor]: 667,
        [STAT.radiationProtection]: 310,
        [STAT.thermalProtection]: 400,
        [STAT.biologicalProtection]: 400,
        [STAT.psychoProtection]: 280,
        [STAT.bleedingProtection]: 35,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default wornTopaz;