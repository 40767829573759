import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const rapsSpanner = new Armor(ARMOR.RAPS_SPANNER,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 10,
        [STAT.artefaktHeal]: 2.25,
        [STAT.bulletDmgFactor]: 90,
        [STAT.tearDmgFactor]: 105,
        [STAT.explosionDmgFactor]: 125,
        [STAT.electraDmgFactor]: 533,
        [STAT.burnDmgFactor]: 533,
        [STAT.chemicalBurnDmgFactor]: 533,
        [STAT.radiationProtection]: 225,
        [STAT.thermalProtection]: 225,
        [STAT.biologicalProtection]: 285,
        [STAT.psychoProtection]: 225,
        [STAT.bleedingProtection]: 47,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_SPACIOUS_CONTAINER,
)

export default rapsSpanner;