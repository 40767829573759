import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const rapsAh4Raider = new Armor(ARMOR.RAPS_AH_4_RAIDER,
    {
        [STAT.staminaBonus]: -10,
        [STAT.speedModifier]: -2,
        [STAT.maxWeightBonus]: 10,
        [STAT.artefaktHeal]: 2,
        [STAT.bulletDmgFactor]: 79,
        [STAT.tearDmgFactor]: 93,
        [STAT.explosionDmgFactor]: 111,
        [STAT.electraDmgFactor]: 533,
        [STAT.burnDmgFactor]: 533,
        [STAT.chemicalBurnDmgFactor]: 533,
        [STAT.radiationProtection]: 210,
        [STAT.thermalProtection]: 210,
        [STAT.biologicalProtection]: 270,
        [STAT.psychoProtection]: 210,
        [STAT.bleedingProtection]: 44,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 587630,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 350,
            [RESOURCE.RED_FERN]: 320,
            [RESOURCE.BETA_DATA_FRAGMENT]: 287,
            [RESOURCE.SUBSTANCE_07270]: 510,
        },
        dependency: ARMOR.WORN_AH_4_RAIDER,
    },
    {
        resources: {
            [RESOURCE.RUB]: 587630,
            [RESOURCE.LIMBOPLASMA]: 5,
            [RESOURCE.BITTERLEAF]: 420,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 94,
            [RESOURCE.LIM]: 330,
        },
        dependency: ARMOR.WORN_AH_4_RAIDER,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default rapsAh4Raider;