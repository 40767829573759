import hopliteArmoredSuit from "./hoplite-armored-suit";
import {COMBAT_ARMOR} from "../armor";
import wornHopliteArmoredSuit from "./worn-hoplite-armored-suit";
import Armor from "../../../models/armor";
import wornLegionnaire from "./worn-legionnaire";
import legionnaireHeavyArmoredSuit from "./legionnaire-heavy-armored-suit";
import damagedCenturion from "./damaged-centurion";
import wornOshProtectiveSuit from "./worn-osh-protective-suit";
import oshProtectiveSuit from "./osh-protective-suit";
import jaegerExoarmor from "./jaeger-exoarmor";
import coatWithBodyArmor from "./coat-with-body-armor";
import reportersArmor from "./reporters-armor";
import wornFleeceProtectiveSuit from "./worn-fleece-protective-suit";
import huntersCloak from "./hunters-cloak";
import fleeceProtectiveSuit from "./fleece-protective-suit";
import trapperSuit from "./trapper-suit";
import wornTrapperSuit from "./worn-trapper-suit";
import wornStrikerArmoredSuit from "./worn-striker-armored-suit";
import cloak from "./cloak";
import banditCloak from "./bandit-cloak";
import berill5MArmoredSuit from "./berill-5m-armored-suit";
import wormArmoredSuit from "./worm-armored-suit";
import healingBerill from "./healing-berill";
import currantGhillieSuit from "./currant-ghillie-suit";
import houndmasterProtectiveSuit from "./houndmaster-protective-suit";
import scoutSuit from "./scout-suit";
import strikerArmoredSuit from "./striker-armored-suit";
import centurionArmoredExoskeleton from "./centurion-armored-exoskeleton";
import damagedJaeger from "./damaged-jaeger";
import wornJaeger from "./worn-jaeger";
import wornCenturion from "./worn-centurion";
import yorshProtectiveArmoredSuit from "./yorsh-protective-armored-suit";
import lynxArmoredSuit from "./lynx-armored-suit";
import phantomHunterArmoredSuit from "./phantom-hunter-armored-suit";
import ratcatcherExoarmor from "./ratcatcher-exoarmor";
import ghillieSuit from "./ghillie-suit";
import cd1Suit from "./cd-1-suit";
import cd2ArmoredSuit from "./cd-2-armored-suit";
import cd3AArmoredSuit from "./cd-3a-armored-suit";
import altynSet from "./altyn-set";
import cd3BArmoredSuit from "./cd-3b-armored-suit";
import shadeHunterHeavyArmoredSuit from "./shade-hunter-heavy-armored-suit";
import altynTHeavySet from "./altyn-t-heavy-set";
import blizzardBoundHeavyArmoredSuit from "./blizzard-bound-heavy-armored-suit";
import modifiedExoskeleton from "./modified-exoskeleton";
import sbaTank from "./sba-tank";
import cd4ArmoredSuit from "./cd-4-armored-suit";
import beastSlayerExoarmor from "./beast-slayer-exoarmor";
import wornBeastSlayer from "./worn-beast-slayer";
import apostleHeavyArmoredSuit from "./apostle-heavy-armored-suit";
import vanguardArmoredSuit from "./vanguard-armored-suit";
import reiterSuperHeavyArmoredSuit from "./reiter-super-heavy-armored-suit";
import graniteSuperHeavyArmoredSuit from "./granite-super-heavy-armored-suit";
import chieftainSuperHeavyArmoredSuit from "./chieftain-super-heavy-armored-suit";

const CombatArmor: Record<COMBAT_ARMOR, Armor> = {
    [COMBAT_ARMOR.COAT_WITH_BODY_ARMOR]: coatWithBodyArmor,
    [COMBAT_ARMOR.REPORTERS_ARMOR]: reportersArmor,
    [COMBAT_ARMOR.WORN_FLEECE_PROTECTIVE_SUIT]: wornFleeceProtectiveSuit,
    [COMBAT_ARMOR.WORN_HOPLITE_ARMORED_SUIT]: wornHopliteArmoredSuit,
    [COMBAT_ARMOR.HUNTERS_CLOAK]: huntersCloak,
    [COMBAT_ARMOR.FLEECE_PROTECTIVE_SUIT]: fleeceProtectiveSuit,
    [COMBAT_ARMOR.HOPLITE_ARMORED_SUIT]: hopliteArmoredSuit,
    [COMBAT_ARMOR.TRAPPER_SUIT]: trapperSuit,
    [COMBAT_ARMOR.WORN_TRAPPER_SUIT]: wornTrapperSuit,
    [COMBAT_ARMOR.WORN_STRIKER_ARMORED_SUIT]: wornStrikerArmoredSuit,
    [COMBAT_ARMOR.CLOAK]: cloak,
    [COMBAT_ARMOR.BANDIT_CLOAK]: banditCloak,
    [COMBAT_ARMOR.BERILL_5_M_ARMORED_SUIT]: berill5MArmoredSuit,
    [COMBAT_ARMOR.WORM_ARMORED_SUIT]: wormArmoredSuit,
    [COMBAT_ARMOR.HEALING_BERILL]: healingBerill,
    [COMBAT_ARMOR.CURRANT_GHILLIE_SUIT]: currantGhillieSuit,
    [COMBAT_ARMOR.HOUNDMASTER_PROTECTIVE_SUIT]: houndmasterProtectiveSuit,
    [COMBAT_ARMOR.SCOUT_SUIT]: scoutSuit,
    [COMBAT_ARMOR.STRIKER_ARMORED_SUIT]: strikerArmoredSuit,
    [COMBAT_ARMOR.OSH_PROTECTIVE_SUIT]: oshProtectiveSuit,
    [COMBAT_ARMOR.JAEGER_EXOARMOR]: jaegerExoarmor,
    [COMBAT_ARMOR.LEGIONNAIRE_HEAVY_ARMORED_SUIT]: legionnaireHeavyArmoredSuit,
    [COMBAT_ARMOR.CENTURION_ARMORED_EXOSKELETON]: centurionArmoredExoskeleton,
    [COMBAT_ARMOR.WORN_OSH_PROTECTIVE_SUIT]: wornOshProtectiveSuit,
    [COMBAT_ARMOR.WORN_LEGIONNAIRE]: wornLegionnaire,
    [COMBAT_ARMOR.DAMAGED_JAEGER]: damagedJaeger,
    [COMBAT_ARMOR.WORN_JAEGER]: wornJaeger,
    [COMBAT_ARMOR.DAMAGED_CENTURION]: damagedCenturion,
    [COMBAT_ARMOR.WORN_CENTURION]: wornCenturion,
    [COMBAT_ARMOR.YORSH_PROTECTIVE_ARMORED_SUIT]: yorshProtectiveArmoredSuit,
    [COMBAT_ARMOR.LYNX_ARMORED_SUIT]: lynxArmoredSuit,
    [COMBAT_ARMOR.PHANTOM_HUNTER_ARMORED_SUIT]: phantomHunterArmoredSuit,
    [COMBAT_ARMOR.RATCATCHER_EXOARMOR]: ratcatcherExoarmor,
    [COMBAT_ARMOR.GHILLIE_SUIT]: ghillieSuit,
    [COMBAT_ARMOR.CD_1_SUIT]: cd1Suit,
    [COMBAT_ARMOR.CD_2_ARMORED_SUIT]: cd2ArmoredSuit,
    [COMBAT_ARMOR.CD_3_A_ARMORED_SUIT]: cd3AArmoredSuit,
    [COMBAT_ARMOR.ALTYN_SET]: altynSet,
    [COMBAT_ARMOR.CD_3_B_ARMORED_SUIT]: cd3BArmoredSuit,
    [COMBAT_ARMOR.SHADE_HUNTER_HEAVY_ARMORED_SUIT]: shadeHunterHeavyArmoredSuit,
    [COMBAT_ARMOR.ALTYN_T_HEAVY_SET]: altynTHeavySet,
    [COMBAT_ARMOR.BLIZZARD_BOUND_HEAVY_ARMORED_SUIT]: blizzardBoundHeavyArmoredSuit,
    [COMBAT_ARMOR.MODIFIED_EXOSKELETON]: modifiedExoskeleton,
    [COMBAT_ARMOR.SBA_TANK]: sbaTank,
    [COMBAT_ARMOR.CD_4_ARMORED_SUIT]: cd4ArmoredSuit,
    [COMBAT_ARMOR.BEAST_SLAYER_EXOARMOR]: beastSlayerExoarmor,
    [COMBAT_ARMOR.WORN_BEAST_SLAYER]: wornBeastSlayer,
    [COMBAT_ARMOR.APOSTLE_HEAVY_ARMORED_SUIT]: apostleHeavyArmoredSuit,
    [COMBAT_ARMOR.VANGUARD_ARMORED_SUIT]: vanguardArmoredSuit,
    [COMBAT_ARMOR.REITER_SUPER_HEAVY_ARMORED_SUIT]: reiterSuperHeavyArmoredSuit,
    [COMBAT_ARMOR.GRANITE_SUPER_HEAVY_ARMORED_SUIT]: graniteSuperHeavyArmoredSuit,
    [COMBAT_ARMOR.CHIEFTAIN_SUPER_HEAVY_ARMORED_SUIT]: chieftainSuperHeavyArmoredSuit,
}

export default CombatArmor;