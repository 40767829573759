import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const saturnSuit = new Armor(ARMOR.SATURN_SUIT,
    {
        [STAT.maxWeightBonus]: 8,
        [STAT.bulletDmgFactor]: 84,
        [STAT.tearDmgFactor]: 95,
        [STAT.explosionDmgFactor]: 114,
        [STAT.electraDmgFactor]: 1031,
        [STAT.burnDmgFactor]: 1031,
        [STAT.chemicalBurnDmgFactor]: 1031,
        [STAT.radiationProtection]: 400,
        [STAT.thermalProtection]: 400,
        [STAT.biologicalProtection]: 415,
        [STAT.psychoProtection]: 400,
        [STAT.bleedingProtection]: 48,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 2268120,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 3360,
            [RESOURCE.RED_FERN]: 1930,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 660,
            [RESOURCE.SUBSTANCE_07270]: 2900,
            [RESOURCE.ANOMALOUS_SERUM]: 75,
        },
        dependency: ARMOR.WORN_SATURN_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default saturnSuit;