import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const damagedAh2Nomad = new Armor(ARMOR.DAMAGED_AH_2_NOMAD,
    {
        [STAT.staminaBonus]: -5,
        [STAT.artefaktHeal]: 1.1,
        [STAT.bulletDmgFactor]: 31,
        [STAT.tearDmgFactor]: 36,
        [STAT.explosionDmgFactor]: 43,
        [STAT.electraDmgFactor]: 207,
        [STAT.burnDmgFactor]: 207,
        [STAT.chemicalBurnDmgFactor]: 207,
        [STAT.radiationProtection]: 100,
        [STAT.thermalProtection]: 100,
        [STAT.biologicalProtection]: 100,
        [STAT.psychoProtection]: 30,
        [STAT.bleedingProtection]: 26,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default damagedAh2Nomad;