import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornBeastSlayer = new Armor(ARMOR.WORN_BEAST_SLAYER,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 25,
        [STAT.bulletDmgFactor]: 107,
        [STAT.tearDmgFactor]: 307,
        [STAT.explosionDmgFactor]: 147,
        [STAT.electraDmgFactor]: 65,
        [STAT.burnDmgFactor]: 65,
        [STAT.chemicalBurnDmgFactor]: 65,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 40,
        [STAT.biologicalProtection]: 220,
        [STAT.psychoProtection]: 200,
        [STAT.bleedingProtection]: 79,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 1272130,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 940,
            [RESOURCE.RED_FERN]: 2200,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 350,
            [RESOURCE.SUBSTANCE_07270]: 1470,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.JAEGER_EXOARMOR,
    },
    {
        resources: {
            [RESOURCE.RUB]: 1272130,
            [RESOURCE.LIMBOPLASMA]: 22,
            [RESOURCE.BITTERLEAF]: 1800,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 204,
            [RESOURCE.LIM]: 960,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.JAEGER_EXOARMOR,
    },
    {
        resources: {
            [RESOURCE.RUB]: 1666840,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1230,
            [RESOURCE.RED_FERN]: 2890,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 450,
            [RESOURCE.SUBSTANCE_07270]: 1930,
            [RESOURCE.ANOMALOUS_SERUM]: 15,
        },
        dependency: ARMOR.WORN_JAEGER,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default wornBeastSlayer;