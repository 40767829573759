import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const wornStrikerArmoredSuit = new Armor(ARMOR.WORN_STRIKER_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -3,
        [STAT.maxWeightBonus]: 3,
        [STAT.bulletDmgFactor]: 61,
        [STAT.tearDmgFactor]: 61,
        [STAT.explosionDmgFactor]: 84,
        [STAT.electraDmgFactor]: 25,
        [STAT.burnDmgFactor]: 25,
        [STAT.chemicalBurnDmgFactor]: 25,
        [STAT.radiationProtection]: 100,
        [STAT.thermalProtection]: 0,
        [STAT.biologicalProtection]: 90,
        [STAT.psychoProtection]: 30,
        [STAT.bleedingProtection]: 42,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default wornStrikerArmoredSuit;