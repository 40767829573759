import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {STAT} from "../../../models/stats";

const npaBackpack = new Container(
    CONTAINER.NPA_BACKPACK,
    {
        [STAT.staminaBonus]: 40,
        [STAT.maxWeightBonus]: 45,
    },
    [
    ].filter(Boolean), 0, 0);

export default npaBackpack;