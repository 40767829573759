import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const foragerContainer = new Container(
    CONTAINER.FORAGER_CONTAINER,
    {
        [STAT.speedModifier]: -2,
        [STAT.radiationAccumulation]: 2,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 1035370,
                [RESOURCE.ANOMALOUS_BATTERY]: 11,
                [RESOURCE.LIMBOPLASMA]: 17,
                [RESOURCE.BITTERLEAF]: 1100,
                [RESOURCE.LAMBDA_DATA_FRAGMENT]: 251,
                [RESOURCE.LIM]: 880,
            },
            dependency: CONTAINER.KZS_5,
        },
        {
            resources: {
                [RESOURCE.RUB]: 1054730,
                [RESOURCE.ANOMALOUS_BATTERY]: 11,
                [RESOURCE.LIMBOPLASMA]: 18,
                [RESOURCE.BITTERLEAF]: 1150,
                [RESOURCE.LAMBDA_DATA_FRAGMENT]: 261,
                [RESOURCE.LIM]: 920,
            },
            dependency: CONTAINER.BEARS_DEN_4_CONTAINER,
        },
    ].filter(Boolean), 4, 0.7);

export default foragerContainer;