import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const albatrossHeavyAssaultArmoredExoskeleton = new Armor(ARMOR.ALBATROSS_HEAVY_ASSAULT_ARMORED_EXOSKELETON,
    {
        [STAT.maxWeightBonus]: 30,
        [STAT.radiationAccumulation]: 1,
        [STAT.bleedingAccumulation]: 1,
        [STAT.bulletDmgFactor]: 167,
        [STAT.tearDmgFactor]: 167,
        [STAT.explosionDmgFactor]: 238,
        [STAT.electraDmgFactor]: 278,
        [STAT.burnDmgFactor]: 278,
        [STAT.chemicalBurnDmgFactor]: 278,
        [STAT.radiationProtection]: 210,
        [STAT.thermalProtection]: 210,
        [STAT.biologicalProtection]: 300,
        [STAT.psychoProtection]: 210,
        [STAT.bleedingProtection]: 65,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default albatrossHeavyAssaultArmoredExoskeleton;