import Container from "../../../models/container";
import {CONTAINER} from "../container";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const hiveContainer = new Container(
    CONTAINER.HIVE_CONTAINER,
    {
        [STAT.speedModifier]: -1.5,
    },
    [
        {
            resources: {
                [RESOURCE.RUB]: 1633570,
                [RESOURCE.QUANTUM_BATTERY]: 530,
                [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1500,
                [RESOURCE.RED_FERN]: 1570,
                [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 740,
                [RESOURCE.SUBSTANCE_07270]: 2360,
                [RESOURCE.ANOMALOUS_SERUM]: 30,
            },
            dependency: CONTAINER.COCOON_CONTAINER,
        },
        {
            resources: {
                [RESOURCE.RUB]: 1633570,
                [RESOURCE.QUANTUM_BATTERY]: 530,
                [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 1500,
                [RESOURCE.RED_FERN]: 1570,
                [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 740,
                [RESOURCE.SUBSTANCE_07270]: 2360,
                [RESOURCE.ANOMALOUS_SERUM]: 30,
            },
            dependency: CONTAINER.FORAGER_CONTAINER,
        },
    ].filter(Boolean), 5, 0.8);

export default hiveContainer;