import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const damagedJaeger = new Armor(ARMOR.DAMAGED_JAEGER,
    {
        [STAT.staminaBonus]: -15,
        [STAT.speedModifier]: -3,
        [STAT.maxWeightBonus]: 25,
        [STAT.bulletDmgFactor]: 87,
        [STAT.tearDmgFactor]: 262,
        [STAT.explosionDmgFactor]: 120,
        [STAT.electraDmgFactor]: 53,
        [STAT.burnDmgFactor]: 53,
        [STAT.chemicalBurnDmgFactor]: 53,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 50,
        [STAT.biologicalProtection]: 190,
        [STAT.psychoProtection]: 170,
        [STAT.bleedingProtection]: 77,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_SPACIOUS_CONTAINER,
)

export default damagedJaeger;