export const MAX_LEVEL = 15;
export type Level = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;

export const ArmorLevelEfficiency: Record<Level, number> = {
    0: 0,
    1: 1.83,
    2: 3.65,
    3: 5.49,
    4: 7.31,
    5: 12.8,
    6: 15.54,
    7: 18.29,
    8: 21.03,
    9: 23.78,
    10: 31.83,
    11: 35.85,
    12: 39.88,
    13: 43.9,
    14: 47.92,
    15: 60
};

/**
 * Artifact quality, from 0 to 1.75, % modifier
 */
export type ArtifactQuality = number;

export enum Rarity {
    COMMON = "COMMON",
    UNCOMMON = "UNCOMMON",
    RARE = "RARE",
    EPIC = "EPIC",
    EXCEPTIONAL = "EXCEPTIONAL",
    LEGENDARY = "LEGENDARY",
    UNIQUE = "UNIQUE"
}

export const ARTIFACT_NEGATIVE_STAT_PERCENTAGE = 0.9;
export const ARTIFACT_POSITIVE_STAT_PERCENT_PER_LEVEL = 2;

export const MAX_ARTIFACT_CAPACITY = 6;

export type NumberValidation = {
    min: number,
    max: number,
    digits: number,
};

export const QUALITY_VALIDATION: NumberValidation = {
    min: 0,
    max: 175,
    digits: 2,
};

export const LEVEL_VALIDATION: NumberValidation = {
    min: 0,
    max: MAX_LEVEL,
    digits: 0,
}

/**
 * Protection, from 0 to 1, % modifier
 */
export type Protection = number;