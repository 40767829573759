import {ARTIFACT, NO_ARTIFACT} from "./artifact";
import Artifact from "../../models/artifact";
import ElectrophysicalArtifacts from "./electrophysical";
import ThermalArtifacts from "./thermal";
import GraviArtifacts from "./gravitational";
import BioArtifacts from "./biochemical";

const AllArtifacts: Record<ARTIFACT, Artifact> = {
    ...ElectrophysicalArtifacts,
    ...ThermalArtifacts,
    ...GraviArtifacts,
    ...BioArtifacts,
    [NO_ARTIFACT.NONE]: new Artifact(ARTIFACT.NONE, {}, {})
}

export default AllArtifacts;