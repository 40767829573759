import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const wornAmethyst = new Armor(ARMOR.WORN_AMETHYST,
    {
        [STAT.bulletDmgFactor]: 53,
        [STAT.tearDmgFactor]: 63,
        [STAT.explosionDmgFactor]: 76,
        [STAT.electraDmgFactor]: 800,
        [STAT.burnDmgFactor]: 800,
        [STAT.chemicalBurnDmgFactor]: 800,
        [STAT.radiationProtection]: 370,
        [STAT.thermalProtection]: 370,
        [STAT.biologicalProtection]: 400,
        [STAT.psychoProtection]: 370,
        [STAT.bleedingProtection]: 38,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.LIGHT_BAG_STANDARD_CONTAINER,
)

export default wornAmethyst;