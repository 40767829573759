import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {RESOURCE} from "../../../models/resource";
import {STAT} from "../../../models/stats";

const wornSkat9m = new Armor(ARMOR.WORN_SKAT_9_M,
    {
        [STAT.staminaBonus]: -6,
        [STAT.maxWeightBonus]: 10,
        [STAT.bulletDmgFactor]: 118,
        [STAT.tearDmgFactor]: 127,
        [STAT.explosionDmgFactor]: 171,
        [STAT.electraDmgFactor]: 296,
        [STAT.burnDmgFactor]: 296,
        [STAT.chemicalBurnDmgFactor]: 296,
        [STAT.radiationProtection]: 200,
        [STAT.thermalProtection]: 120,
        [STAT.biologicalProtection]: 210,
        [STAT.psychoProtection]: 170,
        [STAT.bleedingProtection]: 56,
    },
    [
    {
        resources: {
            [RESOURCE.RUB]: 694030,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 640,
            [RESOURCE.RED_FERN]: 268,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 320,
            [RESOURCE.SUBSTANCE_07270]: 1610,
        },
        dependency: ARMOR.KINGPIN_ARMORED_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 694030,
            [RESOURCE.LIMBOPLASMA]: 6,
            [RESOURCE.BITTERLEAF]: 820,
            [RESOURCE.LAMBDA_DATA_FRAGMENT]: 186,
            [RESOURCE.LIM]: 920,
        },
        dependency: ARMOR.KINGPIN_ARMORED_SUIT,
    },
    {
        resources: {
            [RESOURCE.RUB]: 694030,
            [RESOURCE.REMAINS_OF_A_PSY_TRACKER]: 640,
            [RESOURCE.RED_FERN]: 268,
            [RESOURCE.DIGAMMA_DATA_FRAGMENT]: 320,
            [RESOURCE.SUBSTANCE_07270]: 1610,
        },
        dependency: ARMOR.PSZ_10_ARMORED_SUIT,
    },
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default wornSkat9m;