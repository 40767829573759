import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const blizzardVortex = new Artifact(ARTIFACT.BLIZZARD_VORTEX, {
    [STAT.speedModifier]: [3, 6],
    [STAT.staminaRegenerationBonus]: [-2.5, -5],
}, {
    [STAT.reactionToElectroshock]: [2, 3],
    [STAT.reactionToBurn]: [2, 3],
    [STAT.reactionToChemicalBurn]: [2, 3],
});

export default blizzardVortex;