import {STAT, Stats, StatsHash} from "./stats";
import Craftable from "./craftable";
import {ARMOR, ARMOR_CONTAINER_TYPE} from "../data/armor/armor";
import {Level} from "../constants/base";
import {Recipe} from "./resource";

export type ArmorData = {
    id: ARMOR,
    level: Level,
}

class Armor extends Craftable {
    protected readonly dynamicallyChangedStats: StatsHash = {
        [STAT.bulletDmgFactor]: true,
        [STAT.tearDmgFactor]: true,
        [STAT.explosionDmgFactor]: true,
        [STAT.burnDmgFactor]: true,
        [STAT.chemicalBurnDmgFactor]: true,
        [STAT.electraDmgFactor]: true,
    };

    constructor(id: ARMOR, stats: Stats, recipes: Recipe[], public readonly containerType: ARMOR_CONTAINER_TYPE) {
        super(id, stats, recipes);
    }
}

export default Armor;