import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const trampolineBraslet = new Artifact(ARTIFACT.TRAMPOLINE_BRASLET, {
    [STAT.speedModifier]: [1, 3],
    [STAT.staminaRegenerationBonus]: [5, 7.5],
    [STAT.psychoAccumulation]: [-3, -8],
}, {
    [STAT.speedModifier]: [0.5, 1.5],
    [STAT.staminaRegenerationBonus]: [2.5, 3.75],
    [STAT.psychoAccumulation]: [0.75, 2],
});

export default trampolineBraslet;