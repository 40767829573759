import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const cd2ArmoredSuit = new Armor(ARMOR.CD_2_ARMORED_SUIT,
    {
        [STAT.staminaBonus]: -5,
        [STAT.maxWeightBonus]: 8,
        [STAT.bulletDmgFactor]: 60,
        [STAT.tearDmgFactor]: 76,
        [STAT.explosionDmgFactor]: 86,
        [STAT.electraDmgFactor]: 53,
        [STAT.burnDmgFactor]: 53,
        [STAT.chemicalBurnDmgFactor]: 53,
        [STAT.radiationProtection]: 100,
        [STAT.thermalProtection]: 60,
        [STAT.biologicalProtection]: 150,
        [STAT.psychoProtection]: 0,
        [STAT.bleedingProtection]: 44,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default cd2ArmoredSuit;