import {ARMOR_CONTAINER_TYPE, ARMOR} from "../armor";
import Armor from "../../../models/armor";
import {STAT} from "../../../models/stats";

const damagedAh3SeekerSuit = new Armor(ARMOR.DAMAGED_AH_3_SEEKER_SUIT,
    {
        [STAT.staminaBonus]: -10,
        [STAT.maxWeightBonus]: 2,
        [STAT.artefaktHeal]: 1.25,
        [STAT.bulletDmgFactor]: 48,
        [STAT.tearDmgFactor]: 57,
        [STAT.explosionDmgFactor]: 68,
        [STAT.electraDmgFactor]: 326,
        [STAT.burnDmgFactor]: 326,
        [STAT.chemicalBurnDmgFactor]: 326,
        [STAT.radiationProtection]: 165,
        [STAT.thermalProtection]: 165,
        [STAT.biologicalProtection]: 165,
        [STAT.psychoProtection]: 120,
        [STAT.bleedingProtection]: 35,
    },
    [
    ].filter(Boolean),
    ARMOR_CONTAINER_TYPE.ALL_BAG_ALL_CONTAINER,
)

export default damagedAh3SeekerSuit;