import {ArtifactData} from "../../../models/artifact";
import React, {useCallback} from "react";
import ArtifactEdit from "./edit/artifact-edit";
import ArtifactSearch from "./search/artifact-search";
import {ARTIFACT} from "../../../data/artifact/artifact";
import {Rarity} from "../../../constants/base";

type ArtifactComponentProps = {
    artifact: ArtifactData,
    setArtifactFactory: (index: number) => (artifact: ArtifactData) => void,
    index: number,
    slots: number,
}

const ArtifactComponent: React.FC<ArtifactComponentProps> = ({ artifact, setArtifactFactory, index, slots}) => {
    const setNewArtifact = useCallback((id: ARTIFACT) => setArtifactFactory(index)({
        id,
        level: 0,
        additionalStats: [],
        quality: 100,
        rarity: Rarity.COMMON,
    }), [index, setArtifactFactory]);
    return artifact.id === ARTIFACT.NONE ?
        (<ArtifactSearch setNewArtifact={setNewArtifact} index={index}/>) :
        (<ArtifactEdit
            artifact={artifact}
            setArtifactFactory={setArtifactFactory}
            slots={slots}
            index={index}
        />)
};

export default ArtifactComponent;
