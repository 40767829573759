import Artifact from "../../../models/artifact";
import {ARTIFACT} from "../artifact";
import {STAT} from "../../../models/stats";

const kiselSliznyak = new Artifact(ARTIFACT.KISEL_SLIZNYAK, {
    [STAT.bleedingAccumulation]: [1.5, 3],
    [STAT.burnDmgFactor]: [-25, -50],
    [STAT.bleedingProtection]: [7.5, 15],
}, {
    [STAT.bleedingDmgFactor]: [7.5, 15],
    [STAT.burnDmgFactor]: [10, 20],
    [STAT.bleedingProtection]: [3.5, 7.5],
});

export default kiselSliznyak;