import Armor from "../../../models/armor";
import {COMBINED_ARMOR} from "../armor";
import wornAh2Nomad from "./worn-ah-2-nomad";
import damagedAh2Nomad from "./damaged-ah-2-nomad";
import ah1DrifterSuit from "./ah-1-drifter-suit";
import ps1Picklock from "./ps-1-picklock";
import ah2NomadSuit from "./ah-2-nomad-suit";
import ah3SeekerSuit from "./ah-3-seeker-suit";
import rapsAh4Raider from "./raps-ah-4-raider";
import rapsAh5Pilgrim from "./raps-ah-5-pilgrim";
import damagedAh3SeekerSuit from "./damaged-ah-3-seeker-suit";
import wornAh3SeekerSuit from "./worn-ah-3-seeker-suit";
import wornAh4Raider from "./worn-ah-4-raider";
import wornAh5Pilgrim from "./worn-ah-5-pilgrim";
import rapsSpanner from "./raps-spanner";
import expeditionSuit from "./expedition-suit";
import magnetSuit from "./magnet-suit";
import tongaSuit from "./tonga-suit";
import albatrossScoutArmoredExoskeleton from "./albatross-scout-armored-exoskeleton";
import jdZivcas2aExoarmor from "./jd-zivcas-2a-exoarmor";
import albatrossInfiltratorArmoredExoskeleton from "./albatross-infiltrator-armored-exoskeleton";
import albatrossHeavyAssaultArmoredExoskeleton from "./albatross-heavy-assault-armored-exoskeleton";
import rigelSuit from "./rigel-suit";
import rapsAh6Nomad from "./raps-ah-6-nomad";
import wornAh6Nomad from "./worn-ah-6-nomad";
import houndsExoskeleton from "./hounds-exoskeleton";

const CombinedArmor: Record<COMBINED_ARMOR, Armor> = {
    [COMBINED_ARMOR.WORN_AH_2_NOMAD]: wornAh2Nomad,
    [COMBINED_ARMOR.DAMAGED_AH_2_NOMAD]: damagedAh2Nomad,
    [COMBINED_ARMOR.AH_1_DRIFTER_SUIT]: ah1DrifterSuit,
    [COMBINED_ARMOR.PS_1_PICKLOCK]: ps1Picklock,
    [COMBINED_ARMOR.AH_2_NOMAD_SUIT]: ah2NomadSuit,
    [COMBINED_ARMOR.AH_3_SEEKER_SUIT]: ah3SeekerSuit,
    [COMBINED_ARMOR.RAPS_AH_4_RAIDER]: rapsAh4Raider,
    [COMBINED_ARMOR.RAPS_AH_5_PILGRIM]: rapsAh5Pilgrim,
    [COMBINED_ARMOR.DAMAGED_AH_3_SEEKER_SUIT]: damagedAh3SeekerSuit,
    [COMBINED_ARMOR.WORN_AH_3_SEEKER_SUIT]: wornAh3SeekerSuit,
    [COMBINED_ARMOR.WORN_AH_4_RAIDER]: wornAh4Raider,
    [COMBINED_ARMOR.WORN_AH_5_PILGRIM]: wornAh5Pilgrim,
    [COMBINED_ARMOR.RAPS_SPANNER]: rapsSpanner,
    [COMBINED_ARMOR.EXPEDITION_SUIT]: expeditionSuit,
    [COMBINED_ARMOR.MAGNET_SUIT]: magnetSuit,
    [COMBINED_ARMOR.TONGA_SUIT]: tongaSuit,
    [COMBINED_ARMOR.ALBATROSS_SCOUT_ARMORED_EXOSKELETON]: albatrossScoutArmoredExoskeleton,
    [COMBINED_ARMOR.JD_ZIVCAS_2_A_EXOARMOR]: jdZivcas2aExoarmor,
    [COMBINED_ARMOR.ALBATROSS_INFILTRATOR_ARMORED_EXOSKELETON]: albatrossInfiltratorArmoredExoskeleton,
    [COMBINED_ARMOR.ALBATROSS_HEAVY_ASSAULT_ARMORED_EXOSKELETON]: albatrossHeavyAssaultArmoredExoskeleton,
    [COMBINED_ARMOR.RIGEL_SUIT]: rigelSuit,
    [COMBINED_ARMOR.RAPS_AH_6_NOMAD]: rapsAh6Nomad,
    [COMBINED_ARMOR.WORN_AH_6_NOMAD]: wornAh6Nomad,
    [COMBINED_ARMOR.HOUNDS_EXOSKELETON]: houndsExoskeleton
}

export default CombinedArmor;